import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../context/store";
import Modal from "react-modal";
import cross from "../../../assets/modalIcons/cross.svg";

const MessagesTable = ({
  title1 = "-",
  title2 = "Tarih",
  title3 = "Gönderen",
  title4 = "Gönderilen",
  title5 = "Mesaj",
  data,
}) => {
  const { messageId } = useContext(StoreContext);

  useEffect(() => {
    console.log("messageId", messageId);
  }, [messageId]);

  //MODAL
  const [showModal, setShowModal] = useState(false);
  const [view, setView] = useState("");

  function handleOpenModal(x) {
    setView(x);
    setShowModal(true);
  }
  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <div className={style.container}>
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
      </div>
      {data &&
        data.map((message, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{message.id}</div>
            <div className={style.data2}>{message.tarih}</div>
            <div className={style.data3}>{message.gonderen}</div>
            <div className={style.data4}>{message.alan}</div>
            <div className={style.data5}>
              {/*      <button
                onClick={() => {
                  setMessageId(message.id);
                  Promise.resolve(getMessageMethod(message.id)).then(() => {
                    setEditMessageClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>*/}

              <div
                onClick={() => {
                  handleOpenModal(message.mesaj);
                  console.log(message.mesaj, "mesqajjjjjj");
                }}
                className={style.data10}
              >
                <img alt="" src={operation}></img>
              </div>
              <Modal
                isOpen={showModal}
                onRequestClose={handleCloseModal}
                style={{
                  content: {
                    maxWidth: "500px",
                    height: "fit-content",
                    minHeight: "300px",
                    backgroundColor: "#F1F2F7",
                    color: "#3733bf",
                    borderRadius: "20px",
                    margin: "auto",
                    marginTop: "10%",
                    padding: "15px",
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "medium",
                    fontFamily: "Poppins",
                    border: "2px",
                    borderStyle: "solid",
                    borderColor: "#F99E1D",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    placeContent: "start",
                    gap: "10px",
                  },
                  overlay: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
                }}
              >
                <div className={style.tab}>
                  <button className={style.tabicon} onClick={handleCloseModal}>
                    <img alt="" src={cross}></img>
                  </button>
                  <div className={style.tabtext}>Kapat</div>
                </div>
                <div>{view}</div>
              </Modal>
            </div>
          </div>
        ))}
    </div>
  );
};

export default MessagesTable;
