import style from "./style.module.css";

import { useContext } from "react";
import StoreContext from "../../../context/store";

const SecondBar = () => {
  const { selectedAssignmentSection, setSelectedAssignmentSection } =
    useContext(StoreContext);

  const styleSection = (section) => {
    if (selectedAssignmentSection === section) {
      return style.ssection;
    } else {
      return style.section;
    }
  };

  return (
    <div className={style.container}>
      <button
        onClick={() => {
          setSelectedAssignmentSection("1");
        }}
        className={styleSection("1")}
      >
        Sınıf Ders Atama
      </button>
      <button
        onClick={() => {
          setSelectedAssignmentSection("2");
        }}
        className={styleSection("2")}
      >
        Öğretmen Ders Atama
      </button>
      <button
        onClick={() => {
          setSelectedAssignmentSection("3");
        }}
        className={styleSection("3")}
      >
        Derslik Ders Atama
      </button>
      <button
        onClick={() => {
          setSelectedAssignmentSection("4");
        }}
        className={styleSection("4")}
      >
        Ortak Ders Atama
      </button>
      <button
        onClick={() => {
          setSelectedAssignmentSection("5");
        }}
        className={styleSection("5")}
      >
        Atama Kopyala
      </button>
      {/*  <button
        onClick={() => {
          setSelectedAssignmentSection("6");
        }}
        className={styleSection("6")}
      >
        Eksik Atamalar
      </button> */}
    </div>
  );
};

export default SecondBar;
