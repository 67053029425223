import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { addWelldone } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";

import icon4 from "../../../assets/welldoneIcons/dersehazir.svg";
import icon3 from "../../../assets/welldoneIcons/dersteetkin.svg";
import icon2 from "../../../assets/welldoneIcons/iyikiyafet.svg";
import icon1 from "../../../assets/welldoneIcons/odevbasarili.svg";
import icon5 from "../../../assets/welldoneIcons/ozenlinot.svg";
import icon8 from "../../../assets/welldoneIcons/sinavahazir.svg";
import icon7 from "../../../assets/welldoneIcons/sosyal.svg";
import icon6 from "../../../assets/welldoneIcons/temiz.svg";

import { getStudents } from "../../../api/general";
import dropdown from "../../../assets/arrowdown.svg";
import useClickOutside from "../../../hooks/useClickOutside";
import { useEffect } from "react";
import { useRef } from "react";

const AddWelldoneModal = () => {
  const { setAddWelldoneClick } = useContext(StoreContext);


  const [student, setStudent] = useState("Öğrenci Seçiniz");
  const [studentId, setStudentId] = useState("");

  const [nameSurname, setNamesurname] = useState("");
  const [password, setPassword] = useState("");
  const [unvan, setUnvan] = useState("");
  const [brans, setBrans] = useState("");
  const [phone, setPhone] = useState("");
  const [sinif, setSinif] = useState("");
  const [sosyal, setSosyal] = useState("");
  const [email, setEmail] = useState("");

  const [checks, setChecks] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleCheckChange = (index) => {
    const updatedChecks = [...checks];
    updatedChecks[index] = !updatedChecks[index];
    setChecks(updatedChecks);
  };

  // FOR DROPDOWN

  const [optionArea, setOptionArea] = useState(false);
  const buttonRef = useRef(null);

  const [students, setStudents] = useState([]);

  useEffect(() => {
    getStudentsMethod();
  }, []); //

  // FOR DROPDOWN

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const handleStudentSelect = (event) => {
    setStudent(event.target.value.split(",")[0]);
    setStudentId(event.target.value.split(",")[1]);
    setOptionArea(false);
  };

  const getStudentsMethod = async () => {
    const response = await getStudents();
    if (response.error) {
      console.log("Test ogretmenler gelmedi");
    } else {
      console.log("Test ogretmenler geldi");
      setStudents(response.data.result);
    }
  }; //

  const addWelldoneMethod = async () => {
    if (
      studentId === "" 
      
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await addWelldone(
      studentId,
      checks
    );
    if (response.error) {
      console.log("Aferin ekleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Aferin ekleme başarısız");
    } else {
      console.log("Aferin ekleme başarılı");
      successMessage("Aferin ekleme başarılı");
      setAddWelldoneClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddWelldoneClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.dropdown}>
            <div className={style.selectedoption}>{student}</div>
            <button onClick={handleToggle}>
              <img src={dropdown} alt=""></img>
            </button>

            {optionArea && (
              <div className={style.optionarea} ref={buttonRef}>
                <div>
                  {students &&
                    students.map((student, index) => (
                      <button
                        onClick={handleStudentSelect}
                        value={[student.nameSurname, student.id]}
                        key={index}
                        className={style.option}
                      >
                        {student.nameSurname}
                      </button>
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className={style.input}>
            <img className="w-8 h-8" src={icon1}></img>
            <div className={style.inputtext}>
              <div className={style.inputtitle}>
                Verilen ödevi başarıyla yerine getirdi
              </div>
              <input
                type="checkbox"
                className={style.checktick}
                value={checks[0]}
                checked={checks[0]}
                onChange={() => handleCheckChange(0)}
                // checked={check1 === true}
              />
            </div>
          </div>
          <div className={style.input}>
            <img className="w-8 h-8" src={icon2}></img>
            <div className={style.inputtext}>
              <div className={style.inputtitle}>
                Kılık kıyafet konusunda özenli.
              </div>
              <input
                type="checkbox"
                className={style.checktick}
                value={checks[1]}
                checked={checks[1]}
                onChange={() => handleCheckChange(1)}
                // checked={check2 === true}
              />
            </div>
          </div>
          <div className={style.input}>
            <img className="w-8 h-8" src={icon3}></img>
            <div className={style.inputtext}>
              <div className={style.inputtitle}>
                Derse etkin şeklide katıldı.
              </div>
              <input
                type="checkbox"
                className={style.checktick}
                value={checks[2]}
                checked={checks[2]}
                onChange={() => handleCheckChange(2)}
                // checked={check3 === true}
              />
            </div>
          </div>
          <div className={style.input}>
            <img className="w-8 h-8" src={icon4}></img>
            <div className={style.inputtext}>
              <div className={style.inputtitle}>Derse hazır geldi</div>
              <input
                type="checkbox"
                className={style.checktick}
                value={checks[3]}
                checked={checks[3]}
                onChange={() => handleCheckChange(3)}
                // checked={check4 === true}
              />
            </div>
          </div>
          <div className={style.input}>
            <img className="w-8 h-8" src={icon5}></img>
            <div className={style.inputtext}>
              <div className={style.inputtitle}>
                Ders notlarını özenli aldı.
              </div>
              <input
                type="checkbox"
                className={style.checktick}
                value={checks[4]}
                checked={checks[4]}
                onChange={() => handleCheckChange(4)}
                // checked={check5 === true}
              />
            </div>
          </div>
          <div className={style.input}>
            <img className="w-8 h-8" src={icon6}></img>
            <div className={style.inputtext}>
              <div className={style.inputtitle}>
                Çevre temizliğinde özenli davrandı.
              </div>
              <input
                type="checkbox"
                className={style.checktick}
                value={checks[5]}
                checked={checks[5]}
                onChange={() => handleCheckChange(5)}
                // checked={check6 === true}
              />
            </div>
          </div>
          <div className={style.input}>
            <img className="w-8 h-8" src={icon7}></img>
            <div className={style.inputtext}>
              <div className={style.inputtitle}>
                Arkadaşlarıyla olumlu çalıştı.
              </div>
              <input
                type="checkbox"
                className={style.checktick}
                value={checks[6]}
                checked={checks[6]}
                onChange={() => handleCheckChange(6)}
                // checked={check7 === true}
              />
            </div>
          </div>
          <div className={style.input}>
            <img className="w-8 h-8" src={icon8}></img>
            <div className={style.inputtext}>
              <div className={style.inputtitle}>Sınavlarına hazılandı.</div>
              <input
                type="checkbox"
                className={style.checktick}
                value={checks[7]}
                checked={checks[7]}
                onChange={() => handleCheckChange(7)}
                // checked={check8 === true}
              />
            </div>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  addWelldoneMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWelldoneModal;
