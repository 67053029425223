
import Bar from "../components/Programs/ProgramsBar";
import Header from "../components/Header/SecondHeader";
import style from "../styles/Programs.module.css"
import SecondBar from "../components/Programs/ProgramsSecondBar";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../context/store";
import TeacherPrograms from "../components/Programs/ProgramsSections/TeacherPrograms";
import Table11 from "../components/Programs/ProgramsSections/Tables/Table11";
import Table12 from "../components/Programs/ProgramsSections/Tables/Table12";
import Table21 from "../components/Programs/ProgramsSections/Tables/Table21";
import Table22 from "../components/Programs/ProgramsSections/Tables/Table22";
import Table31 from "../components/Programs/ProgramsSections/Tables/Table31";
import Table32 from "../components/Programs/ProgramsSections/Tables/Table32";
import Table41 from "../components/Programs/ProgramsSections/Tables/Table41";
import Table42 from "../components/Programs/ProgramsSections/Tables/Table42";
import { getTeachers } from "../api/general";
import { getTeacherLectureAssigns } from "../api/general";
import PlaceModal from "../components/Programs/ProgramsModals/PlaceModal";
import PlaceTeacherModal from "../components/Programs/ProgramsModals/PlaceTeacherModal";
import NonPlacedModal from "../components/Programs/ProgramsModals/NonPlacedModals";
import Settings from "../components/Programs/ProgramsModals/SettingsModal";
import CleanModal from "../components/Programs/ProgramsModals/CleanModal";
import ChangesModal from "../components/Programs/ProgramsModals/ChangesModal";
import DirectivesModal from "../components/Programs/ProgramsModals/DirectivesModal";
import ModalsBar from "../components/Programs/ProgramsModals/ModalsBar";
import FilterBar from "../components/Programs/FilterBar";
import PrintSection from "../components/Programs/ProgramsSections/PrintSection";
const Programs = () => {

    const {
        selectedProgramSection,
        showPlaceModal,
        showTeacherPlaceModal,
        nonPlacedLecturesModal,
        settingsModal,
        cleanModal,
        changesModal,
        directivesModal,
    } = useContext(StoreContext);

    const [teachers, setTeachers] = useState([]);

    console.log("tablo ögretmenler",teachers)

    useEffect(() => {
        getTeachersMethod();
    }, [])
    const getTeachersMethod = async () => {
        try {
            const response = await getTeachers();
            if (response) {
                console.log("Data listed successfully", response.data);
                setTeachers(response.data.result);

            } else {
                console.log("Data not listed!");
            }
        } catch (error) {
            console.log(error);

        }
    }

    return (
        <div className={style.page}>
            {showPlaceModal && <PlaceModal />}
            {showTeacherPlaceModal && <PlaceTeacherModal />}
            {nonPlacedLecturesModal && <NonPlacedModal />}
            {settingsModal && <Settings />}
            {cleanModal && <CleanModal />}
            {changesModal && <ChangesModal />}
            {directivesModal && <DirectivesModal />}
            <div className={style.container}>
                <div className={style.header}>
                    <Header />
                </div>
                <div className={style.bar}>
                    <Bar />
                </div>
                <SecondBar />
                {selectedProgramSection === "1" &&
                    <>
                        <TeacherPrograms />
                        <ModalsBar />
                        <FilterBar />
                        <div className={style.table}>
                            <Table11
                                data={teachers}
                            />
                            <Table12
                                data={teachers}
                            />
                        </div>


                    </>
                }
                {selectedProgramSection === "2" &&
                    <>
                        <TeacherPrograms />
                        <div className={style.table}>
                            <Table21
                                data={teachers}
                            />
                            <Table22
                                data={teachers}
                            />
                        </div>
                    </>
                }
                {selectedProgramSection === "3" &&
                    <>
                        <TeacherPrograms />
                        <div className={style.table}>
                            <Table31
                                data={teachers}
                            />
                            <Table32
                                data={teachers}
                            />
                        </div>

                    </>
                }
                {selectedProgramSection === "4" &&
                    <>
                        <div className={style.table}>
                            <Table41
                                data={teachers}
                            />
                            <Table42
                                data={teachers}
                            />
                        </div>
                        <TeacherPrograms />
                    </>}
                {selectedProgramSection === "5" && <PrintSection />}
            </div>
        </div>
    )
}

export default Programs;