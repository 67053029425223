import style from "./style.module.css"
import StoreContext from "../../../../context/store";
import { useContext } from "react";

import star from "../../../../assets/star.svg"
import teacher from "../../../../assets/teachers.svg"
import lectures from "../../../../assets/lectures.svg"
import settings from "../../../../assets/settings.svg";
import bin from "../../../../assets/bin.svg";
import changes from "../../../../assets/changes.svg";
import directives from "../../../../assets/directives.svg";


const ModalsBar = () => {
    const {
        setShowPlaceModal,
        setShowTeacherPlaceModal,
        setNonPlacedLecturesModal,
        setSettingsModal,
        setCleanModal,
        setChangesModal,
        setDirectivesModal
    } = useContext(StoreContext);
    return (
        <div className={style.modalsbar}>
            <div className={style.tab}>
                <button
                    className={style.tabicon}
                    onClick={() => {
                        setShowPlaceModal(true);
                    }}
                >
                    <img alt="" src={star}></img>
                </button>
                <div className={style.tabtext}>Yerleştir 0/700</div>
            </div>
            <div className={style.tab}>
                <button
                    className={style.tabicon}
                    onClick={() => {
                        setShowTeacherPlaceModal(true);
                    }}
                >
                    <img alt="" src={teacher}></img>
                </button>
                <div className={style.tabtext}>Öğretmeni Yerleştir</div>
            </div>
            <div className={style.tab}>
                <button
                    className={style.tabicon}
                    onClick={() => {
                        setNonPlacedLecturesModal(true);
                    }}
                >
                    <img alt="" src={lectures}></img>
                </button>
                <div className={style.tabtext}>Yerleşmeyen Dersler</div>
            </div>
            <div className={style.tab}>
                <button
                    className={style.tabicon}
                    onClick={() => {
                        setSettingsModal(true);
                    }}
                >
                    <img alt="" src={settings}></img>
                </button>
                <div className={style.tabtext}>Ayarlar</div>
            </div>
            <div className={style.tab}>
                <button
                    className={style.tabicon}
                    onClick={() => {
                        setCleanModal(true);
                    }}
                >
                    <img alt="" src={bin}></img>
                </button>
                <div className={style.tabtext}>Temizle</div>
            </div>
            <div className={style.tab}>
                <button
                    className={style.tabicon}
                    onClick={() => {
                        setChangesModal(true);
                    }}
                >
                    <img alt="" src={changes}></img>
                </button>
                <div className={style.tabtext}>Değişenler</div>
            </div>
            <div className={style.tab}>
                <button
                    className={style.tabicon}
                    onClick={() => {
                        setDirectivesModal(true);
                    }}
                >
                    <img alt="" src={directives}></img>
                </button>
                <div className={style.tabtext}>Yönergeler</div>
            </div>
        </div>
    )
}

export default ModalsBar;