import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import EditAttendanceModal from "../EditAttendanceModal";
import { useContext, useEffect, useState, useRef } from "react";
import StoreContext from "../../../context/store";
import save from "../../../assets/modalIcons/save.svg";
import calendar from "../../../assets/calendaricon.svg";
import arrowleft from "../../../assets/arrowleft.svg";
import arrowright from "../../../assets/arrowright.svg";
import dropdown from "../../../assets/arrowdown.svg";
import useClickOutside from "../../../hooks/useClickOutside";
import { getClassnamesDD,getAttendance } from "../../../api/general";
import tick from "../../../assets/tick.svg";
import { successMessage, errorMessage } from "../../../helpers/toast";
import AddAttendanceModal from "../AddAttendanceModal";
import Select from "react-select";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import TextField from '@mui/material/TextField';
const useStyles = makeStyles({
  root: {
    border: 1,
    color: 'white',
    height: 48,
    "& .react-date-picker__wrapper": {
      padding: "0 10px",
      borderColor: "#ccc",
      borderRadius: "4px"
    },
    "& .react-date-picker--open": {
      borderColor: "red"
    }
  },
  label: {
    backgroundColor: "white",
    position: "absolute",
    zIndex: "2000",
    fontSize: "11px",
    fontFamily: "sans-serif",
    paddingLeft: "10px"
  }
});
const AttendancesTable = ({
  title1 = "-",
  title2 = "Sınıf",
  title3 = "Öğrenci No",
  title4 = "Öğrenci Adı Soyadı",
  title5 = "Yok",
  title6 = "Geç",
  title7 = "Nöbetçi",
  title8 = "Faaliyet",
  title9 = "İzinli",
  title10 = "Raporlu",
  title11 = "Detay",
  data,
}) => {
  const {
    setEditAttendanceClick,
    editAttendanceClick,
    attendanceId,
    setAttendanceId,
    attendances,
    setAttendances,
    date,
    setDate,
    selectsinif,
    setSelectsinif
  } = useContext(StoreContext);

  const [attendancetype, setAttendanceType] = useState("");
  const [selectedSinif, setSelectedSinif] = useState();
  const [showImage, setShowImage] = useState([]);
  const classes = useStyles();


  const toggleImage = (index, type) => {
    console.log("ind", index)
    setShowImage((prevShowImage) => {
      const newShowImage = [...prevShowImage];
      console.log("new", newShowImage)
      newShowImage[index] = newShowImage[index] === type ? "" : type;
      return newShowImage;
    });
  };

  // FOR DROPDOWN1

  const [optionArea, setOptionArea] = useState(false);
  const buttonRef = useRef(null);

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const [classrooms, setClassrooms] = useState("");

  useEffect(() => {
    getWithClassMethod();
    //getAttendanceMethod();
  }, []); //

  // FOR CLASSROOM DROPDOWN
  const getWithClassMethod = async () => {
    const response = await getClassnamesDD();
    if (response.error) {
    } else {
      setClassrooms(response.data);
    }
  };

  const getAttendanceMethod = async () => {
    const response = await getAttendance(date,selectsinif);
    if (response.error) {
    }else {
      setAttendances(response.data.result)
    }
  }

  // Geçerli bir tarih kontrolü yapar
  const isValidDate = (attendanceDate) => {
    return attendanceDate instanceof Date && !isNaN(attendanceDate);
  };

  const setVariable = (value) => {
    let temp = Object.assign({}, selectsinif);
    temp = value;
    setSelectsinif(temp);
  };
  console.log("sinif seçimi",selectsinif)
  const formatDateFn = (date) => {
    const selectedDate = new Date(date);

    return selectedDate.toDateString();
  };

  return (
    <div className={style.container}>
      {editAttendanceClick && (
        <EditAttendanceModal attendanceId={attendanceId} />
      )}
      <div className={style.upperside}>
        <div className={style.leftside}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
            <DatePicker
              //selected={null}
              value={null}
              //className={classes.label}
              onChange={(date) => {
                setDate(formatDateFn(date));
              }}
              inputVariant="outlined"
              label="Lütfen Tarih Seçin"
              />
          </LocalizationProvider>

          <Select
            value={selectedSinif}
            onChange={(e) => {
              setSelectedSinif(e);
              setVariable(e !== null ? e.key : null);
            }}
            getOptionValue={(option) => option.key}
            getOptionLabel={(option) => option.value}
            isClearable={true}
            options={classrooms}
            placeholder="Sınıf Seçimi..."
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: " 1px solid #EEF8FF",
                backgroundColor: "#EEF8FF",
                width: "200px",
                height: "40px",
                paddingTop: "0px",
                paddingBottom: "0px",
                margin: "7px",
                marginLeft: "-9px",
              }),
            }}
          />
        </div>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              getAttendanceMethod();
            }}
          >
            <img alt="" src={save}></img>
          </button>
          <div className={style.tabtext}>Ara</div>
        </div>
      </div>
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
        <div className={style.title7}>{title7}</div>
        <div className={style.title8}>{title8}</div>
        <div className={style.title9}>{title9}</div>
        <div className={style.title10}>{title10}</div>
        <div className={style.title11}>{title11}</div>

      </div>
      {data &&
        data.map((attendances, index) => (
          
          <div className={style.datas} key={index}>
            <div className={style.data1}>{attendances.id}</div>
            <div className={style.data2}>{attendances.sinifAdi}</div>
            <div className={style.data3}>{attendances.ogrenciNo}</div>
            <div className={style.data4}>{attendances.ogrenciAdi}</div>
            <button
            className={style.data5}
            onChange={() => {
              toggleImage(index, "Yok")
              //setAttendanceType(attendance)
            }}
           >
            {showImage[index] === "Yok" || attendances.yok === "True" && (
                <img src={tick} alt="Tick" className="w-6 h-6" />
              )}
            </button>
            <button
            className={style.data6}
            onChange={() => {
              toggleImage(index, "Geç")
              //setAttendanceType(attendance)
            }}
           >
            {showImage[index] === "Geç" || attendances.geç === "True" && (
                <img src={tick} alt="Tick" className="w-6 h-6" />
              )}
            </button>
            <button
            className={style.data7}
            onChange={() => {
              toggleImage(index, "Nöbetçi")
              //setAttendanceType(attendance)
            }}
           >
            {showImage[index] === "Nöbetçi" || attendances.nobetci === "True" && (
                <img src={tick} alt="Tick" className="w-6 h-6" />
              )}
            </button>
            <button
            className={style.data8}
            onChange={() => {
              toggleImage(index, "Faaliyet")
              //setAttendanceType(attendance)
            }}
           >
            {showImage[index] === "Faaliyet" || attendances.faaliyet === "True" && (
                <img src={tick} alt="Tick" className="w-6 h-6" />
              )}
            </button>
            <button
            className={style.data9}
            onChange={() => {
              toggleImage(index, "İzinli")
              //setAttendanceType(attendance)
            }}
           >
            {showImage[index] === "İzinli" || attendances.İzinli === "True" && (
                <img src={tick} alt="Tick" className="w-6 h-6" />
              )}
            </button>
            <button
            className={style.data10}
            onChange={() => {
              toggleImage(index, "Raporlu")
              //setAttendanceType(attendance)
            }}
           >
            {showImage[index] === "Raporlu" || attendances.raporlu === "True" && (
                <img src={tick} alt="Tick" className="w-6 h-6" />
              )}
            </button>
            <div className={style.data11}>{attendances.detay}</div>
            <div className={style.data12}>
              <button
                onClick={() => {
                  setAttendanceId(attendances.id);
                  Promise.resolve().then(
                    () => {
                      setEditAttendanceClick(true);
                    }
                  );
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AttendancesTable;
