import style from "./style.module.css";
import { Link } from "react-router-dom";
import homeIcon from "../../../assets/homeIcon.svg";
import logo from "../../../assets/logo1.svg";
import okulumLogo from "../../../assets/okulumlogo2.svg";
import logoutIcon from "../../../assets/logouticon.svg";
import { useContext } from "react";
import StoreContext from "../../../context/store";

const Header = () => {
  const { setIsAuth } = useContext(StoreContext);

  return (
    <div className={style.container}>
      <div className={style.leftside}>
        <img src={logo} className={style.logo} alt="logo"></img>
        <Link to="/">
          <img
            src={okulumLogo}
            className={style.okulumlogo}
            alt="okulumLogo"
          ></img>
        </Link>
      </div>
      <div className={style.rightside}>
        <div className={style.logoutarea}>
          <Link
            to="/giris"
            onClick={() => {
              localStorage.clear();
              //setIsAuth(false);
            }}
          >
            <img src={logoutIcon} className={style.home} alt="logoutIcon"></img>{" "}
          </Link>
          <Link
            className={style.logout}
            to="/giris"
            onClick={() => {
              localStorage.clear();
              //setIsAuth(false);
            }}
          >
            Çıkış
          </Link>{" "}
        </div>
        <Link to="/">
          <img src={homeIcon} className={style.home} alt="homeIcon"></img>{" "}
        </Link>
      </div>
    </div>
  );
};

export default Header;
