import { useEffect, useState } from "react";
import { getSocialClubs } from "../api/general";
import style from "../styles/SocialClubs.module.css";
import SocialClubsTable from "../components/SocialClub/SocialClubsTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/SocialClub/SocialClubBar";
import AddSocialClubModal from "../components/SocialClub/AddSocialClubModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const SocialClubs = () => {
  const { addSocialClubClick, socialClubInputValue } =
    useContext(StoreContext);

  const [socialClubs, setSocialClubs] = useState([]);

  useEffect(() => {
    getSocialClubsMethod();
    console.log(socialClubs, "socialClubs");
    console.log(socialClubInputValue, "socialClubInputValue");
  }, []);

  const getSocialClubsMethod = async () => {
    const response = await getSocialClubs();
    if (response.error) {
      console.log("Test kulüpler gelmedi");
    } else {
      console.log("Test kulüpler geldi");
      setSocialClubs(response.data);
    }
  };

  return (
    <div className={style.page}>
      {addSocialClubClick && <AddSocialClubModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <SocialClubsTable
            data={socialClubs?.filter(
              (el) =>
                el.klupname
                  .toLowerCase()
                  .includes(socialClubInputValue.toLowerCase()) ||
                el.ogretmenname
                  .toLowerCase()
                  .includes(socialClubInputValue.toLowerCase()) ||
                el.unvan.toLowerCase().includes(socialClubInputValue.toLowerCase())
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialClubs;
