import { useEffect, useState } from "react";
import { getExams } from "../api/general";
import style from "../styles/Exams.module.css";
import ExamsTable from "../components/Exam/ExamsTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Exam/ExamBar";
import AddExamModal from "../components/Exam/AddExamModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Exams = () => {
  const { addExamClick, examInputValue } = useContext(StoreContext);

  const [exams, setExams] = useState([]);
  useEffect(() => {
    getExamsMethod();
    console.log(exams, "exams");
  }, []);

  const getExamsMethod = async () => {
    const response = await getExams();
    if (response.error) {
      console.log("Test sınavlar gelmedi");
    } else {
      console.log("Test sınavlar geldi");
      setExams(response.data.result);
    }
  };

  return (
    <div className={style.page}>
      {addExamClick && <AddExamModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <ExamsTable data={exams} />
        </div>
      </div>
    </div>
  );
};

export default Exams;
