import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { addTeacher } from "../../../api/general";
import Multiselect from 'multiselect-react-dropdown';
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";

const AddTeacherModal = ({ branches, }) => {
  const { setAddTeacherClick } = useContext(StoreContext);
  const [nameSurname, setNamesurname] = useState("");
  const [password, setPassword] = useState("");
  const [unvan, setUnvan] = useState("");
  const [brans, setBrans] = useState({});
  const [phone, setPhone] = useState("");
  const [sinif, setSinif] = useState("");
  const [sosyal, setSosyal] = useState("");
  const [email, setEmail] = useState("");

  const addTeacherMethod = async (e) => {
    console.log("brans:::",brans);
    if (
      nameSurname === "" ||
      password === "" ||
      unvan === "" ||
      brans === "" ||
      phone === "" ||
      email === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await addTeacher(
      nameSurname,
      password,
      unvan,
      brans,
      phone,
      email
    );
    if (response.error) {
      response.error.response.data.errors.forEach(element => {
        errorMessage(element);
      });
      errorMessage("Öğretmen ekleme başarısız");
    } else {
      successMessage("Öğretmen ekleme başarılı");
      setAddTeacherClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddTeacherClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğretmen Adı Soyadı</div>
            <input
              className={style.inputtext}
              value={nameSurname}
              onChange={(e) => setNamesurname(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>E-mail</div>
            <input
              className={style.inputtext}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Ünvanı</div>
            <input
              className={style.inputtext}
              value={unvan}
              onChange={(e) => setUnvan(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Branşı</div>
            <div className={style.inputtext}>
              <Multiselect
                displayValue="value"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={function noRefCheck() { }}
                onSearch={function noRefCheck() { }}
                onSelect={(e) => { setBrans(e) }}
                options={branches}
                placeholder=""
                style={{
                  chips: {
                    background: '#3733bf',
                    height: "20px"
                  },
                  multiselectContainer: {
                    color: '#3733bf',
                    border: 'none',
                    width: '100%',
                    height: "30px",
                  },
                  searchBox: {
                    border: 'none',
                    width: '100%',
                    height: "30px",
                    padding: "0px 0px 0px 5px",
                  }
                }}
              />
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Telefon No</div>
            <input
              className={style.inputtext}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğretmen Şifresi</div>
            <input
              className={style.inputtext}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
          {/*  <div className={style.input}>
            <div className={style.inputtitle}>Sınıfı</div>
            <input
              className={style.inputtext}
              value={sinif}
              onChange={(e) => setSinif(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Sosyal Kulüp</div>
            <input
              className={style.inputtext}
              value={sosyal}
              onChange={(e) => setSosyal(e.target.value)}
            ></input>
          </div> */}
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={(e) => {
                  addTeacherMethod(e);
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeacherModal;
