import style from "./style.module.css";
import video from "../../../assets/video.svg";
import search from "../../../assets/search.svg";
import reload from "../../../assets/reload.svg";
import plus from "../../../assets/plus.svg";
import pdf from "../../../assets/pdf.svg";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { getAppointmentPdf } from "../../../api/general";
import settings from "../../../assets/settingsicon.svg";
import { successMessage, errorMessage } from "../../../helpers/toast";

const Bar = () => {
  const {
    setAddAppointmentClick,
    setAppointmentInputValue,
    appointmentInputValue,
    setSettingsAppointmentClick,
    dateInfo,
    setDateInfo,
  } = useContext(StoreContext);

  const formatDate = (dateDay) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      day: "2-digit",
      year: "numeric",
    };
    const formattedDate = dateDay.toLocaleString("en-US", options);

    return formattedDate;
  };

  const getAppointmentPdfMethod = async () => {
    const response = await getAppointmentPdf();
    if (response.error) {
      console.log("Randevu pdfi gelmedi");
      console.log(response.error, "response.erro");
    } else {
      console.log("Randevu pdfi geldi");
    }
  };

  /*const getTodayDateMethod = async () => {
    const response = await getTodayDate();
    if (response.error) {
      console.log(response.error, "response.error");
    } else {
      setDateInfo(response.data);
      console.log(response.data, "response.data Dateınfo");
    }
  }; */

  return (
    <div className={style.container}>
      <div className={style.appointments}>
        <div className={style.appointmentstext}>Randevu</div>
        <Link>
          <img alt="" className={style.appointmentsvideo} src={video}></img>
        </Link>
      </div>
      <div className={style.search}>
        <button className={style.searchicon}>
          <img alt="" src={search}></img>
        </button>
        <input
          className={style.searchinput}
          placeholder="Ara"
          onChange={(e) => {
            setAppointmentInputValue(e.target.value);
          }}
          value={appointmentInputValue}
        ></input>
      </div>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        <img alt="" className={style.reload} src={reload}></img>
      </button>
      <div className={style.tabs}>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              setSettingsAppointmentClick(true);
             // getTodayDateMethod(formatDate(new Date()));
            }}
          >
            <img alt="" src={settings}></img>
          </button>
          <div className={style.tabtext}>Randevu Ayarları</div>
        </div>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              setAddAppointmentClick(true);
            }}
          >
            <img alt="" src={plus}></img>
          </button>
          <div className={style.tabtext}>Yeni Randevu</div>
        </div>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              //    getAppointmentPdfMethod();
            }}
          >
            <img alt="" src={pdf}></img>
          </button>
          <div className={style.tabtext}>PDF Yazdır</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
