import style from "../styles/Payments.module.css";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Payment/PaymentBar";
import AddPaymentModal from "../components/Payment/AddPaymentModal";
import { useContext, useState, useEffect } from "react";
import StoreContext from "../context/store";
import SecondBar from "../components/Payment/PaymentSecondBar";
import { getPaymentDetail, getPayments } from "../api/general";
import PaymentTable from "../components/Payment/PaymentTable";

const Payment = () => {
  const { addPaymentClick, selectedPaymentSection } = useContext(StoreContext);

 
  const [paymentId, setPaymentId] = useState(
    `${window.location.pathname.split("/")[2].split("-")[0]}`
  );

  

  return (
    <div className={style.page}>
      {addPaymentClick && <AddPaymentModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <PaymentTable  paymentId={paymentId}/>
        </div>
      </div>
    </div>
  );
};

export default Payment;
