import { Checkbox } from "@mui/material";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { getClassWithStudents, getClassrooms } from "../api/general";
import { useEffect } from "react";
import { useContext } from "react";
import StoreContext from "../context/store";

const customStyles = {
  rows: {
    style: {
      minHeight: "90px", // override the row height
      Width: "280px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "12px", // override the cell padding for head cells
      paddingRight: "8px",
      fontSize: "20px",
    },
  },
  cells: {
    style: {
      paddingLeft: "14px", // override the cell padding for data cells
      paddingRight: "8px",
      borderBottomStyle: "solid",
      borderBottomWidth: "4px",
      fontSize: "20px",
    },
  },
};

const Datatable = ({tableData,setTableData}) => {
  const { attendance, selectedSiniff, setSelectedSiniff } =
    useContext(StoreContext);
    console.log("datableS", tableData);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    if(selectedSiniff !== {})
      getStudentMethod(selectedSiniff);
  }, [selectedSiniff]);

  const [data, setData] = useState();
  const [selectedRow, setSelectedRow] = useState(null);

  const getStudentMethod = async (sinif) => {
    debugger;
    const response = await getClassWithStudents(sinif.key);
    if (response.error) {
      console.log("Test ogrencilerrr gelmedi");
    } else {
      console.log("Test ogrenciler geldiiiiiii");
      setTableData(response.data.result);
    }
  };

  const columns = [
    {
      name: "Öğrenci No",
      selector: (row) => row.ogrenciNo,    
    },
    {
      name: "Öğrenci Adı ",
      selector: (row) => row.ogrenciAdi,     
    },
    {
      name: "Yok",
      selector: (row) => row.option1,
      cell: (rowinfo) => {
        return (
          <Checkbox
            type="checkbox"
            name="option1"
            value={rowinfo.option1}
            onChange={(e) => handleChange(e.target.name,e.target.checked,rowinfo.id)}
          />
        );
      },
    },
    {
      name: "Geç",
      selector: (row) => row.option2,
      cell: (rowinfo) => {
        return (
          <Checkbox
            type="checkbox"
            name="option2"
            value={rowinfo.option2}
            onChange={(e) => handleChange(e.target.name,e.target.checked,rowinfo.id)}
          />
        );
      },

    },
    {
      name: "Nöbetçi",
      selector: (row) => row.option3,
      cell: (rowinfo) => {
        return (
          <Checkbox
            type="checkbox"
            name="option3"
            value={rowinfo.option3}
            onChange={(e) => handleChange(e.target.name,e.target.checked,rowinfo.id)}
          />
        );
      },
    },
    {
      name: "Faaliyet",
      selector: (row) => row.option4,
      cell: (rowinfo) => {
        return (
          <Checkbox
            type="checkbox"
            name="option4"
            value={rowinfo.option4}
            onChange={(e) => handleChange(e.target.name,e.target.checked,rowinfo.id)}
          />
        );
      },
    },
    {
      name: "İzinli",
      selector: (row) => row.option5,
      cell: (rowinfo) => {
        return (
          <Checkbox
            type="checkbox"
            name="option5"
            value={rowinfo.option5}
            onChange={(e) => handleChange(e.target.name,e.target.checked,rowinfo.id)}
          />
        );
      },
    },
    {
      name: "Raporlu",
      selector: (row) => row.option6,
      cell: (rowinfo) => {
        return (
          <Checkbox
            type="checkbox"
            name="option6"
            value={rowinfo.option6}
            onChange={(e) => handleChange(e.target.name,e.target.checked,rowinfo.id)}
          />
        );
      },

    },
    {
      name: "Detay",
      selector: (row) => row.detay,
      cell: (rowinfo) => {
        return <input type="text" 
        name="detay"
        onChange={(e) => handleChange(e.target.name,e.target.value,rowinfo.id) }
        className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" 
        placeholder="Detay"/>;
      },
      style:{

      }
    },
  ];

  const handleChange = (name,value,id) => {
    let index = tableData.findIndex(s=> s.id === id);
    const updatedChecks = [...tableData];
    updatedChecks[index][name] = value
    setTableData(updatedChecks)
  };

  return (
    <DataTable
      columns={columns}
      data={tableData}
      customStyles={customStyles}
    />
  );
};

export default Datatable;
