import { getMessaging,getToken, onMessage } from 'firebase/messaging';
import app from './firebase';


const messaging = getMessaging(app);

export const requestPermission = async () => {
  try {
    await Notification.requestPermission();
    console.log('Notification permission granted.');
  } catch (error) {
    console.error('Notification permission request failed:', error);
  }
};

export const subscribeTopic = async (topic) => {
  try {
    const token = await getToken(messaging);
    await fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `key=AAAAEYVdj84:APA91bGMI7UZcJolRqv0gzYV2amClRdFVimNzGYesr-r96nUuqFMDcMH0ZG264TptSkzMhNPWaPOeXIdoF8MUpS-LpUFJYxYcT3mAm8wDYfnkaPXEC7nynQuJFJ5I8BeoLNQvZWLbKNe`, // Firebase Console'da bulunan "Cloud Messaging" sekmesinden Server Key'i alın
      }),
    });
    console.log(`Successfully  to token`);
    console.log(`Successfully subscribed to topic:`);
  } catch (error) {
    console.error(`Error subscribing to topic: ${topic}`, error);
  }
};




export const showNotification = (title, body) => {
  debugger;
  const notificationOptions = {
    body,
    
  };
  window.registration.showNotification(title, notificationOptions);
};

// Firebase Messaging'den gelen bildirimleri dinleme


const NotificationService = {
  
  requestPermission,
  //getTokenFromMessaging,
  showNotification,
  subscribeTopic
};


export default NotificationService;