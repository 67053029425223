import style from "./style.module.css";
import lectureinfo from "../../../assets/modalIcons/lectureinfo.svg";
import cross from "../../../assets/modalIcons/cross.svg";
import deleteicon from "../../../assets/modalIcons/delete.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { deleteAttendance, editAttendance } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";

const EditAttendanceModal = ({ attendanceId }) => {
  const { setEditAttendanceClick, attendance } = useContext(StoreContext);

  const [nameSurname, setNamesurname] = useState(attendance.nameSurname);
  const [password, setPassword] = useState("");
  const [unvan, setUnvan] = useState(attendance.unvan);
  const [brans, setBrans] = useState(attendance.brans);
  const [phone, setPhone] = useState(attendance.phone);
  const [sinif, setSinif] = useState(attendance.sinif);
  const [sosyal, setSosyal] = useState(attendance.sosyal);
  const [email, setEmail] = useState(attendance.mail);

  const deleteAttendanceMethod = async () => {
    const response = await deleteAttendance(attendanceId);
    if (response.error) {
      console.log("Yoklama silme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Yoklama silinemedi");
    } else {
      console.log("Yoklama silme başarılı");
      successMessage("Yoklama silindi");
      setEditAttendanceClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  const editAttendanceMethod = async () => {
    if (
      nameSurname === "" ||
      unvan === "" ||
      brans === "" ||
      phone === "" ||
      email === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await editAttendance(
      nameSurname,
      password,
      unvan,
      brans,
      phone,
      sinif,
      sosyal,
      email,
      attendanceId
    );
    if (response.error) {
      console.log("Yoklama güncelleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Yoklama güncellenemedi");
    } else {
      console.log("Yoklama güncelleme başarılı");
      successMessage("Yoklama güncellendi");
      setEditAttendanceClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button className={style.tabicon1}>
                <img alt="" src={lectureinfo}></img>
              </button>
              <div className={style.tabtext1}>Yoklama Bilgileri</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setEditAttendanceClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci Adı</div>
            <input
              className={style.inputtext}
              value={nameSurname}
              onChange={(e) => setNamesurname(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci Soyadı</div>
            <input
              className={style.inputtext}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci No</div>
            <input
              className={style.inputtext}
              value={unvan}
              onChange={(e) => setUnvan(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci Sınıfı</div>
            <input
              className={style.inputtext}
              value={brans}
              onChange={(e) => setBrans(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Devamsızlık Sayısı  </div>
            <input
              className={style.inputtext}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></input>
               <div className={style.inputtitle}>Raporlu Devamsızlık </div>
               <input
              className={style.inputtext}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Veli Adı Soyadı</div>
            <input
              className={style.inputtext}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Telefonu</div>
            <input
              className={style.inputtext}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
          {/*   <div className={style.input}>
            <div className={style.inputtitle}>Sınıfı</div>
            <input
              className={style.inputtext}
              value={sinif}
              onChange={(e) => setSinif(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Sosyal Kulüp</div>
            <input
              className={style.inputtext}
              value={sosyal}
              onChange={(e) => setSosyal(e.target.value)}
            ></input>
          </div> */}
        </div>
        <div className={style.lowertabs}>
        </div>
      </div>
    </div>
  );
};

export default EditAttendanceModal;
