import { initializeApp } from "firebase/app";
import { getMessaging,onMessage } from 'firebase/messaging'; // 'sw' eklenmiş
import { getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import toast from 'react-hot-toast';

const firebaseConfig = {
  apiKey: 'AIzaSyC0E687DeLbEbEaci5mqicDWy7cvKwK5oE',
  authDomain: 'okulumnet-76479.firebaseapp.com',
  projectId: 'okulumnet-76479',
  storageBucket: 'okulumnet-76479.appspot.com',
  messagingSenderId: '75251945422',
  appId: '1:75251945422:web:ea993234b7e5d07c58e59f',
  measurementId: 'G-7PV1LMR38D'
};

const app = initializeApp(firebaseConfig);
export const autho =getAuth(app);
export const db = getFirestore(app)
const messaging = getMessaging(app);



export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
export default app;
