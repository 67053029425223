import { Navigate, Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";
import StoreContext from "../context/store";

const NonPrivateRoutes = () => {
  const { isAuth, controlToken, isLoading } = useContext(StoreContext);

  useEffect(() => {
    controlToken();
  }, []);

  if (isLoading) {
    return <div className="">Loading...</div>;
  }

  return !isAuth ? <Outlet /> : <Navigate to="/" replace={true} />;
};

export default NonPrivateRoutes;
