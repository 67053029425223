import style from "./style.module.css";

import tick from "../../../../assets/modalIcons/tick.svg";
import { useContext, useState, useRef } from "react";
import StoreContext from "../../../../context/store";

import useClickOutside from "../../../../hooks/useClickOutside";

import lectures from "../../../../assets/modalIcons/settings.svg"
import save from "../../../../assets/modalIcons/save.svg"
import cross from "../../../../assets/modalIcons/cross.svg"
import dropdown from "../../../../assets/arrowdown.svg"

const Settings = () => {
    const { setSettingsModal } =
        useContext(StoreContext);


    const [optionArea2, setOptionArea2] = useState(false);
    const [algorithm, setAlgorithm] = useState("Standart")
    const [salgorithm, setSelectedAlgorithm] = useState("")
    const buttonRef2 = useRef(null);

    const [isToggleActive, setIsToggleActive] = useState(false)



    useClickOutside(buttonRef2, () => {
        setOptionArea2(false);
    });

    const handleToggle = () => {
        if (isToggleActive) {
            setIsToggleActive(false)            
        } else {
            setIsToggleActive(true)
        }

    }

    const handleToggle2 = () => {
        setOptionArea2(!optionArea2);
    };

    const handleSelectAlgorithm = (event) => {
        setSelectedAlgorithm(event.target.value);
        setOptionArea2(false);
    };

    return (
        <div className={style.modal}>
            <div className={style.modalbox}>
                <div className={style.uppertabs}>
                    <div className={style.tableftside}>
                        <div className={style.tab}>
                            <button className={style.tabicon_left}>
                                <img alt="" src={lectures}></img>
                            </button>
                            <div className={style.tabtext_left}>Ayarlar </div>
                        </div>
                    </div>
                    <div className={style.tabrightside}>
                        <div className={style.tab}>
                            <button className={style.tabicon_right} onClick={() => {
                                setSettingsModal(false);
                            }}>
                                <img alt="" src={cross}></img>
                            </button>
                            <div className={style.tabtext_right}>Kapat</div>
                        </div>
                    </div>
                </div>
                <div className={style.contents}>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Algoritma</div>
                        <div className={style.dropdown}>
                            <div className={style.selectedoption}>{salgorithm}</div>
                            <button onClick={handleToggle2}>
                                <img src={dropdown} alt=""></img>
                            </button>

                            {optionArea2 && (
                                <div className={style.optionarea} ref={buttonRef2}>
                                    <div>
                                        <button
                                            onClick={handleSelectAlgorithm}
                                            className={style.option}
                                            value={algorithm}
                                        >{algorithm}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Algoritma Derinliği</div>
                        <input
                            className={style.inputtext}
                        ></input>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Bir öğretmen için günlük en fazla boşluk</div>
                        <input
                            className={style.inputtext}
                        ></input>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Bir öğretmen için haftalık en fazla boşluk</div>
                        <input
                            className={style.inputtext}
                        ></input>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Bir öğretmene verilen günlük en fazla ders</div>
                        <input
                            className={style.inputtext}
                        ></input>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Bir dersin yerleşmesi için en fazla süre (sn)</div>
                        <input
                            className={style.inputtext}
                        ></input>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Çalışma Süresi (sn)</div>
                        <input
                            className={style.inputtext}
                        ></input>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Öğretmen aynı sınıfın birden fazla dersine giriyorsa bu dersler farklı günlere gelsin:</div>
                        <div className={style.buttoncontainer}>
                            <input
                                type="button"
                                className={`${isToggleActive ? style.activeinputbutton : style.inputbutton}`}
                                onClick={handleToggle}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className={style.lowertabs}>
                    <div className={style.tableftside}></div>
                    <div className={style.tabrightside}>
                        <div className={style.tab}>
                            <button
                                className={style.tabicon_right}
                                onClick={() => {
                                    setSettingsModal(false);
                                }}
                            >
                                <img alt="" src={save}></img>
                            </button>
                            <div className={style.tabtext_right}>Kaydet</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings;