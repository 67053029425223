import style from "./style.module.css";
import lectureinfo from "../../../assets/modalIcons/lectureinfo.svg";
import cross from "../../../assets/modalIcons/cross.svg";
import deleteicon from "../../../assets/modalIcons/delete.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import appointmenticon from "../../../assets/modalIcons/appointmenticon.svg";
import dropdown from "../../../assets/arrowdown.svg";
import calendar from "../../../assets/calendaricon.svg";
import clock from "../../../assets/clockicon.svg";
import { useContext, useState, useRef, useEffect } from "react";
import StoreContext from "../../../context/store";
import {
  deleteAppointment,
  editAppointment,
  getClassrooms,
  getUnits,
} from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import useClickOutside from "../../../hooks/useClickOutside";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";

const EditAppointmentModal = ({ appointmentId }) => {
  const { setEditAppointmentClick, appointment } = useContext(StoreContext);

  const [appointmentdate, setAppointmentDate] = useState(
    new Date(appointment.tarih)
  );

  const [appointmenttime, setAppointmentTime] = useState(new Date());
  const [appointmentunit, setAppointmentUnit] = useState(
    appointment.randevubirim
  );
  const [appointmentissue, setAppointmentIssue] = useState(appointment.konu);
  const [appointmentperson, setAppointmentPerson] = useState(
    appointment.randevualan
  );
  const [appointmentstudent, setAppointmentStudent] = useState(
    appointment.studentname
  );
  const [appointmentclassroom, setAppointmentClassroom] = useState(
    appointment.sinif_name
  );
  const [appointmentclassroomId, setAppointmentClassroomId] = useState(
    appointment.sinif_id
  );
  const [appointmentapproval, setAppointmentApproval] = useState(
    appointment.onay
  );
  const [appointmentstatus, setAppointmentStatus] = useState(appointment.durum);
  const [appointmentview, setAppointmentView] = useState(appointment.gorus);

  useEffect(() => {
    formatTime(appointment.saat);
  }, []);

  const formatTime = (x) => {
    const time = x;
    const [hour, minute, second] = time.split(":");
    const date = new Date();
    date.setHours(hour, minute, second);
    const dateString = date.toISOString();

    setAppointmentTime(new Date(dateString));
  };

  // FOR DROPDOWN1

  const [optionArea, setOptionArea] = useState(false);
  const buttonRef = useRef(null);

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const handleAppointmentSelect = (event) => {
    setAppointmentApproval(event.target.value);
    setOptionArea(false);
  }; //
  // FOR DROPDOWN2

  const [optionArea1, setOptionArea1] = useState(false);
  const buttonRef1 = useRef(null);

  useClickOutside(buttonRef1, () => {
    setOptionArea1(false);
  });

  const handleToggle1 = () => {
    setOptionArea1(!optionArea1);
  };

  const handleAppointmentStatusSelect = (event) => {
    setAppointmentStatus(event.target.value);
    setOptionArea1(false);
  }; //

  const deleteAppointmentMethod = async () => {
    const response = await deleteAppointment(appointmentId);
    if (response.error) {
      console.log("Randevu silme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Randevu silinemedi");
    } else {
      console.log("Randevu silme başarılı");
      successMessage("Randevu silindi");
      setEditAppointmentClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  // FOR CLASSROOM DROPDOWN

  const [optionArea2, setOptionArea2] = useState(false);
  const buttonRef2 = useRef(null);

  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassroomsMethod();
    getUnitsMethod();
  }, []); //

  // FOR CLASSROOM DROPDOWN

  useClickOutside(buttonRef2, () => {
    setOptionArea2(false);
  });

  const handleToggle2 = () => {
    setOptionArea2(!optionArea2);
  };

  const handleClassroomSelect = (event) => {
    setAppointmentClassroom(event.target.value.split(",")[0]);
    setAppointmentClassroomId(event.target.value.split(",")[1]);
    setOptionArea2(false);
  };

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
    }
  }; //

  //FOR UNIT DROPDOWN

  const [optionArea3, setOptionArea3] = useState(false);

  const [units, setUnits] = useState([]);

  const buttonRef3 = useRef(null);

  useClickOutside(buttonRef3, () => {
    setOptionArea3(false);
  });

  const handleToggle3 = () => {
    setOptionArea3(!optionArea3);
  };

  const getUnitsMethod = async () => {
    const response = await getUnits();
    if (response.error) {
      console.log("Birimler gelmedi");
    } else {
      console.log("Birimler geldi");
      setUnits(response.data);
    }
  }; //

  const editAppointmentMethod = async () => {
    if (
      appointmentdate === "" ||
      appointmenttime === "" ||
      appointmentunit === "" ||
      appointmentissue === "" ||
      appointmentperson === "" ||
      appointmentstudent === "" ||
      appointmentclassroom === "" ||
      appointmentapproval === "" ||
      appointmentstatus === "" ||
      appointmentview === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await editAppointment(
      appointmentId,
      appointmentdate,
      appointmenttime,
      appointmentunit,
      appointmentissue,
      appointmentperson,
      appointmentstudent,
      appointmentclassroomId,
      appointmentapproval,
      appointmentstatus,
      appointmentview
    );
    if (response.error) {
      console.log("Randevu güncelleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Randevu güncellenemedi");
    } else {
      console.log("Randevu güncelleme başarılı");
      successMessage("Randevu güncellendi");
      setEditAppointmentClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button className={style.tabicon1}>
                <img alt="" src={appointmenticon}></img>
              </button>
              <div className={style.tabtext1}>Randevu</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setEditAppointmentClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.inputcalendarandhour}>
            <div className={style.input2}>
              <div className={style.inputtitle2}>Tarih</div>
              <div className={style.inputtext2}>
                <DatePicker
                  className="bg-transparent border-0 outline-none pt-1 w-full "
                  timeZone="Europe/Istanbul"
                  selected={appointmentdate}
                  onChange={(date) => setAppointmentDate(date)}
                  dateFormat="dd/MM/yyyy"
                  locale={tr}
                  showPopperArrow={false}
                />

                <img src={calendar} className={style.icon}></img>
              </div>

              <img src={calendar} className={style.icon}></img>
            </div>
            <div className={style.input1}>
              <div className={style.inputtitle1}>Saat</div>
              <div className={style.inputtext1}>
                <DatePicker
                  className="bg-transparent border-0 outline-none pt-1 w-full"
                  selected={appointmenttime}
                  onChange={(time) => setAppointmentTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={10}
                  timeCaption="Saat"
                  dateFormat="HH:mm"
                  showPopperArrow={false}
                  locale={tr}
                  timeZone="UTC+3"
                />
              </div>
              <img src={clock} className={style.icon1}></img>
            </div>
          </div>

          <div className={style.input}>
            <div className={style.inputtitle}>Konu</div>
            <input
              className={style.inputtext}
              value={appointmentissue}
              onChange={(e) => setAppointmentIssue(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Randevu Alınan Birim</div>

            <div className={style.dropdown}>
              <div className={style.selectedoption}>{appointmentunit}</div>
              <button onClick={handleToggle3}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea3 && (
                <div className={style.optionarea} ref={buttonRef3}>
                  <div>
                    {[...new Set(units.map((unit) => unit.birimname))].map(
                      (birimname, index) => (
                        <button
                          onClick={(e) => {
                            setAppointmentUnit(e.target.value);

                            setOptionArea2(false);
                          }}
                          value={birimname}
                          key={index}
                          className={style.option}
                        >
                          {birimname}
                        </button>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Randevuyu Alan</div>
            <input
              className={style.inputtext}
              value={appointmentperson}
              onChange={(e) => setAppointmentPerson(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci Adı Soyadı</div>
            <input
              className={style.inputtext}
              value={appointmentstudent}
              onChange={(e) => setAppointmentStudent(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Sınıf</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{appointmentclassroom}</div>
              <button onClick={handleToggle2}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea2 && (
                <div className={style.optionarea} ref={buttonRef2}>
                  <div>
                    {classrooms &&
                      classrooms.map((classroom, index) => (
                        <button
                          onClick={handleClassroomSelect}
                          value={[classroom.sinifname, classroom.id]}
                          key={index}
                          className={style.option}
                        >
                          {classroom.sinifname}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Onay</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{appointmentapproval}</div>
              <button onClick={handleToggle}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea && (
                <div className={style.optionarea} ref={buttonRef}>
                  <div>
                    <button
                      onClick={handleAppointmentSelect}
                      value="Kabul"
                      className={style.option}
                    >
                      Kabul
                    </button>
                    <button
                      onClick={handleAppointmentSelect}
                      value="Red"
                      className={style.option}
                    >
                      Red
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Durum</div>

            <div className={style.dropdown}>
              <div className={style.selectedoption}>{appointmentstatus}</div>
              <button onClick={handleToggle1}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea1 && (
                <div className={style.optionarea} ref={buttonRef1}>
                  <div>
                    <button
                      onClick={handleAppointmentStatusSelect}
                      value="Yapıldı"
                      className={style.option}
                    >
                      Yapıldı
                    </button>
                    <button
                      onClick={handleAppointmentStatusSelect}
                      value="Yapılmadı"
                      className={style.option}
                    >
                      Yapılmadı
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Görüş</div>
            <textarea
              className={style.inputtext3}
              value={appointmentview}
              onChange={(e) => setAppointmentView(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button
                className={style.tabicon1}
                onClick={() => {
                  deleteAppointmentMethod();
                }}
              >
                <img alt="" src={deleteicon}></img>
              </button>
              <div className={style.tabtext1}>Sil</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  editAppointmentMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAppointmentModal;
