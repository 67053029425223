import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import deleteicon from "../../../assets/modalIcons/delete.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import photo from "../../../assets/modalIcons/photo.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { addStudent, deleteStudent, editStudent } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import { useEffect } from "react";
import { getClassnamesDD } from "../../../api/general";
import { getParents } from "../../../api/general";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";

const AddStudentModal = ({ studentId }) => {
  const {
    setAddStudentClick,
    setEditStudentClick,
    student,
    setStudent,
  } = useContext(StoreContext);

  const [selectedSinif, setSelectedSinif] = useState(student.sClass);
  const [selectedParents, setSelectedParents] =useState(student.parents);
  const [parents, setParents] = useState();
  const [image, setImage] = useState(null);

  // FOR CLASSROOM DROPDOWN

  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassroomsMethod();
    getParentsMethod();
  }, []); //

  // FOR CLASSROOM DROPDOWN

  const getParentsMethod = async () => {
    const response = await getParents();
    if (response.error) {
    } else {
      setParents(response.data);
    }
  };

  const getClassroomsMethod = async () => {
    const response = await getClassnamesDD();
    if (response.error) {
    } else {
      setClassrooms(response.data);
    }
  }; //

  const setVariable = (variableName, value) => {
    let temp = Object.assign({}, student);
    temp[variableName] = value;
    console.log("temp",temp)
    setStudent(temp);
  };
  

  const addStudentMethod = async () => {
    if (
      student.nameSurname === "" ||
      student.password === "" ||
      student.studentnumber === "" ||
      student.phone === "" ||
      student.email === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }
    let response;
    let message;
    if (studentId !== undefined) {
      response = await editStudent(studentId, student);
      message = "düzenleme";
    } else {
      response = await addStudent(student);
      message = "ekleme";
    }
    if (response.error) {
      errorMessage(`Ogrenci ${message} başarısız`);
    } else {
      successMessage(`Sınıf ${message} başarılı`);
      setAddStudentClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  const deleteStudentMethod = async () => {
    const response = await deleteStudent(studentId);
    if (response.error) {
      errorMessage("Ogrenci silinemedi");
    } else {
      successMessage("Ogrenci silindi");
      setEditStudentClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  const closeModal = () => {
    if (studentId !== undefined) setEditStudentClick(false);
    else setAddStudentClick(false);
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon} onClick={closeModal}>
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci Adı Soyadı </div>
            <input
              className={style.inputtext}
              value={student.nameSurname ?? ""}
              onChange={(e) => setVariable("nameSurname", e.target.value)}
            ></input>
          </div>

          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci No</div>
            <input
              type="number"
              className={style.inputtext}
              value={student.studentnumber ?? ""}
              onChange={(e) => setVariable("studentnumber", e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci Sınıfı</div>

            <Select
              value={selectedSinif}
              className={style.inputtext}
              onChange={(e) => {
                setSelectedSinif(e);
                setVariable("sClass", e);
              }}
              getOptionValue={(option) => option.key}
              getOptionLabel={(option) => option.value}
              options={classrooms}
              placeholder="Sınıf Seçimi..."
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: "1px solid #3733bf",
                  backgroundColor: "#EEF8FF",
                  width: "102%",
                  height: "30px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  margin: "-6px",
                  marginLeft: "-9px",
                }),
              }}
            />
          </div>

          <div className={style.input}>
            <div className={style.inputtitle}>Şifre</div>
            <input
              className={style.inputtext}
              value={student.password ?? ""}
              onChange={(e) => setVariable("password", e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Veli Adı Soyadı</div>
            <div className={style.inputtext}>
            <Multiselect
              displayValue="value"
              onSelect={(e) => {
                setSelectedParents(e);
                setVariable("parents",e);
              }}
              selectedValues={selectedParents}
              onKeyPressFn={function noRefCheck() {}}
              onRemove={(e) => {
                setSelectedParents(e);
                setVariable("parents",e);
              }}
              onSearch={function noRefCheck() {}}
              getOptionValue={(option) => option.key}
              getOptionLabel={(option) => option.value}
              options={parents}
              placeholder=""
              style={{
                chips: {
                  background: "#3733bf",
                  height: "20px",
                },
                multiselectContainer: {
                  color: '#3733bf',
                  border: 'none',
                  width: '100%',
                  height: "30px",
                },
                searchBox: {
                  border: 'none',
                  width: '100%',
                  height: "30px",
                  padding: "0px 0px 0px 5px",
                }
              }
             
              }
            />
          </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Telefonu</div>
            <input
              className={style.inputtext}
              value={student.phone ?? ""}
              onChange={(e) => setVariable("phone", e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>E-mail</div>
            <input
              className={style.inputtext}
              value={student.email ?? ""}
              onChange={(e) => setVariable("email", e.target.value)}
            ></input>
          </div>
          <div className={style.tab2}>
            <input
              type="file"
              name="photo"
              id="upload-photo"
              className={style.photoinput}
              onChange={(event) => {
                setImage(event.target.files[0]);
              }}
            ></input>

            <img alt="" src={photo} className={style.tabicon2}></img>

            <div className={style.tabtext2}>Fotoğraf Yükle</div>
          </div>
        </div>

        <div className={style.lowertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              {studentId !== undefined && (
                <div className={style.tab1}>
                  <button
                    className={style.tabicon}
                    onClick={() => {
                      deleteStudentMethod();
                    }}
                  >
                    <img alt="" src={deleteicon}></img>
                  </button>
                  <div className={style.tabtext1}>Sil</div>
                </div>
              )}
            </div>
          </div>

          <div className={style.lowertabs}>
            <div className={style.tableftside}></div>
            <div className={style.tabrightside}>
              <div className={style.tab}>
                <button
                  className={style.tabicon}
                  onClick={() => {
                    addStudentMethod();
                  }}
                >
                  <img alt="" src={save}></img>
                </button>
                <div className={style.tabtext}>Kaydet</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStudentModal;
