import { useEffect, useState } from "react";
import { getWelldones } from "../api/general";
import style from "../styles/Welldones.module.css";
import WelldonesTable from "../components/Welldone/WelldonesTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Welldone/WelldoneBar";
import AddWelldoneModal from "../components/Welldone/AddWelldoneModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Welldones = () => {
  const { addWelldoneClick, welldoneInputValue } =
    useContext(StoreContext);

  const [welldones, setWelldones] = useState([]);
  console.log(welldones, "welldones");
  useEffect(() => {
    getWelldonesMethod();
  
  }, []);

  const getWelldonesMethod = async () => {
    const response = await getWelldones();
    if (response.error) {
      console.log("Test aferinler gelmedi");
    } else {
      console.log("Test aferinler geldi");
      setWelldones(response.data.result);
    }
  };

  return (
    <div className={style.page}>
      {addWelldoneClick && <AddWelldoneModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <WelldonesTable
            data={welldones}/>
        </div>
      </div>
    </div>
  );
};

export default Welldones;
