import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import EditSchoolroomModal from "../EditSchoolroomModal";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getSchoolroom } from "../../../api/general";

const SchoolroomsTable = ({
  title1 = "-",
  title2 = "Derslik Adı",
  title3 = "Kısa Adı",
  title4 = "Sınıf",
  title5 = "İşlem",
  data,
}) => {
  const {
    setEditSchoolroomClick,
    editSchoolroomClick,
    schoolroomId,
    setSchoolroomId,
    setSchoolroom,
  } = useContext(StoreContext);

  useEffect(() => {
    console.log("schoolroomId", schoolroomId);
  }, [schoolroomId]);

  const getSchoolroomMethod = async (schoolroomId) => {
    const response = await getSchoolroom(schoolroomId);
    if (response.error) {
      console.log("Derslik bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setSchoolroom(response.data[0]);
      console.log("Derslik bilgileri geldi");
    }
  };

  return (
    <div className={style.container}>
      {editSchoolroomClick && (
        <EditSchoolroomModal schoolroomId={schoolroomId} />
      )}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
      </div>
      {data &&
        data.map((schoolroom, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{index+1}</div>
            <div className={style.data2}>{schoolroom.derslikname}</div>
            <div className={style.data3}>{schoolroom.kisaad}</div>
            <div className={style.data4}>{schoolroom.sinif}</div>
            <div className={style.data5}>
              <button
                onClick={() => {
                  setSchoolroomId(schoolroom.id);
                  Promise.resolve(getSchoolroomMethod(schoolroom.id)).then(
                    () => {
                      setEditSchoolroomClick(true);
                    }
                  );
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SchoolroomsTable;
