import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import { useContext, useEffect,useState } from "react";
import StoreContext from "../../../context/store";
import { getPayment, getPaymentDetail } from "../../../api/general";

const PaymentTable = ({
  title1 = "-",
  title2 = "Sınıf Adı",
  title3 = "Öğrenci No",
  title4 = "Adı Soyadı",
  title5 = "Tutar",
  paymentId,
}) => {
  
  const [payments, setPayments] = useState([]);
  useEffect(() => {
    getPaymentDetailMethod(paymentId);
  }, [paymentId]);

  const getPaymentDetailMethod = async (paymentId) => {
    const response = await getPaymentDetail(paymentId);
    if (response.error) {
      console.log("Ödemein spesifik bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setPayments(response.data);
      console.log("Ödemein spesifik bilgileri geldi");
    }
  };



  return (
    <div className={style.container}>
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
      </div>
      {payments &&
        payments.map((payment, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{payment.id}</div>
            <div className={style.data2}>{payment.sinifadi}</div>
            <div className={style.data3}>{payment.ogrencino}</div>
            <div className={style.data4}>{payment.adsoyad}</div>
            <div className={style.data5}>{payment.tutar}</div>
          </div>
        ))}
    </div>
  );
};

export default PaymentTable;
