import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { addAttendance } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import icon1 from "../../../assets/welldoneIcons/odevbasarili.svg";
import {
  getClassnamesDD,
  getStudentsDD,
  getStudents,
  getClassWithStudents,
} from "../../../api/general";
import { useEffect } from "react";
import DataTable from "../../Datatable.js";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import TextField from "@mui/material/TextField";

const AddAttendanceModal = () => {
  const {
    setAddAttendanceClick,
    attendance,
    setAttendance,
    selectedSiniff,
    setSelectedSiniff,
  } = useContext(StoreContext);

  const [students, setStudents] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  //const [selectedSiniff, setSelectedSiniff] = useState({});
  const [selectedStudent, setSelectedStudent] = useState();
  const [tableData, setTableData] = useState();
  const [checks, setChecks] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [date, setDate] = useState(new Date());


  useEffect(() => {
    getWithClassMethod();
  }, [selectedSiniff]);

  const getWithClassMethod = async () => {
    const response = await getClassnamesDD();
    if (response.error) {
    } else {
      setClassrooms(response.data);
    }
  };

  const addAttendanceMethod = async () => {
     if (
        date === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }
    let response;
    let message;
    response = await addAttendance(tableData,date,attendance)
    message = "ekleme"
    if (response.error) {
      errorMessage(`Yoklama ${message} başarısız`);
    } else {
      successMessage(`Yoklama ${message} başarılı`);
      setAddAttendanceClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
      
    }
  }; 
  

  const setVariable = ( value) => {
    let temp = Object.assign({}, attendance);
    temp = value;
    setAttendance(temp);
  };

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);

    return selectedDate.toDateString();
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddAttendanceClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className=" flex space-x-8 py-8 justify-center item-center absolute inset-x-52 top-32 h-16 ...">
          <div className=" flex w-64 h-32  ">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
              <DatePicker
                //selected={null}
                value={null}
                name
                onChange={(date) => {
                  setDate(formatDateFn(date));
                }}
                inputVariant="outlined"
                label="Lütfen Tarih Seçin"
              />
            </LocalizationProvider>
          </div>
          <div className="p-0">
            <Select
              value={selectedSiniff}
              onChange={(e) => {
                setSelectedSiniff(e);
                setVariable( e !== null ? e.key : null);
              }}
              getOptionValue={(option) => option.key}
              getOptionLabel={(option) => option.value}
              isClearable={true}
              options={classrooms}
              placeholder="Sınıf Seçimi..."
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: " 1px solid #EEF8FF",
                  backgroundColor: "#EEF8FF",
                  width: "200px",
                  height: "40px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  margin: "7px",
                  marginLeft: "-9px",
                }),
              }}
            />
          </div>

          <div className="absolute inset-x-8 top-32 right-12 w-84 ">
            <DataTable setTableData={setTableData} tableData={tableData} />
          </div>
        </div>

        <div className="absolute bottom-14 right-28 h-16 w-20 ...">
          <div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                addAttendanceMethod();
              }}
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAttendanceModal;
