import AddWelldone from "../Welldone/AddWelldoneModal/index";
import WelldoneTable from "../Welldone/WelldonesTable/index";
import AddExam from "../Exam/AddExamModal/index";
import ExamTable from "../Exam/ExamsTable/index";
import { Outlet } from "react-router-dom";
import AddAttendance from "../Attendance/AddAttendanceModal/index";
import AttendanceTable from "../Attendance/AttendancesTable/index";
//import Welldones from "./pages/Welldone";
import { Link, useRoutes, useNavigate } from "react-router-dom";
import { useContext } from "react";
import StoreContext from "../../context/store";
import Welldones from "../../pages/Welldone";
import Header from "../../components/Header/HomeHeader";
import Meals from "../../pages/Meals"

const StudentPage = () => {
  const { userRole } = useContext(StoreContext);

  return (
    <div>
      <div>
        {userRole === "Student" && (
          <>
            <Outlet />
          </>
        )}
      </div>
    </div>
  );
};

export default StudentPage;
