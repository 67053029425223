import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import { StoreProvider } from "./context/store";
import Login from "./pages/Login";
import Home from "./pages/Home";
import PrivateRoutes from "./utils/private-route";
import NonPrivateRoutes from "./utils/non-private-route";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Teachers from "./pages/Teachers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Students from "./pages/Students";
import Classrooms from "./pages/Classrooms";
import Lectures from "./pages/Lecture";
import Appointments from "./pages/Appointments";
import CourseAssignments from "./pages/CourseAssignments";
import Schoolrooms from "./pages/Schoolrooms";
import SocialClubs from "./pages/SocialClubs";
import Welldones from "./pages/Welldone";
import Etudes from "./pages/Etude";
import Messages from "./pages/Messages";
import Announcements from "./pages/Announcements";
import Attendances from "./pages/Attendance";
import Meals from "./pages/Meals";
import Payments from "./pages/Payments";
import Payment from "./pages/Payment";
import Exams from "./pages/Exams";
import Programs from "./pages/Programs";
import { ToastProvider } from 'react-toast-notifications';
import Notification from "./components/Notification";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route element={<PrivateRoutes />}>
        <Route index element={<Home />} />
        <Route path="/ogretmenler" element={<Teachers />} />
        <Route path="/ogrenciler" element={<Students />} />
        <Route path="/siniflar" element={<Classrooms/>} />
        <Route path="/dersler" element={<Lectures />} />
        <Route path="/programlar" element={<Programs/>}/>
        <Route path="/randevular" element={<Appointments />} />
        <Route path="/dersatamalari" element={<CourseAssignments/>} />
        <Route path="/derslikler" element={<Schoolrooms/>} />
        <Route path="/sosyal-kulupler" element={<SocialClubs />} />
        <Route path="/aferin" element={<Welldones />} />
        <Route path="/etut" element={<Etudes />} />
        <Route path="/mesajlar" element={<Messages />} />
        <Route path="/duyurular" element={<Announcements />} />
        <Route path="/yoklama" element={<Attendances />} />
        <Route path="/yemek" element={<Meals />} />
        <Route path="/odemeler" element={<Payments />} />
        <Route path="/odemeler/:odeme" element={<Payment />} />
        <Route path="/sınavlar" element={<Exams />} />
      </Route>
      <Route element={<NonPrivateRoutes />}>
        <Route path="/giris" element={<Login />}></Route>
        <Route path="/kayıt-ol" element={<Register />}></Route>
        <Route path="/sifremi-unuttum" element={<ForgotPassword />}></Route>
      </Route>
    </Route>
  )
);



const App = () => {
  return (
    <StoreProvider>
        <ToastProvider>
      <RouterProvider router={router} />
      <Notification />
      </ToastProvider>
      <ToastContainer />
    </StoreProvider>
    
  );
};

export default App;
