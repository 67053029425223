import style from "./style.module.css";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";

const AddClassRoomDay = ({day}) => {
  const {
    classroom,
    setClassroom
  } = useContext(StoreContext);
  
  const setValueHour = (hour,value,startEnd) => {
    let temp = Object.assign({},classroom);
    temp.classLessons[day].hours[hour][startEnd] = value;
    setClassroom(temp);
  } 
  console.log("sınıflar",classroom)
  const setVariable = (variableName,value) => {
    let temp = Object.assign({},classroom);
    temp.classLessons[day][variableName] = value
    setClassroom(temp);
  }

  const initializeHours = () => {
    let result = [];
    for (let i = 0; i < 9; i++) {
      result.push(<div className={style.hoursinput} key={i}>
        <div className={style.hoursinputtitle}>{i+1}.Ders</div>
        <input
          className={style.hoursinputtext}
          value={classroom.classLessons[day]?.hours[i].start ?? ""}
          onChange={(e) => setValueHour(i,e.target.value,"start")}
        ></input>
        <input
          className={style.hoursinputtext}
          value={classroom.classLessons[day]?.hours[i].end ?? ""}
          onChange={(e) => setValueHour(i,e.target.value,"end")}
        ></input>
      </div>);
    }
    return <>{result}</>;
  }

  return (
    <div className={style.container}>
      <div className={style.lessonshoursinterval}>
        <div className={style.text}>Ders Saatleri Aralığı</div>
        <input
          className={style.input2}
          value={classroom.classLessons[day]?.baslangic ?? ""}
          onChange={(e) => { setVariable("baslangic",e.target.value)}}
        ></input>
        <div className={style.text}>İle</div>
        <input
          className={style.input2}
          value={classroom.classLessons[day]?.bitis ?? ""}
          onChange={(e) => { setVariable("bitis",e.target.value)}}
        ></input>
        <div className={style.text}>Arası</div>
      </div>

      <div className={style.lessonhoursarea}>
        <div className={style.hoursinputs}>
          <div className={style.hourstitles}>
            <div className={style.hourstitle}>Giriş</div>
            <div className={style.hourstitle}>Çıkış</div>
          </div>
          {initializeHours()}
        </div>
      </div>
    </div>
  );
};

export default AddClassRoomDay;
