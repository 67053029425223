import AssignmentCopy from "../components/CourseAssignment/CourseAssignmentSections/AssignmentCopy";
import ClassroomCourseAssignment from "../components/CourseAssignment/CourseAssignmentSections/ClassroomCourseAssignment";
import CommonCourseAssignment from "../components/CourseAssignment/CourseAssignmentSections/CommonCourseAssignment";
import MissingAssignment from "../components/CourseAssignment/CourseAssignmentSections/MissingAssignment";
import TeacherCourseAssignment from "../components/CourseAssignment/CourseAssignmentSections/TeacherCourseAssignment";
import SchoolroomCourseAssignment from "../components/CourseAssignment/CourseAssignmentSections/SchoolroomCourseAssignment";

export const checkAssigmentSections = (selectedSection) => {
  switch (selectedSection) {
    case "1":
      return <ClassroomCourseAssignment />;

    case "2":
      return <TeacherCourseAssignment />;

    case "3":
      return <SchoolroomCourseAssignment />;

    case "4":
      return <CommonCourseAssignment />;

    case "5":
      return <AssignmentCopy />;

    case "6":
      return <MissingAssignment />;

    default:
      return <ClassroomCourseAssignment />;
  }
};
