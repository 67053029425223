import { useEffect, useState } from "react";
import { getClassrooms } from "../api/general";
import style from "../styles/Classrooms.module.css";
import ClassroomsTable from "../components/Classroom/ClassroomsTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Classroom/ClassroomBar";
import AddClassroomModal from "../components/Classroom/AddClassroomModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Classrooms = () => {
  const { addClassroomClick, classroomInputValue } = useContext(StoreContext);

  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassroomsMethod();
  }, []);

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
      console.log(response.error);
    } else {
      setClassrooms(response.data.result);
    }
  };

  return (
    <div className={style.page}>
      {addClassroomClick && <AddClassroomModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <ClassroomsTable data={classrooms} />
        </div>
      </div>
    </div>
  );
};

export default Classrooms;
