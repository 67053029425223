import style from "./style.module.css";
import search from "../../../../assets/search.svg";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../../context/store";
import {
  addTeacherLectureAssigns,
  getClassroomLectureAssigns,
  getClassrooms,
  getTeacherLectureAssigns,
  getTeachers,
  teacherLectureAssignsDelete,
} from "../../../../api/general";
import cn from "classnames";
import assignment from "../../../../assets/assign.svg";
import whiteassignment from "../../../../assets/whiteassign.svg";
import whitedelete from "../../../../assets/assignwhitedelete.svg";
import assigndelete from "../../../../assets/assigndelete.svg";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../../helpers/toast";

const TeacherCourseAssignment = ({
  title5 = "Öğretmen Adı ",
  title6 = "Branş",
  title7 = "Atama Sayısı",

  title1 = "Sıra",
  title2 = "Sınıf Adı",
  title3 = "Toplam",

  title8 = "Ders Adı",
  title9 = "Atanan Öğretmen",
  title10 = "HDS",
  title11 = "Ata",

  title12 = "Sınıf",
  title13 = "Ders",
  title14 = "HDS",
  title15 = "Sil",
}) => {
  const {
    teacherCourseAssignmentTeacherInputValue,
    setTeacherCourseAssignmentTeacherInputValue,
    teacherCourseAssignmentClassroomInputValue,
    setTeacherCourseAssignmentClassroomInputValue,
    teacherCourseAssignmentLectureAssignInputValue,
    setTeacherCourseAssignmentLectureAssignInputValue,
  } = useContext(StoreContext);

  const [teachers, setTeachers] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [selectedTeacherName, setSelectedTeacherName] = useState("");

  const [classrooms, setClassrooms] = useState([]);
  const [selectedClassroomId, setSelectedClassroomId] = useState("");
  const [selectedClassName, setSelectedClassName] = useState("");

  const [lectureassigns, setLectureAssigns] = useState([]);
  const [selectedLectureAssignId, setSelectedLectureAssignId] = useState("");

  const [teacherlectureassigns, setTeacherLectureAssigns] = useState([]);
  const [selectedTeacherLectureAssignId, setSelectedTeacherLectureAssignId] =
    useState("");

  useEffect(() => {
    getClassroomsMethod();
    getTeachersMethod();
  }, []);

  //teacher

  const getTeachersMethod = async () => {
    const response = await getTeachers();
    if (response.error) {
      console.log("Test öğretmenler gelmedi");
    } else {
      console.log("Test öğretmenler geldi");
      setTeachers(response.data);
    }
  };

  const styleSelectedTeacher = (id) => {
    if (selectedTeacherId === id) {
      return style.sdatas3;
    } else {
      return style.datas3;
    }
  };

  //classroom

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
      console.log(response.data, "response.data.classrooms");
    }
  };

  const styleSelectedClassroom = (id) => {
    if (selectedClassroomId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  //lectureassign

  const getClassroomLectureAssignsMethod = async (selectedClassroomId) => {
    const response = await getClassroomLectureAssigns(selectedClassroomId);
    if (response.error) {
      console.log("Test ders atamaları gelmedi");
    } else {
      console.log("Test ders atamaları geldi");
      setLectureAssigns(response.data);
    }
  };

  const styleSelectedLectureAssign = (id) => {
    if (selectedLectureAssignId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  const styleSelectedLectureAssignImg = (id) => {
    if (selectedLectureAssignId === id) {
      return <img src={whiteassignment}></img>;
    } else {
      return <img src={assignment}></img>;
    }
  };

  const addTeacherLectureAssignsMethod = async (
    selectedLectureId,
    selectedTeacherId,
    selectedClassroomId
  ) => {
    const response = await addTeacherLectureAssigns(
      selectedLectureId,
      selectedTeacherId,
      selectedClassroomId
    );
    if (response.error) {
      console.log("Ders öğretmene eklenemedi");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Ders öğretmene eklenemedi");
    } else {
      console.log("Ders sınıfa eklendi");
      successMessage("Ders öğretmene eklendi");

      getTeacherLectureAssignsMethod(selectedTeacherId);
      getClassroomLectureAssignsMethod(selectedClassroomId);

      /*  setTimeout(function () {
        window.location.reload();
      }, 2500); */
    }
  };

  //teacherlectureassign

  const getTeacherLectureAssignsMethod = async (selectedTeacherId) => {
    /*  if (selectedTeacherId === "" ) {
      return;
    } */
    const response = await getTeacherLectureAssigns(selectedTeacherId);
    if (response.error) {
      console.log("Test ders atamaları gelmedi");
    } else {
      console.log("Test ders atamaları geldi");
      setTeacherLectureAssigns(response.data);
    }
  };

  const deleteTeacherLectureAssignsMethod = async (
    selectedLectureId,
    selectedTeacherId,
    selectedClassroomId
  ) => {
    const response = await teacherLectureAssignsDelete(
      selectedLectureId,
      selectedTeacherId,
      selectedClassroomId
    );
    if (response.error) {
      console.log("Ders öğretmenden silinemedi");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Ders öğretmenden silinemedi");
    } else {
      console.log("Ders sınıftan silindi");
      successMessage("Ders öğretmenden silindi");

      getTeacherLectureAssignsMethod(selectedTeacherId);
      getClassroomLectureAssignsMethod(selectedClassroomId);

      /*  setTimeout(function () {
        window.location.reload();
      }, 2500); */
    }
  };

  const styleSelectedTeacherLectureAssign = (id) => {
    if (selectedTeacherLectureAssignId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  const styleSelectedTeacherLectureDeleteImg = (id) => {
    if (selectedTeacherLectureAssignId === id) {
      return <img src={whitedelete}></img>;
    } else {
      return <img src={assigndelete}></img>;
    }
  };

  return (
    <div className={style.container}>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>Öğretmenler</div>
          <div className={style.search1}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Öğretmen Ara"
              onChange={(e) => {
                setTeacherCourseAssignmentTeacherInputValue(e.target.value);
              }}
              value={teacherCourseAssignmentTeacherInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table1}>
          <div className={style.titles2}>
            <div className={style.title5}>{title5}</div>
            <div className={style.title6}>{title6}</div>
            <div className={style.title7}>{title7} </div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {teachers &&
              teachers
                .filter(
                  (el) =>
                    el.nameSurname
                      .toLowerCase()
                      .includes(
                        teacherCourseAssignmentTeacherInputValue.toLowerCase()
                      ) ||
                    el.brans
                      .toLowerCase()
                      .includes(
                        teacherCourseAssignmentTeacherInputValue.toLowerCase()
                      )
                )
                .map((teacher, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas4 : null,
                      styleSelectedTeacher(teacher.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedTeacherId(teacher.id);
                      setSelectedTeacherName(teacher.nameSurname);
                      getTeacherLectureAssignsMethod(teacher.id);
                    }}
                  >
                    <div className={style.data5}>{teacher.nameSurname} </div>
                    <div className={style.data6}>{teacher.brans}</div>
                    <div className={style.data7}>100</div>
                  </button>
                ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>Sınıflar</div>
          <div className={style.search}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Sınıf Ara"
              onChange={(e) => {
                setTeacherCourseAssignmentClassroomInputValue(e.target.value);
              }}
              value={teacherCourseAssignmentClassroomInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table}>
          <div className={style.titles}>
            <div className={style.title1}>{title1}</div>
            <div className={style.title2}>{title2}</div>
            <div className={style.title3}>{title3} </div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {classrooms &&
              classrooms
                .filter((el) =>
                  el.sinifname
                    .toLowerCase()
                    .includes(
                      teacherCourseAssignmentClassroomInputValue.toLowerCase()
                    )
                )
                .map((classroom, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas2 : null,
                      styleSelectedClassroom(classroom.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedClassroomId(classroom.id);
                      getClassroomLectureAssignsMethod(classroom.id);
                      setSelectedClassName(classroom.sinifname);
                    }}
                  >
                    <div className={style.data1}>{classroom.id}</div>
                    <div className={style.data2}>{classroom.sinifname}</div>

                    <div className={style.data3}>{classroom.id}</div>
                  </button>
                ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>
            {selectedClassName} Sınıfına Atanan Dersler
          </div>
          <div className={style.search2}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Ders Ara"
              onChange={(e) => {
                setTeacherCourseAssignmentLectureAssignInputValue(
                  e.target.value
                );
              }}
              value={teacherCourseAssignmentLectureAssignInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table2}>
          <div className={style.titles}>
            <div className={style.title8}>{title8}</div>
            <div className={style.title9}>{title9}</div>
            <div className={style.title10}>{title10} </div>
            <div className={style.title11}>{title11}</div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {lectureassigns &&
              lectureassigns
                .filter(
                  (el) =>
                    el.dersname
                      .toLowerCase()
                      .includes(
                        teacherCourseAssignmentLectureAssignInputValue.toLowerCase()
                      ) ||
                    el.atananogretmen
                      .toLowerCase()
                      .includes(
                        teacherCourseAssignmentLectureAssignInputValue.toLowerCase()
                      )
                )
                .map((lecture, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas2 : null,
                      styleSelectedLectureAssign(lecture.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedLectureAssignId(lecture.id);
                    }}
                  >
                    <div className={style.data8}>{lecture.dersname}</div>
                    <div className={style.data9}>{lecture.atananogretmen}</div>
                    <div className={style.data10}>{lecture.hds}</div>
                    <button
                      className={style.data11}
                      onClick={() => {
                        addTeacherLectureAssignsMethod(
                          lecture.id,
                          selectedTeacherId,
                          selectedClassroomId
                        );
                      }}
                    >
                      {styleSelectedLectureAssignImg(lecture.id)}
                    </button>
                  </button>
                ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>
            {selectedTeacherName} Atanan Dersler
          </div>
          <div className={style.search3}></div>
        </div>

        <div className={style.table3}>
          <div className={style.titles}>
            <div className={style.title12}>{title12}</div>
            <div className={style.title13}>{title13}</div>
            <div className={style.title14}>{title14} </div>
            <div className={style.title15}>{title15}</div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {teacherlectureassigns.map((lecture, index) =>
              lecture.id !== "" ? (
                <button
                  className={cn(
                    style.datas,
                    index % 2 === 0 ? style.datas2 : null,
                    styleSelectedTeacherLectureAssign(index)
                  )}
                  key={index}
                  onClick={() => {
                    setSelectedTeacherLectureAssignId(index);
                  }}
                >
                  <div className={style.data12}>{lecture.sinifname} </div>
                  <div className={style.data13}>{lecture.dersname}</div>
                  <div className={style.data14}>{lecture.hds} </div>

                  <button
                    className={style.data15}
                    onClick={() => {
                      deleteTeacherLectureAssignsMethod(
                        lecture.id,
                        selectedTeacherId,
                        selectedClassroomId
                      );
                    }}
                  >
                    {styleSelectedTeacherLectureDeleteImg(index)}
                  </button>
                </button>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherCourseAssignment;
