import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import EditWelldoneModal from "../EditWelldoneModal";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getWelldone } from "../../../api/general";
import AddWelldoneModal from "../AddWelldoneModal";

const WelldonesTable = ({
  title1 = "-",
  title2 = "Sınıf",
  title3 = "Öğrenci No",
  title4 = "Öğrenci Adı",
  title5 = "Aferin Sayısı",
  title6 = "İşlem",
  data,
}) => {
  const {
    setEditWelldoneClick,
    editWelldoneClick,
    welldoneId,
    setWelldoneId,
    setWelldone,
    welldone
  } = useContext(StoreContext);

  useEffect(() => {
    console.log("welldoneId", welldoneId);
  }, [welldoneId]);

  const getWelldoneMethod = async (welldoneId) => {
    const response = await getWelldone(welldoneId);
    if (response.error) {
      console.log("Aferin bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setWelldone(response.data.result);
      ;
      console.log("Aferin bilgileri geldi");
      console.log(response.data.result, "response.data.aferindata");
      console.log(welldone, "welldone")
    }
  };

  return (
    <div className={style.container}>
      {editWelldoneClick && <AddWelldoneModal welldoneId={welldoneId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
      </div>
      {data &&
        data.map((welldone, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{welldone.id}</div>
            <div className={style.data2}>{welldone.sinif}</div>
            <div className={style.data3}>{welldone.studentNumber}</div>
            <div className={style.data4}>{welldone.nameSurname}</div>
            <div className={style.data5}>{welldone.aferinSayisi}</div>
            <div className={style.data6}>
              <button
                onClick={() => {
                  setWelldoneId(welldone.id);
                  Promise.resolve(getWelldoneMethod(welldone.id)).then(() => {
                    setEditWelldoneClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default WelldonesTable;
