import style from "./style.module.css"

const TeachersTable = (
    {
        title1 = "Sıra",
        title2 = "Sınıf Adı",
        title3 = "Toplam",
        title4 = "Kalan",
        data,
    }
) => {
    console.log("data", data);
    return (
        <div className={style.container}>
            <div className={style.titles}>
                <div className={style.title1}>{title1}</div>
                <div className={style.title2}>{title2}</div>
                <div className={style.title3}>{title3} </div>
                <div className={style.title4}>{title4} </div>
            </div>
            <div className="flex flex-col max-h-[312px] overflow-y-auto gap-2 overflow-x-hidden">
                {data &&
                    data.map((teacher, index) => (
                        <button className={`${style.datas} ${index % 2 == 0 ? style.even : style.odd}`} key={index}>
                            <div className={style.data1}>{teacher.id}</div>
                            <div className={style.data2}>{teacher.nameSurname}</div>
                            <div className={style.data3}>{teacher.brans}</div>
                            <div className={style.data4}>{teacher.brans}</div>
                        </button>
                    ))}
            </div>
        </div>
    )
}

export default TeachersTable;