import { useEffect, useState } from "react";
import { getMeals } from "../api/general";
import style from "../styles/Meals.module.css";
import MealsTable from "../components/Meal/MealsTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Meal/MealBar";
import AddMealModal from "../components/Meal/AddMealModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Meals = () => {
  const { addMealClick, mealInputValue } =
    useContext(StoreContext);

  const [meals, setMeals] = useState([]);

  useEffect(() => {
    getMealsMethod();
    console.log(meals, "meals");
    console.log(mealInputValue, "mealInputValue");
  }, []);

  const getMealsMethod = async () => {
    const response = await getMeals();
    if (response.error) {
      console.log("Test ogretmenler gelmedi");
    } else {
      console.log("Test ogretmenler geldi");
      setMeals(response.data);
    }
  };

  return (
    <div className={style.page}>
      {addMealClick && <AddMealModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <MealsTable
            data={meals?.filter(
              (el) =>
                el.tarih
                  .toLowerCase()
                  .includes(mealInputValue.toLowerCase()) ||
                el.aksam
                  .toLowerCase()
                  .includes(mealInputValue.toLowerCase()) ||
                el.sabah.toLowerCase().includes(mealInputValue.toLowerCase())
                ||
                el.oglen.toLowerCase().includes(mealInputValue.toLowerCase())
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Meals;
