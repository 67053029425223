import style from "./style.module.css";
import menu from "../../../assets/modalIcons/menu.svg";
import cross from "../../../assets/modalIcons/cross.svg";
import deleteicon from "../../../assets/modalIcons/delete.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { deleteMeal, editMeal } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import DatePicker from "react-datepicker";
import calendar from "../../../assets/calendaricon.svg";
import tr from "date-fns/locale/tr";


const EditMealModal = ({ mealId }) => {
  const { setEditMealClick, meal } = useContext(StoreContext);

  const [date, setDate] = useState(new Date(meal.tarih));
  const [morning, setMorning] = useState(meal.sabah);
  const [afternoon, setAfternoon] = useState(meal.oglen);
  const [evening, setEvening] = useState(meal.aksam);

  const deleteMealMethod = async () => {
    const response = await deleteMeal(mealId);
    if (response.error) {
      console.log("Yemek silme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Yemek silinemedi");
    } else {
      console.log("Yemek silme başarılı");
      successMessage("Yemek silindi");
      setEditMealClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  const editMealMethod = async () => {
    if (date === "" || morning === "" || afternoon === "" || evening === "") {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await editMeal(
     date, morning, afternoon, evening,
      mealId
    );
    if (response.error) {
      console.log("Yemek güncelleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Yemek güncellenemedi");
    } else {
      console.log("Yemek güncelleme başarılı");
      successMessage("Yemek güncellendi");
      setEditMealClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button className={style.tabicon1}>
                <img alt="" src={menu}></img>
              </button>
              <div className={style.tabtext1}>Menü</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setEditMealClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
         <div className={style.inputs}>
          <div className={style.input2}>
            <div className={style.inputtitle}>Tarih</div>
            <div className={style.inputtext}>
              {" "}
              <DatePicker
                className="bg-transparent border-0 outline-none pt-1 w-full "
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="dd/MM/yyyy"
                locale={tr}
                showPopperArrow={false}
                timeZone="UTC+3"
              />
              <img src={calendar} className={style.icon}></img>
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Kahvaltı</div>
            <input
              className={style.inputtext}
              value={morning}
              onChange={(e) => setMorning(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğle Yemeği</div>
            <input
              className={style.inputtext}
              value={afternoon}
              onChange={(e) => setAfternoon(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Akşam Yemeği</div>
            <input
              className={style.inputtext}
              value={evening}
              onChange={(e) => setEvening(e.target.value)}
            ></input>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button
                className={style.tabicon1}
                onClick={() => {
                  deleteMealMethod();
                }}
              >
                <img alt="" src={deleteicon}></img>
              </button>
              <div className={style.tabtext1}>Sil</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  editMealMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMealModal;
