import style from "./style.module.css";
import video from "../../../assets/video.svg";
import search from "../../../assets/search.svg";
import reload from "../../../assets/reload.svg";
import plus from "../../../assets/plus.svg";
import pdf from "../../../assets/pdf.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import StoreContext from "../../../context/store";
import { getLecturePdf } from "../../../api/general";

const Bar = () => {
  const { setAddLectureClick, setLectureInputValue, lectureInputValue } =
    useContext(StoreContext);

  const getLecturePdfMethod = async () => {
    const response = await getLecturePdf();
    if (response.error) {
      console.log("Ders pdfi gelmedi");
      console.log(response.error, "response.erro");
    } else {
      console.log("Ders pdfi geldi");
    }
  };

  return (
    <div className={style.container}>
      <div className={style.lectures}>
        <div className={style.lecturestext}>Dersler</div>
        <Link>
          <img alt="" className={style.lecturesvideo} src={video}></img>
        </Link>
      </div>
      <div className={style.search}>
        <button className={style.searchicon}>
          <img alt="" src={search}></img>
        </button>
        <input
          className={style.searchinput}
          placeholder="Ara"
          onChange={(e) => {
            setLectureInputValue(e.target.value);
          }}
          value={lectureInputValue}
        ></input>
      </div>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        <img alt="" className={style.reload} src={reload}></img>
      </button>
      <div className={style.tabs}>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              setAddLectureClick(true);
            }}
          >
            <img alt="" src={plus}></img>
          </button>
          <div className={style.tabtext}>Yeni Ders</div>
        </div>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
            //  getLecturePdfMethod();
            }}
          >
            <img alt="" src={pdf}></img>
          </button>
          <div className={style.tabtext}>PDF Yazdır</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
