import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import AddStudentModal from "../AddStudentModal";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getStudent } from "../../../api/general";
import studentphoto from "../../../assets/studentphoto.svg";

const StudentsTable = ({
  title1 = "-",
  title2 = "Adı Soyadı",
  title3 = "Öğrenci No",
  title4 = "Sınıf",
  title5 = "Veli Ad Soyad",
  title6 = "Telefon",
  title7 = "İşlem",
  data,
}) => {
  const {
    setEditStudentClick,
    editStudentClick,
    studentId,
    setStudentId,
    setStudent,
  } = useContext(StoreContext);

  useEffect(() => {
   
  }, []);

  const getStudentMethod = async (studentId) => {
    
    const response = await getStudent(studentId);
    if (response.error) {
    } else {
      await setStudent(response.data.result);
    }
  };

  



  return (
    <div className={style.container}>
      {editStudentClick && <AddStudentModal studentId={studentId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
        <div className={style.title7}>{title7}</div>
      </div>
      {data &&
        data.map((student, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{student.id}</div>
            <div className={style.data2}><img alt="" className={style.photo} src={studentphoto}></img>{student.nameSurname}</div>
            <div className={style.data3}>{student.studentNumber}</div>
            <div className={style.data4}>{student.sClass}</div>
            <div className={style.data5}>{student.veliNameSurname}</div>
            <div className={style.data6}>{student.phone}</div>
            <div className={style.data7}>
              <button
                onClick={() => {
                  setStudentId(student.id);
                  Promise.resolve(getStudentMethod(student.id)).then(() => {
                    setEditStudentClick(true);
                 
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default StudentsTable;
