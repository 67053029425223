import { useEffect, useState } from "react";

import style from "../styles/Appointments.module.css";
import AppointmentsTable from "../components/Appointment/AppointmentsTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Appointment/AppointmentBar";
import AddAppointmentModal from "../components/Appointment/AddAppointmentModal";
import { useContext } from "react";
import StoreContext from "../context/store";
import SettingsAppointmentModal from "../components/Appointment/SettingsAppointmentModal";

const Appointments = () => {
  const {
    addAppointmentClick,
    appointmentInputValue,
    settingsAppointmentClick,
  } = useContext(StoreContext);

  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
  }, []);

 /* const getAppointmentsMethod = async () => {
    const response = await getAppointments();
    if (response.error) {
      console.log("Test randevular gelmedi");
    } else {
      console.log("Test randevular geldi");
      setAppointments(response.data);
    }
  };*/

  return (
    <div className={style.page}>
      {addAppointmentClick && <AddAppointmentModal />}
      {settingsAppointmentClick && <SettingsAppointmentModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <AppointmentsTable
            data={appointments?.filter(
              (el) =>
                el.konu
                  .toLowerCase()
                  .includes(appointmentInputValue.toLowerCase()) ||
                el.randevubirim
                  .toLowerCase()
                  .includes(appointmentInputValue.toLowerCase()) ||
                el.randevualan
                  .toLowerCase()
                  .includes(appointmentInputValue.toLowerCase()) ||
                el.onay
                  .toLowerCase()
                  .includes(appointmentInputValue.toLowerCase()) ||
                el.durum
                  .toLowerCase()
                  .includes(appointmentInputValue.toLowerCase())
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Appointments;
