import style from "./style.module.css";
import operation from "../../../../assets/operation.svg";
import EditPaymentModal from "../../EditPaymentModal";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../../context/store";
import { getPayment, getPaymentDetail } from "../../../../api/general";
import arrowdown from "../../../../assets/arrowdown.svg";
import { Link } from "react-router-dom";

const AllPayments = ({
  title1 = "-",
  title2 = "Etkinlikler",
  title3 = "Sınıfı",
  title4 = "Etkinlik Tarihi",
  title5 = "Tutar",
  title6 = "Ödeme Sayısı",
  title7 = "Toplam Tutar",
  title8 = "İşlem",
  data,
}) => {
  const {
    setEditPaymentClick,
    editPaymentClick,
    paymentId,
    setPaymentId,
    setPayment,
  } = useContext(StoreContext);

  useEffect(() => {
    console.log("paymentId", paymentId);
    console.log(data,"dataall")
  }, [paymentId]);

  const getPaymentMethod = async (paymentId) => {
    const response = await getPayment(paymentId);
    if (response.error) {
      console.log("Ödemenin bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setPayment(response.data[0]);
      console.log("Ödemenin bilgileri geldi");
    }
  };

  return (
    <div className={style.container}>
      {editPaymentClick && <EditPaymentModal paymentId={paymentId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
        <div className={style.title7}>{title7}</div>
        <div className={style.title8}>{title8}</div>
      </div>
      {data &&
        data.map((payment, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{payment.id}</div>
            <div className={style.data2}>{payment.etkinlikname}</div>
            <div className={style.data3}>{payment.siniflar}</div>
            <div className={style.data4}>{payment.tarih}</div>
            <div className={style.data5}>{payment.tutar}</div>
            <div className={style.data6}>
              {payment.odemesayisi}
              <Link to={`/odemeler/${payment.id}`}>
                <img src={arrowdown}></img>
              </Link>
            </div>
            <div className={style.data7}>{payment.toplamtutar}</div>
            <div className={style.data8}>
              <button
                onClick={() => {
                  setPaymentId(payment.id);
                  Promise.resolve(getPaymentMethod(payment.id)).then(() => {
                    setEditPaymentClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AllPayments;
