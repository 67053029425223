import style from "./style.module.css";
import React, { useRef } from 'react';
import cross from "../../../assets/modalIcons/cross.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState, useEffect } from "react";
import StoreContext from "../../../context/store";
import {
  addAnnouncement,
  getStudents,
  getTeachers,
  getParents,
  ClassStudents,
} from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import dateicon from "../../../assets/modalIcons/date.svg";
import clip from "../../../assets/modalIcons/clip.svg";
import send from "../../../assets/modalIcons/send.svg";
import tick from "../../../assets/modalIcons/tick.svg";
import search from "../../../assets/search.svg";
import cn from "classnames";
import { getClassrooms } from "../../../api/general";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import moment from "moment/moment";
import { parseISO } from "date-fns";
import { fi } from "date-fns/locale";
import { getTokenFromMessaging, requestPermission } from "../../NotificationService";
import { useToasts } from 'react-toast-notifications';
import NotificationService from "../../NotificationService";

const AddAnnouncementModal = () => {

  const {
    setAddAnnouncementClick,
    setAnnouncementClassroomInputValue,
    announcementClassroomInputValue,
    announcementTeacherInputValue,
    setAnnouncementTeacherInputValue,
    announcementStudentInputValue,
    setAnnouncementStudentInputValue,
    announcementParentInputValue,
    setAnnouncementParentInputValue,
    userGuid,
  } = useContext(StoreContext);
const {addToast} = useToasts();

  const [resim, setResim] = useState();
  const [classrooms, setClassrooms] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [parents, setParents] = useState([]);
  const [classStudents, setClassStudents] = useState([])
  const [type] = useState("DUYURU");
  const [date, setDate] = useState(new Date());
  const [message, setAnnouncement] = useState("");
  
  const [selectedClassrooms, setSelectedClassrooms] = useState({SinifIds:[]});
  const [selectedStudents, setSelectedStudents] = useState("");
  const [selectedParents, setSelectedParents] = useState(""); 
  const [selectedTeachers,setSelectedTeachers] = useState("")
  const [selectedValues, setSelectedValues] = useState({UserIds:[]});
  const [isStudentOptionSelected, setIsStudentOptionSelected] = useState(false);
  const [isClassOptionSelected, setIsClassOptionSelected] = useState(false);
   const [isTeacherOptionSelected, setIsTeacherOptionSelected] = useState(false);
   const [isParentOptionSelected, setIsParentOptionSelected] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  
  useEffect(() => {
    getClassroomsMethod();
    getTeachersMethod();
    getParentsMethod(); 
  }, [selectedValues]);

  //const isOptionSelected = (option) => selectedOptions.includes(option);
 
  //Toplu seçim 
  const handleCheckboxAll = (option) => {
    let allUserIdS = [...selectedValues.UserIds];

    switch (option) {
      case "Öğretmenler":
        // Öğretmenler seçeneği işaretliyse, öğretmen verilerinden userId'leri al
        const TeachertArray = Array.isArray(teachers) ? teachers : [];
        const teacherIds = TeachertArray.map((teacher) => teacher.userId);

        if (isTeacherOptionSelected) {
          allUserIdS = allUserIdS.filter((id) => !teacherIds.includes(id));
        } else {
          allUserIdS = [...allUserIdS, ...teacherIds];
        }
        break;

      case "Veliler":
        const ParentArray = Array.isArray(parents) ? parents : [];
        const parentIds = ParentArray.map((parent) => parent.userId);
        allUserIdS = [...allUserIdS, ...parentIds];
        break;

      default:
        break;
    }

    // Seçili seçeneklerin userId'lerini set et
    setSelectedValues((prevValues) => ({
      ...prevValues,
      UserIds: allUserIdS,
    }));

    switch (option) {
      case "Öğretmenler":
        setIsTeacherOptionSelected(!isTeacherOptionSelected);
        break;

      case "Veliler":
        setIsParentOptionSelected(!isParentOptionSelected);
        break;

      default:
        break;
    }
  };

  


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setResim(file);
     // FormData içeriğini görmek için
  };

  //Selected Classrooms CheckBoxes

  const handleSelectedClassrooms = (classroom) => {
     setSelectedClassrooms((prevValues) => {
      const newClassroomArray = prevValues.SinifIds.includes(classroom)
      ? prevValues.SinifIds.filter((item) => item !== classroom)
      : [...prevValues.SinifIds, classroom];
      
      return {...prevValues, SinifIds: newClassroomArray};
     })
  };

  const handleCheckboxClassroomAll = () => {
    let allClassroomIds = [...selectedClassrooms.SinifIds]; // Mevcut SinifId'leri kopyala
  
    // Tüm sınıf Id'lerini al
    const allClassroomIdsArray = classrooms.map(classroom => classroom.id);
  
    if (isClassOptionSelected) {
      // Eğer sınıflar zaten işaretliyse, bu SinifId'leri kaldır
      allClassroomIds = allClassroomIds.filter(id => !allClassroomIdsArray.includes(id));
    } else {
      allClassroomIds = [...allClassroomIds, ...allClassroomIdsArray];
    }
  
    // Seçili sınıfların SinifId'lerini set et
    setSelectedClassrooms((prevValues) => ({
      ...prevValues,
      SinifIds: allClassroomIds,
    }));
  
    // İlgili seçeneğin işaretli durumunu güncelle
    setIsClassOptionSelected(!isClassOptionSelected);
  };
console.log("tekli",selectedValues)


const handleSelectedValues = (value) => {
  setSelectedValues((prevValues) => {
    let newUserIdArray;
    // Önceki değerleri kopyala
    const currentIds = [...prevValues.UserIds];
    // Seçilen değerin indeksini bul
    const index = currentIds.indexOf(value);

    if (index !== -1) {
      // Eğer değer dizide varsa, diziden kaldır
      currentIds.splice(index, 1);
    } else {
      // Eğer değer dizide yoksa, diziye ekle
      currentIds.push(value);
    }

    // Yeni diziyi güncelle
    newUserIdArray = [...currentIds];

    return { ...prevValues, UserIds: newUserIdArray };
  });
};
  const isSelectedTeachers = (userId) => {
    // "Öğretmenler" seçeneği seçiliyse veya seçili sınıflar arasında bulunuyorsa true döndür
    if (!Array.isArray(selectedValues) || !selectedValues.includes) {
      return false;
    }
  
    // Eğer selectedValues dizisi içinde userId varsa, true döndür
    return selectedValues.includes(userId);
  };


  const handleSelectedStudents = (student) => {
    if (selectedStudents.includes(student)) {
      setSelectedStudents(selectedStudents.filter((item) => item !== student));
    } else {
      setSelectedStudents([...selectedStudents, student]);
    }
  };


  //TABLES CLASSROOMS

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data.result);
     
    }
  };



  //TABLES TEACHERS

  const getTeachersMethod = async () => {
    const response = await getTeachers();
    if (response.error) {
     // console.log("Test öğretmenler gelmedi");
    } else {
     // console.log("Test öğretmenler geldi");
      setTeachers(response.data.result);
     
    }
  };
  //TABLES PARENTS

  const getParentsMethod = async () => {
    const response = await getParents();
    if (response.error) {

    } else {

      setParents(response.data);
    }
  };


const searchStudent = students.filter(student =>
    student.nameSurname && student.nameSurname.toLowerCase().includes(announcementClassroomInputValue.toLowerCase())
  );

const searchTeacher = teachers.filter(teacher => 
  teacher.nameSurname && teacher.nameSurname.toLowerCase().includes(announcementTeacherInputValue.toLowerCase()));

const searchClassroom = classrooms.filter(classroom => 
  classroom.sinifName && classroom.sinifName.toLowerCase().includes(announcementClassroomInputValue.toLowerCase()));

const searchParent = parents.filter(parent => 
  parent.value && parent.value.toLowerCase().includes(announcementParentInputValue.toLowerCase()));
 

 

  const addAnnouncementMethod = async () => {

    if (message === "") {
      warningMessage("Lütfen duyuru giriniz");

      return;
    }
    if (date === "") {
      warningMessage("Lütfen tarih seçiniz");

      return;
    }
 

    const response = await addAnnouncement(
      date,
      message,
      resim,
      selectedValues,
      selectedClassrooms,
      userGuid,
    );
    if (response.error) {
      console.log("Duyuru ekleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Duyuru ekleme başarısız");
    } else {
      console.log("Duyuru ekleme başarılı");
      successMessage("Duyuru ekleme başarılı");
      setAddAnnouncementClick(false);

   
    }
  };

  const handleChangeDates = (dt) =>{
     
       let normalDate=dt===null?"":formatDateFn(dt)
       setDate(normalDate);
  }

  const formatDateFn = (d) => {
   if(d){
    return (
      ("0" + d.getDate()).slice(-2) +
       "/" +
        ("0" + Number(d.getMonth())+1).slice(-2) +
        "/" +
         d.getFullYear()
    )
   }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddAnnouncementClick(false);
                  setAnnouncementClassroomInputValue("");
                  setAnnouncementTeacherInputValue("");
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.upperinputs}>
            <textarea
              className={style.messageArea}
              placeholder="Duyurunuz"
              value={message}
              onChange={(e) => setAnnouncement(e.target.value)}
            ></textarea>

            <div className={style.buttonArea}>
              <div className={style.tab2}>
                <input
                  type="file"
                  className={style.photoinput}
                  onChange={handleFileChange}
                ></input>
                <div className={style.tabicon2}>
                  <img alt="" src={clip}></img>
                </div>

                <div className={style.tabtext2}>Medya Ekle</div>
              </div>
              <div className={style.tab2}>
                <div className={style.tabicon2}>
                  <img alt="" src={dateicon}></img>
                </div>
                <div className={style.tabtext2}>
                  <DatePicker
                    className="bg-transparent border-0 outline-none pt-1 w-full "
                    timeZone="Europe/Istanbul"
                    selected={startDate}
                    onChange={(date) => handleChangeDates(date)}
                    dateFormat="dd/MM/yyyy"
                    locale={tr}
                    showPopperArrow={false}
                  />
                </div>
              </div>
              <div className={style.tab1}>
                <button
                  className={style.tabicon1}
                  onClick={() => {
                    addAnnouncementMethod();
                  }}
                >
                  <img alt="" src={send}></img>
                </button>
                <div className={style.tabtext1}>GÖNDER</div>
              </div>
            </div>
          </div>

          <div className={style.lowerinputs}>
            <div className={style.tablearea1}>
              <div className={style.tabletitle}>
                Sınıf: {selectedClassrooms.length}
              </div>
              <div className={style.search}>
                <button className={style.searchicon}>
                  <img alt="" src={search}></img>
                </button>
                <input
                  className={style.searchinput}
                  placeholder="Sınıf Ara"
                  onChange={(e) => {
                    setAnnouncementClassroomInputValue(e.target.value);

                  }}
                  value={announcementClassroomInputValue}
                ></input>
              </div>

              <div className={style.table}>
                <div className={style.title1}>
                  <button
                    className={`${style.checkbox} ${
                      isClassOptionSelected ? style.selected : ""
                    }`}
                    onClick={() => handleCheckboxClassroomAll('Sınıflar')}
                    
                  >
                      {isClassOptionSelected && (
                      <img src={tick} alt="" />
                    )}
                  </button>
                  <div className="pl-2 h-full  flex items-center">
                    {" "}
                    Sınıflar
                  </div>
                </div>
                <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
                  {selectedClassrooms &&
                    classrooms &&
                    classrooms
                      ?.filter((el) =>
                        el.sinifName
                          .toLowerCase()
                          .includes(announcementClassroomInputValue.toLowerCase())
                      )
                      .map((classroom, index) => (
                        <div
                          className={cn(
                            style.datas,
                            index % 2 === 0 && style.datas2
                          )}
                          key={index}
                        >
                              <input                      
                              type="checkbox"
                              className="w-5 h-5 bg-[#afddff] border-0.5px border-[#3733bf] rounded-sm"
                              onClick={() =>
                              handleSelectedClassrooms(classroom.id)}
                             />
                       
                          <div className={style.data1}>
                            {classroom.sinifName}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            <div className={style.tablearea2}>
              <div className={style.tabletitle}>
                Seçilen Öğretmenler: {selectedTeachers.length}
              </div>
              <div className={style.search}>
                <button className={style.searchicon}>
                  <img alt="" src={search}></img>
                </button>
                <input
                  className={style.searchinput}
                  placeholder="Öğretmen Ara"
                  onChange={(e) => {
                    setAnnouncementTeacherInputValue(e.target.value);
                  }}
                  value={announcementTeacherInputValue}
                ></input>
              </div>

              <div className={style.table}>
                <div className={style.title1}>
                  <button
                    className={`${style.checkbox} ${
                      isTeacherOptionSelected.length > 0 ? style.selected : ""
                    }`}
                    onClick={() => handleCheckboxAll('Öğretmenler')}
                  >
                    {isTeacherOptionSelected && (
                      <img src={tick} alt="" />
                    )}
                  </button>
                  <div className="pl-2 h-full  flex items-center">
                    {" "}
                    Öğretmenler
                  </div>
                </div>

                <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
                  {selectedValues &&
                    teachers &&
                    teachers
                      ?.filter(
                        (el) =>
                          el.nameSurname
                            .toLowerCase()
                            .includes(announcementTeacherInputValue.toLowerCase())
                        //selectedClassrooms.includes(el.sinifid)
                      )
                      .map((value, index) => (
                        <div
                          className={cn(
                            style.datas,
                            index % 2 === 0 && style.datas2
                          )}
                          key={index}
                        >
                          <input     
                            type="checkbox"
                            className="w-5 h-5 bg-[#afddff] border-0.5px border-[#3733bf] rounded-sm"
                            onClick={() => handleSelectedValues(value.userId)}
                            />                        
                          <div className={style.data1}>{value.nameSurname}</div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            <div className={style.tablearea2}>
              <div className={style.tabletitle}>
                Seçilen Veliler: {selectedParents.length}
              </div>
              <div className={style.search}>
                <button className={style.searchicon}>
                  <img alt="" src={search}></img>
                </button>
                <input
                  className={style.searchinput}
                  placeholder="Veli Ara"
                  onChange={(e) => {
                    setAnnouncementParentInputValue(e.target.value);
                  }}
                  value={announcementParentInputValue}
                ></input>
              </div>

              <div className={style.table}>
                <div className={style.title1}>
                  <button
                    className={`${style.checkbox} ${
                      isParentOptionSelected ? style.selected : ""
                    }`}
                    onClick={() => handleCheckboxAll('Veliler')}
                  >
                    {isParentOptionSelected && <img src={tick} alt="" />}
                  </button>
                  <div className="pl-2 h-full  flex items-center"> Veliler</div>
                </div>

                <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
                  {selectedValues &&
                    parents &&
                    parents
                      ?.filter(
                        (el) =>
                          el.value
                            .toLowerCase()
                            .includes(announcementParentInputValue.toLowerCase())
                        //selectedClassrooms.includes(el.sinifid)
                      )
                      .map((value, index) => (
                        <div
                          className={cn(
                            style.datas,
                            index % 2 === 0 && style.datas2
                          )}
                          key={index}
                        >
                          <input
                            type="checkbox"
                            className="w-5 h-5 bg-[#afddff] border-0.5px border-[#3733bf] rounded-sm"
                            onClick={() => handleSelectedValues(value.userId)}
                          />
                          <div className={style.data1}>{value.value}</div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAnnouncementModal;
