import { Calendar, momentLocalizer,components } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import StoreContext from "../../context/store";
import { useContext, useState, useEffect } from "react";
import moment from 'moment'; 
import 'moment/locale/tr'; // Türkçe dil dosyasını içe aktarın
import { getTodayDate} from "../../api/general";

moment.locale('tr'); // Türkçe dilini kullan

const MyCalendar = () => {
  const {teacherId,setTeacherId } = useContext(StoreContext);
  const [dateInfo, setDateInfo] = useState([]);
  const [openDates, setOpenDates] = useState([]);

  useEffect(() => {
    // teacherId varsa çağrı yap
    if (teacherId !== "") {
      getTodayDateMethod(teacherId);
    }
  }, [teacherId]);

  const getTodayDateMethod = async (teacherId) => {
    const response = await getTodayDate(teacherId);
    if (!response.error) {
      setDateInfo(response.data);
      const formattedClosedDates = response.data.result.map(info => ({
       
        start: moment(info.begginingAppointmentTimes).toDate(),
        end: moment(info.finalAppointmentTimes).toDate(),
        startTime: moment(info.begginingAppointmentTimes).format('HH:mm'), // Başlangıç saati
        endTime: moment(info.finalAppointmentTimes).format('HH:mm'),
        title: 'Müsait',
        
      }));
     
      setOpenDates(formattedClosedDates);
    }
  };
    
  const localizer = momentLocalizer(moment);

  const handleEventClick = (event) => {
    if (event.title === "Müsait") {
      alert(`Başlangıç Saati: ${event.startTime}, Bitiş Saati: ${event.endTime}`);
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {

  };

  const EventWrapper = (props) => {
    const { event } = props;
    const eventStyle = {
      backgroundColor: event.color, // Etkinlik arka plan rengi
      borderRadius: '0px',
      opacity: 0.8,
      color: 'red',
      border: '0px',
      display: 'block',
      fontSize: '15px',
      padding: '2px',
      margin: '16px',
      cursor: 'pointer',
      fontWeight: 'bold',
    };

  

    return (
      <components.eventWrapper {...props}>
        <div style={eventStyle}>
          {event.startTime} - {event.endTime}
        </div>
      </components.eventWrapper>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <div style={{ width: '150%', maxWidth: '1220px', margin: 'auto' }}> 
        <Calendar
          style={{ height: '69vh', width: '100%' }} 
          toolbar={true}
          localizer={localizer}
          events={openDates}
          startAccessor="start" // Başlangıç zamanı erişimcisi
          endAccessor="end" 
          eventPropGetter={eventStyleGetter}
          components={{ eventWrapper: EventWrapper }}
          //showAllEvents={true}
          messages={{
            agenda: 'Ajanda',
            month: 'Ay',
            day: 'Gün',
            today: 'Bugün',
            Back: 'Geri',
            previous: 'Geri',
            next: 'İleri',
            week: 'Hafta',
          }}
          onSelectEvent={handleEventClick}
        />
      </div>
    </div>
  );
};

export default MyCalendar;
