import style from "./style.module.css"
const Table = () => {
    return (
        <div className={style.container}>
            <div className={style.header}>
                <div className={style.header_dayName}></div>
                <div className={style.header_cell}>1</div>
                <div className={style.header_cell}>2</div>
                <div className={style.header_cell}>3</div>
                <div className={style.header_cell}>4</div>
                <div className={style.header_cell}>5</div>
                <div className={style.header_cell}>6</div>
                <div className={style.header_cell}>7</div>
                <div className={style.header_cell}>8</div>
                <div className={style.header_cell}>9</div>
                <div className={style.header_cell}>10</div>
                <div className={style.header_cell}>11</div>
                <div className={style.header_cell}>12</div>
                <div className={style.header_cell}>13</div>
                <div className={style.header_cell}>14</div>
                <div className={style.header_cell}>15</div>
                <div className={style.header_cell}>16</div>
                <div className={style.header_cell}>17</div>
                <div className={style.header_cell}>18</div>
                <div className={style.header_cell}>19</div>
                <div className={style.header_cell}>20</div>
            </div>
            <div className={style.body}>
                <div className={style.body_dayName}>Pazartesi</div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
            </div>
            <div className={style.body}>
                <div className={style.body_dayName}>Salı</div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
            </div>
            <div className={style.body}>
                <div className={style.body_dayName}>Çarşamba</div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
            </div>
            <div className={style.body}>
                <div className={style.body_dayName}>Perşembe</div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
            </div>
            <div className={style.body}>
                <div className={style.body_dayName}>Cuma</div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
            </div>
            <div className={style.body}>
                <div className={style.body_dayName}>Cumartesi</div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
            </div>
            <div className={style.body}>
                <div className={style.body_dayName}>Pazar</div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
                <div className={style.body_cell}></div>
            </div>
        </div>
    )
}

export default Table;