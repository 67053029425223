import React, { useState } from 'react'
import style from "./style.module.css"

import dropdown from "../../../../assets/arrowdown.svg"
const PrintSection = () => {

    const [selectedOption1, setSelectedOption1] = useState("")
    const [selectedOption2, setSelectedOption2] = useState("")
    const [selectedOption3, setSelectedOption3] = useState("")
    const [dropdownarea, setDropdownArea] = useState("")
    const [isDropdownAreaOpen, setisDropdownAreaOpen] = useState(false)
    const filteroptions1 = ["Öğretmen El Programı", "Öğretmen El Programı Çiftli", "Sınıf El Programı", "Öğretmen ÇarşafProgramı", "Sınıf Çarşaf Programı","Nöbet Programı","Ekders Çizelgesi","Okulum Toplu Şifre"]
    const filteroptions2 = ["Mehmet SAĞLAM", "Fazlı BEYHAN", "Cihangir CENGİZ", "Asuman BAYRAK", "Figen MUTLU","Dursun MERCAN","Fatih TEKİN","Tümünü Seç"]
    const filteroptions3 = ["Üstte", "Ders İçinde"]
    const handleDropdownArea = (event) => {
        setDropdownArea(event.target.name);
        setisDropdownAreaOpen(!isDropdownAreaOpen)
    }

    const handleSelectedOption = (e) => {
        {dropdownarea === "button1" && setSelectedOption1(e.target.value)}
        {dropdownarea === "button2" && setSelectedOption2(e.target.value)}
        {dropdownarea === "button3" && setSelectedOption3(e.target.value)}
        setisDropdownAreaOpen(false)
    }
    return (
        <div className={style.container}>
            <div className={style.container_item}>  
                <div className={style.title}>Çıktı Tipi</div>
                <div className={style.dropdown}>
                    <div className={style.selectedone}>{selectedOption1}</div>
                    <button className={style.dropdown_btn} onClick={handleDropdownArea}>
                        <img src={dropdown} name="button1" alt=""></img>
                    </button>
                    {dropdownarea === "button1" && isDropdownAreaOpen && <div className={style.optionsarea}>
                        <div>
                            {filteroptions1.map((opt, key) =>
                                <button key={key} value={opt} onClick={handleSelectedOption} className={style.option}>
                                    {opt}
                                </button>
                            )}
                        </div>
                    </div>}
                </div>
                <div className={style.pdf_btn}>PDF</div>
            </div>
            <div className={style.container_item}>
                <div className={style.title}>Öğretmen Seçiniz</div>
                <div className={style.dropdown}>
                    <div className={style.selectedone}>{selectedOption2}</div>
                    <button className={style.dropdown_btn} onClick={handleDropdownArea}>
                        <img src={dropdown} name="button2" alt=""></img>
                    </button>
                    {dropdownarea=== "button2" && isDropdownAreaOpen && <div className={style.optionsarea}>
                        <div>
                            {filteroptions2.map((opt, key) =>
                                <button key={key} value={opt} onClick={handleSelectedOption} className={style.option}>
                                    {opt}
                                </button>
                            )}
                        </div>
                    </div>}
                </div>
                <div className={style.excel_btn}>EXCEL</div>
            </div>
            <div className={style.container_item}>
                <div className={style.title}>Giriş Çıkış Saatleri</div>
                <div className={style.dropdown}>
                    <div className={style.selectedone}>{selectedOption3}</div>
                    <button className={style.dropdown_btn} onClick={handleDropdownArea}>
                        <img src={dropdown} name="button3" alt=""></img>
                    </button>
                    {dropdownarea==="button3" && isDropdownAreaOpen && <div className={style.optionsarea}>
                        <div>
                            {filteroptions3.map((opt, key) =>
                                <button key={key} value={opt} onClick={handleSelectedOption} className={style.option}>
                                    {opt}
                                </button>
                            )}
                        </div>
                    </div>}
                </div>
            </div>
            <div className={style.container_item}>
                <div className={style.title}>Belge Tarihi</div>
                <div className={style.input}>
                    <input type='date' />
                </div>
            </div>
            <div className={style.container_item}>
                <div className={style.title}>Geçerlilik Tarihi</div>
                <div className={style.input}>
                    <input type='date' />
                </div>
            </div>
            <div className={style.container_item}>
                <div className={style.title}>Belge Sayı No:</div>
                <div className={style.input}>
                    <input type='date' />
                </div>
            </div>
            <div className={style.container_item}>
                <div className={style.title}>Üst Yazı:</div>
                <div className={style.checkbox_wrapper}>
                    <div className={style.checkbox_container}>   
                        <input className={style.checkbox} type='checkbox' />
                        <span>ile göre</span>
                    </div>
                    <div className={style.checkbox_container}>
                        <input className={style.checkbox} type='checkbox' />
                        <span>ilçeye göre</span>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PrintSection;