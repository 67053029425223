import { useEffect, useState } from "react";
import { getMessages } from "../api/general";
import style from "../styles/Messages.module.css";
import MessagesTable from "../components/Message/MessagesTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Message/MessageBar";
import AddMessageModal from "../components/Message/AddMessageModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Messages = () => {
  const { addMessageClick, messageInputValue } = useContext(StoreContext);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
 
  }, []);



  return (
    <div className={style.page}>
      {addMessageClick && <AddMessageModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <MessagesTable
            data={messages?.filter(
              (el) =>
                el.gonderen
                  .toLowerCase()
                  .includes(messageInputValue.toLowerCase()) ||
                el.gonderilen
                  .toLowerCase()
                  .includes(messageInputValue.toLowerCase()) ||
                el.unvan.toLowerCase().includes(messageInputValue.toLowerCase())
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Messages;
