import style from "./style.module.css";

import tick from "../../../../assets/modalIcons/tick.svg";
import { useContext } from "react";
import StoreContext from "../../../../context/store";
import warning from "../../../../assets/modalIcons/warning.svg"
import cross from "../../../../assets/modalIcons/cross.svg"
const PlaceTeacherModal = () => {

    const { setShowTeacherPlaceModal } =
        useContext(StoreContext);
    return (
        <div className={style.modal}>
            <div className={style.modalbox}>
                <div className={style.uppertabs}>
                    <div className={style.tableftside}>
                        <div className={style.tab}>
                            <button className={style.tabicon_left}>
                                <img alt="" src={warning}></img>
                            </button>
                            <div className={style.tabtext_left}>Bilgilendirme </div>
                        </div>
                    </div>
                    <div className={style.tabrightside}>
                    </div>
                </div>
                <div className={style.contents}>
                    
                </div>
                <div className={style.lowertabs}>
                    <div className={style.tableftside}></div>
                    <div className={style.tabrightside}>
                        <div className={style.tab}>
                            <button
                                className={style.tabicon_right}
                                onClick={() => {
                                    setShowTeacherPlaceModal(false);
                                }}
                            >
                                <img alt="" src={cross}></img>
                            </button>
                            <div className={style.tabtext_right}>İptal</div>
                        </div>
                        <div className={style.tab}>
                            <button
                                className={style.tabicon_right}
                                onClick={() => {
                                    setShowTeacherPlaceModal(false);
                                }}
                            >
                                <img alt="" src={tick}></img>
                            </button>
                            <div className={style.tabtext_right}>Tamam</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlaceTeacherModal;