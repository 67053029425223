import style from "./style.module.css"

const TeachersTable = (
    {
        title1 = "Nöbet Yeri",
        title2 = "Pzt.",
        title3 = "Sal.",
        title4 = "Çar.",
        title5 = "Per.",
        title6 = "Cum.",
        title7 = "C.tesi",
        title8 = "Paz.",
        title9 = "",
        data,
    }
) => {
    console.log("data", data);
    return (
        <div className={style.container}>
            <div className={style.titles}>
                <div className={style.title1}>{title1}</div>
                <div className={style.title2}>{title2}</div>
                <div className={style.title3}>{title3} </div>
                <div className={style.title4}>{title4} </div>
                <div className={style.title5}>{title5}</div>
                <div className={style.title5}>{title6}</div>
                <div className={style.title5}>{title7}</div>
                <div className={style.title5}>{title8}</div>
                <div className={style.title5}>{title9}</div>
            </div>
            <div className="flex flex-col max-h-[312px] overflow-y-auto gap-2 overflow-x-hidden">
                {data &&
                    data.map((teacher, index) => (
                        <button className={`${style.datas} ${index % 2 == 0 ? style.even : style.odd}`} key={index}>
                            <div className={style.data1}>{teacher.id}</div>
                            <div className={style.data2}>{teacher.nameSurname}</div>
                            <div className={style.data3}>{teacher.brans}</div>
                            <div className={style.data4}>{teacher.brans}</div>
                            <div className={style.data5}>{teacher.phone}</div>
                        </button>
                    ))}
            </div>
        </div>
    )
}

export default TeachersTable;