import style from "./style.module.css";
import video from "../../../assets/video.svg";
import search from "../../../assets/search.svg";
import reload from "../../../assets/reload.svg";
import plus from "../../../assets/plus.svg";
import pdf from "../../../assets/pdf.svg";
import arrowdown from "../../../assets/arrowdownwhite.svg";
import { Link } from "react-router-dom";
import { getCourseAssignmentPdf } from "../../../api/general";

const Bar = () => {
  const getCourseAssignmentPdfMethod = async () => {
    const response = await getCourseAssignmentPdf();
    if (response.error) {
      console.log("Ders atamaları pdfi gelmedi");
      console.log(response.error, "response.erro");
    } else {
      console.log("Ders Atamaları pdfi geldi");
    }
  };

  return (
    <div className={style.container}>
      <div className={style.courseAssignments}>
        <div className={style.courseAssignmentstext}>Ders Atamaları</div>
        <Link>
          <img
            alt=""
            className={style.courseAssignmentsvideo}
            src={video}
          ></img>
        </Link>
      </div>

      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        <img alt="" className={style.reload} src={reload}></img>
      </button>
      <div className={style.tabs}>
        <div className={style.tab}>
          <button className={style.tabicon} onClick={() => {}}>
            <img alt="" src={arrowdown}></img>
          </button>
          <div className={style.tabtext}>İşlemler</div>
        </div>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              //   getCourseAssignmentPdfMethod();
            }}
          >
            <img alt="" src={pdf}></img>
          </button>
          <div className={style.tabtext}>PDF Yazdır</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
