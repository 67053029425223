import style from "../styles/CourseAssignments.module.css";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/CourseAssignment/CourseAssignmentBar";
import SecondBar from "../components/CourseAssignment/CourseAssignmentSecondBar";
import { checkAssigmentSections } from "../helpers/checkassignmentsections";
import StoreContext from "../context/store";
import { useContext } from "react";

const CourseAssignments = () => {
  const { selectedAssignmentSection } = useContext(StoreContext);

  return (
    <div className={style.page}>
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <SecondBar />
        <div className={style.table}>
          {checkAssigmentSections(selectedAssignmentSection)}
        </div>
      </div>
    </div>
  );
};

export default CourseAssignments;
