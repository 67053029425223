import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState, useRef, useEffect } from "react";
import StoreContext from "../../../context/store";
import { addSocialClub } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import { getTeachers } from "../../../api/general";
import dropdown from "../../../assets/arrowdown.svg";
import useClickOutside from "../../../hooks/useClickOutside";

const AddSocialClubModal = () => {
  const { setAddSocialClubClick } = useContext(StoreContext);

  

  const [teacher, setTeacher] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [socialClubName, setSocialClubName] = useState("");

  // FOR DROPDOWN

  const [optionArea, setOptionArea] = useState(false);
  const buttonRef = useRef(null);

  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    getTeachersMethod();
  }, []); //

  // FOR DROPDOWN

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const handleTeacherSelect = (event) => {
    setTeacher(event.target.value.split(",")[0]);
    setTeacherId(event.target.value.split(",")[1]);
    setOptionArea(false);


  };

  const getTeachersMethod = async () => {
    const response = await getTeachers();
    if (response.error) {
      console.log("Test ogretmenler gelmedi");
    } else {
      console.log("Test ogretmenler geldi");
      setTeachers(response.data);
    }
  }; //

  const addSocialClubMethod = async () => {
    if (socialClubName === "" || teacher === "") {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await addSocialClub(socialClubName, teacherId);
    if (response.error) {
      console.log("Sosyal Kulüp ekleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Sosyal Kulüp ekleme başarısız");
    } else {
      console.log("Sosyal Kulüp ekleme başarılı");
      successMessage("Sosyal Kulüp ekleme başarılı");
      setAddSocialClubClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddSocialClubClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Sosyal Kulüp Adı</div>
            <input
              className={style.inputtext}
              value={socialClubName}
              onChange={(e) => setSocialClubName(e.target.value)}
            ></input>
          </div>

          <div className={style.input}>
            <div className={style.inputtitle}>Kulüp Öğretmeni</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{teacher}</div>
              <button onClick={handleToggle}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea && (
                <div className={style.optionarea} ref={buttonRef}>
                  <div>
                    {teachers &&
                      teachers.map((teacher, index) => (
                        <button
                          onClick={handleTeacherSelect}
                          
                          value={[teacher.nameSurname, teacher.id]}
                          key={index}
                          className={style.option}
                        >
                          {teacher.nameSurname}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  addSocialClubMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSocialClubModal;
