import style from "./style.module.css";
import { Link } from "react-router-dom";
import youtubeLogo from "../../../assets/socialMediaIcons/youtube.svg";
import facebookLogo from "../../../assets/socialMediaIcons/facebook.svg";
import twitterLogo from "../../../assets/socialMediaIcons/twitter.svg";
import instagramLogo from "../../../assets/socialMediaIcons/instagram.svg";
import linkedinLogo from "../../../assets/socialMediaIcons/linkedin.svg";
import notificationLogo from "../../../assets/notifications.svg";
import logo from "../../../assets/logo.svg";
import okulumLogo from "../../../assets/okulumlogo.svg";
import logoutIcon from "../../../assets/whitelogouticon.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { getNotification, getNotifications } from "../../../api/general";
import Modal from "react-modal";
import cross from "../../../assets/modalIcons/cross.svg";

const Header = () => {
  const [notificationNumber, setNotificationNumber] = useState("");
  const [notifications, setNotifications] = useState([]);

  // useEffect(() => {
  //   getNotificationMethod();
  // }, []);

  //MODAL
  const [showModal, setShowModal] = useState(false);

  function handleOpenModal() {
    setShowModal(true);
  }
  function handleCloseModal() {
    setShowModal(false);
  }

  const getNotificationMethod = async () => {
    const response = await getNotification();
    if (response.error) {
      console.log("Test bildirimler gelmedi");
    } else {
      console.log("Test bildirimler geldi");
      setNotificationNumber(response.data[0].notificationcount);
      console.log(response.data, "response.data.teachers");
    }
  };

  const getNotificationsMethod = async () => {
    const response = await getNotifications();
    if (response.error) {
      console.log("Test bildirimler gelmedi");
    } else {
      console.log("Test bildirimler geldi");
      getNotificationMethod();
      setNotifications(response.data);
      console.log(response.data, "response.data.teachers");
      await handleOpenModal();
    }
  };

  return (
    <div className={style.container}>
      <div className={style.leftside}>
        <img src={logo} className={style.logo} alt="logo"></img>
        <Link to="/">
          <img
            src={okulumLogo}
            className={style.okulumlogo}
            alt="okulumLogo"
          ></img>
        </Link>
      </div>
      <div className={style.rightside}>
        {useWindowSize().width < 460 && (
          <button
            className="relative flex items-center justify-center"
            onClick={getNotificationsMethod}
          >
            <img
              src={notificationLogo}
              className={style.notification}
              alt="notificationLogo"
            ></img>
            <div className=" absolute rounded-full w-6 h-6  bg-[#EB4444] -bottom-2 -right-2 text-white font-[400] text-[13px] font-[Poppins] text-center flex items-center justify-center">
              {notificationNumber}
            </div>
          </button>
        )}
        <div className={style.logoutarea}>
          <Link
            to="/giris"
            onClick={() => {
              localStorage.clear();
              //setIsAuth(false);
            }}
          >
            <img src={logoutIcon} className={style.home} alt="logoutIcon"></img>{" "}
          </Link>
          <Link
            className={style.logout}
            to="/giris"
            onClick={() => {
              localStorage.clear();
              //setIsAuth(false);
            }}
          >
            ÇIKIŞ
          </Link>{" "}
        </div>
        <div className={style.social}>
          <Link to="https://www.youtube.com/" target="_blank">
            <img
              src={youtubeLogo}
              className={style.sociallogo}
              alt="youtubeLogo"
            ></img>
          </Link>
          <Link to="https://twitter.com/" target="_blank">
            <img
              src={twitterLogo}
              className={style.sociallogo}
              alt="twitterLogo"
            ></img>
          </Link>
          <Link to="https://www.facebook.com/" target="_blank">
            <img
              src={facebookLogo}
              className={style.sociallogo}
              alt="facebookLogo"
            ></img>
          </Link>
          <Link to="https://www.instagram.com/" target="_blank">
            <img
              src={instagramLogo}
              className={style.sociallogo}
              alt="instagramLogo"
            ></img>
          </Link>
          <Link to="https://linkedin.com/" target="_blank">
            <img
              src={linkedinLogo}
              className={style.sociallogo}
              alt="linkedinLogo"
            ></img>
          </Link>
        </div>{" "}
        {useWindowSize().width > 460 && (
          <button
            className="relative flex items-center justify-center"
            onClick={getNotificationsMethod}
          >
            <img
              src={notificationLogo}
              className={style.notification}
              alt="notificationLogo"
            ></img>
            <div className=" absolute rounded-full w-8 h-8  bg-[#EB4444] -bottom-2 -right-2 text-white font-[500] font-[Poppins] text-center flex items-center justify-center">
              {notificationNumber}
            </div>
          </button>
        )}
        <Modal
          isOpen={showModal}
          onRequestClose={handleCloseModal}
          style={{
            content: {
              maxWidth: "500px",

              height: "400px",
              backgroundColor: "#F1F2F7",
              color: "#3733bf",
              borderRadius: "20px",
              margin: "auto",
              marginTop: "10%",
              padding: "15px",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "medium",
              fontFamily: "Poppins",
              border: "2px",
              borderStyle: "solid",
              borderColor: "#F99E1D",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              placeContent: "start",
              gap: "10px",
            },
            overlay: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
          }}
        >
          <div className={style.tab}>
            <button className={style.tabicon} onClick={handleCloseModal}>
              <img alt="" src={cross}></img>
            </button>
            <div className={style.tabtext}>Kapat</div>
          </div>

          <div className={style.notificationarea}>
            {notifications.map((notification, index) => (
              <div key={index} className={style.notificationtext}>
                {index + 1}- {notification.mesaj}
              </div>
            ))}{" "}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Header;
