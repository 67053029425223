import style from "./style.module.css";
import video from "../../../assets/video.svg";
import reload from "../../../assets/reload.svg";
import plus from "../../../assets/plus.svg";
import pdf from "../../../assets/pdf.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import StoreContext from "../../../context/store";
import { getPaymentPdf } from "../../../api/general";

const Bar = () => {
  const { setAddPaymentClick,  } =
    useContext(StoreContext);

  const getPaymentPdfMethod = async () => {
    const response = await getPaymentPdf();
    if (response.error) {
      console.log("Ödeme pdfi gelmedi");
      console.log(response.error, "response.erro");
    } else {
      console.log("Ödeme pdfi geldi");
    }
  };



  return (
    <div className={style.container}>
      <div className={style.payments}>
        <Link to="/odemeler" className={style.paymentstext}>Etkinlikler</Link>
        <Link>
          <img alt="" className={style.paymentsvideo} src={video}></img>
        </Link>
      </div>

      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        <img alt="" className={style.reload} src={reload}></img>
      </button>
      <div className={style.tabs}>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              setAddPaymentClick(true);
            }}
          >
            <img alt="" src={plus}></img>
          </button>
          <div className={style.tabtext}>Yeni Etkinlik</div>
        </div>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              //   getPaymentPdfMethod();
            }}
          >
            <img alt="" src={pdf}></img>
          </button>
          <div className={style.tabtext}>PDF Yazdır</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
