import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import EditSocialClubModal from "../EditSocialClubModal";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getSocialClub } from "../../../api/general";

const SocialClubsTable = ({
  title1 = "-",
  title2 = "Kulüp Adı",
  title3 = "Kulüp Öğretmeni",
  title4 = "İşlem",
  data,
}) => {
  const {
    setEditSocialClubClick,
    editSocialClubClick,
    socialClubId,
    setSocialClubId,
    setSocialClub,
  } = useContext(StoreContext);

  useEffect(() => {
    console.log("socialClubId", socialClubId);
  }, [socialClubId]);

  const getSocialClubMethod = async (socialClubId) => {
    const response = await getSocialClub(socialClubId);
    if (response.error) {
      console.log("Sosyal Kulüp bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setSocialClub(response.data);
      console.log("Sosyal Kulüp bilgileri geldi");
    }
  };

  return (
    <div className={style.container}>
      {editSocialClubClick && <EditSocialClubModal socialClubId={socialClubId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>

      </div>
      {data &&
        data.map((socialClub, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{socialClub.id}</div>
            <div className={style.data2}>{socialClub.klupname}</div>
            <div className={style.data3}>{socialClub.ogretmenname}</div>
            <div className={style.data4}>
              <button
                onClick={() => {
                  setSocialClubId(socialClub.id);
                  Promise.resolve(getSocialClubMethod(socialClub.id)).then(() => {
                    setEditSocialClubClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SocialClubsTable;
