import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getLecture } from "../../../api/general";
import AddLectureModal from "../AddLectureModal";

const LecturesTable = ({
  title1 = "-",
  title2 = "Ders Adı",
  title3 = "Kısa Adı",
  title4 = "HDS",
  title5 = "Yerleşme Biçimi",
  title6 = "Toplam Atanan Saat",
  title7="İşlem",
  data,
}) => {
  const {
    setEditLectureClick,
    editLectureClick,
    lectureId,
    setLectureId,
    setLecture,
  } = useContext(StoreContext);

  useEffect(() => {

  }, []);

  const getLectureMethod = async (lectureId) => {
    const response = await getLecture(lectureId);
    if (response.error) {
    } else {
      await setLecture(response.data.result);
    }
  };

  return (
    <div className={style.container}>
      {editLectureClick && <AddLectureModal lectureId={lectureId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
        <div className={style.title7}>{title7}</div>
      </div>
      {data &&
        data.map((lecture, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{lecture.id}</div>
            <div className={style.data2}>{lecture.dersName}</div>
            <div className={style.data3}>{lecture.kisaAd}</div>
            <div className={style.data4}>{lecture.hds}</div>
            <div className={style.data5}>{lecture.yerlesmeBicimi}</div>
            <div className={style.data6}>{lecture.toplamSaat}</div>
            <div className={style.data7}>
              <button
                onClick={() => {
                  setLectureId(lecture.id);
                  Promise.resolve(getLectureMethod(lecture.id)).then(() => {
                    setEditLectureClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default LecturesTable;
