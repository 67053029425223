import { useEffect, useState } from "react";
import { getAnnouncements } from "../api/general";
import style from "../styles/Announcements.module.css";
import AnnouncementsTable from "../components/Announcement/AnnouncementsTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Announcement/AnnouncementBar";
import AddAnnouncementModal from "../components/Announcement/AddAnnouncementModal";
import { useContext } from "react";
import StoreContext from "../context/store";


const Announcements = () => {


  const { addAnnouncementClick, announcementInputValue,userType,LoginMethod } = useContext(StoreContext);

  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
 
  }, []);



  return (
    <div className={style.page}>
      {addAnnouncementClick && <AddAnnouncementModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <AnnouncementsTable
            data={"announcements"}
          
          />
        </div>
      </div>
    </div>
  );
};

export default Announcements;
