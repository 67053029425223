import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState, useRef, useEffect } from "react";
import StoreContext from "../../../context/store";
import { addPayment } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import calendar from "../../../assets/calendaricon.svg";
import { getClassrooms } from "../../../api/general";
import useClickOutside from "../../../hooks/useClickOutside";
import dropdown from "../../../assets/arrowdown.svg";

const AddPaymentModal = () => {
  const { setAddPaymentClick } = useContext(StoreContext);

  const [paymentname, setPaymentname] = useState("");
  const [classroomId, setClassroomId] = useState("");
  const [classroomIds, setClassroomIds] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [price, setPrice] = useState("");
  const [place, setPlace] = useState("");
  const [mebfile, setMebfile] = useState(null);
  const [photo, setPhoto] = useState(null);

  const [classroomName, setClassroomName] = useState("");
  const [classroomNames, setClassroomNames] = useState("");

  // FOR CLASSROOM DROPDOWN

  const [optionArea2, setOptionArea2] = useState(false);
  const buttonRef2 = useRef(null);

  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassroomsMethod();
  }, []); //

  // FOR CLASSROOM DROPDOWN

  useClickOutside(buttonRef2, () => {
    setOptionArea2(false);
  });

  const handleToggle2 = () => {
    setOptionArea2(!optionArea2);
  };

  /*const handleClassroomSelect = (event) => {
    setClassroomName(event.target.value.split(",")[0]);
    setClassroomId(event.target.value.split(",")[1]);
    setOptionArea2(false);
  };*/
  const handleClassroomSelect = (event) => {
    const selectedClassroomId = Number(event.target.value.split(",")[1]);
    const selectedClassName = event.target.value.split(",")[0];

    // Sınıf zaten seçiliyse kaldır, değilse ekle
    if (classroomIds.includes(selectedClassroomId)) {
      const updatedIds = classroomIds.filter(
        (id) => id !== selectedClassroomId
      );
      setClassroomIds(updatedIds);

      const updatedNames = classroomNames
        .split(",")
        .filter((name) => name !== selectedClassName)
        .join(",");
      setClassroomNames(updatedNames);
    } else {
      setClassroomIds([...classroomIds, selectedClassroomId]);

      const updatedNames =
        classroomNames === ""
          ? selectedClassName
          : `${classroomNames},${selectedClassName}`;
      setClassroomNames(updatedNames);
    }
    setOptionArea2(false);
  };

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
    }
  }; //

  const addPaymentMethod = async () => {
    if (
      paymentname === "" ||
      classroomIds === "" ||
      startDate === "" ||
      endDate === "" ||
      price === "" ||
      place === "" 
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await addPayment(
      paymentname,
      classroomIds,
      startDate,
      endDate,
      price,
      place,
      mebfile,
      photo
    );
    if (response.error) {
      console.log("Ödeme ekleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Ödeme ekleme başarısız");
    } else {
      console.log("Ödeme ekleme başarılı");
      successMessage("Ödeme ekleme başarılı");
      setAddPaymentClick(false);

       setTimeout(function () {
        window.location.reload();
      }, 2500); 
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddPaymentClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Etkinlik Adı</div>
            <input
              className={style.inputtext}
              value={paymentname}
              onChange={(e) => setPaymentname(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Sınıfı</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{classroomNames}</div>
              <button onClick={handleToggle2}>
                <img src={dropdown} alt=""></img>
              </button>

              {/*  {optionArea2 && (
                <div className={style.optionarea} ref={buttonRef2}>
                  <div>
                    {classrooms &&
                      classrooms.map((classroom, index) => (
                        <button
                          onClick={handleClassroomSelect}
                          value={[classroom.sinifname, classroom.id]}
                          key={index}
                          className={style.option}
                        >
                          {classroom.sinifname}
                        </button>
                      ))}
                  </div>
                </div>
              )} */}
              {optionArea2 && (
                <div className={style.optionarea} ref={buttonRef2}>
                  <div>
                    {classrooms &&
                      classrooms.map((classroom, index) => (
                        <button
                          onClick={handleClassroomSelect}
                          value={[classroom.sinifname, classroom.id]}
                          key={index}
                          className={`${style.option} ${
                            classroomIds.includes(classroom.id)
                              ? style.selected
                              : ""
                          }`}
                        >
                          {classroom.sinifname}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Başlangıç Tarihi</div>
            <div className={style.inputtext}>
              <DatePicker
                className="bg-transparent border-0 outline-none pt-1 w-full "
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MM/yyyy"
                locale={tr}
                showPopperArrow={false}
                timeZone="UTC+3"
              />

              <img src={calendar} className={style.icon}></img>
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Bitiş Tarihi</div>
            <div className={style.inputtext}>
              <DatePicker
                className="bg-transparent border-0 outline-none pt-1 w-full "
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
                locale={tr}
                showPopperArrow={false}
                timeZone="UTC+3"
              />

              <img src={calendar} className={style.icon}></img>
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Yer</div>
            <input
              className={style.inputtext}
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>MEB Onay Yazısı Ekle</div>
            <div className={style.inputtext}>
              {photo === null ? "Dosya Seçiniz" : "Dosya Seçildi"}
            </div>
            <div className={style.addButton}>
              <input
                className={style.photoinput}
                type="file"
                name="file"
                id="upload-file"
                onChange={(event) => {
                  setMebfile(event.target.files[0]);
                }}
              ></input>
              EKLE
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Medya Ekle</div>
            <div className={style.inputtext}>
              {photo === null ? "Fotoğraf Seçiniz" : "Fotoğraf Seçildi"}
            </div>
            <div className={style.addButton}>
              <input
                className={style.photoinput}
                type="file"
                name="photo"
                id="upload-photo"
                onChange={(event) => {
                  setPhoto(event.target.files[0]);
                }}
              ></input>
              EKLE
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Etkinlik Tutarı</div>
            <input
              className={style.inputtext}
              value={price}
              onChange={(e) => {
                const value = e.target.value;
                // Sadece sayısal değerleri kabul etmek için kontrol yapılıyor
                if (/^\d*$/.test(value)) {
                  setPrice(value);
                }
              }}
              inputMode="numeric"
            ></input>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  addPaymentMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPaymentModal;
