import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { addMeal } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import DatePicker from "react-datepicker";
import calendar from "../../../assets/calendaricon.svg";
import tr from "date-fns/locale/tr";

const AddMealModal = () => {
  const { setAddMealClick } = useContext(StoreContext);

  const [date, setDate] = useState("");
  const [morning, setMorning] = useState("");
  const [afternoon, setAfternoon] = useState("");
  const [evening, setEvening] = useState("");

  const addMealMethod = async () => {
    if (date === "" || morning === "" || afternoon === "" || evening === "") {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await addMeal(date, morning, afternoon, evening);
    if (response.error) {
      console.log("Yemek ekleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Yemek ekleme başarısız");
    } else {
      console.log("Yemek ekleme başarılı");
      successMessage("Yemek ekleme başarılı");
      setAddMealClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddMealClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input2}>
            <div className={style.inputtitle}>Tarih</div>
            <div className={style.inputtext}>
              {" "}
              <DatePicker
                className="bg-transparent border-0 outline-none pt-1 w-full "
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="dd/MM/yyyy"
                locale={tr}
                showPopperArrow={false}
                timeZone="UTC+3"
              />
              <img src={calendar} className={style.icon}></img>
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Kahvaltı</div>
            <input
              className={style.inputtext}
              value={morning}
              onChange={(e) => setMorning(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğle Yemeği</div>
            <input
              className={style.inputtext}
              value={afternoon}
              onChange={(e) => setAfternoon(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Akşam Yemeği</div>
            <input
              className={style.inputtext}
              value={evening}
              onChange={(e) => setEvening(e.target.value)}
            ></input>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  addMealMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMealModal;
