import style from "../styles/Home.module.css";
import ogretmenLogo from "../assets/homeIcons/ogretmen.svg";
import aferinLogo from "../assets/homeIcons/aferin.svg";
import ogrenciLogo from "../assets/homeIcons/ogrenci.svg";
import dersLogo from "../assets/homeIcons/ders.svg";
import duyurularLogo from "../assets/homeIcons/duyurular.svg";
import kullanıcıLogo from "../assets/homeIcons/kullanıcı.svg";
import kursetutLogo from "../assets/homeIcons/kursetut.svg";
import sınavlarLogo from "../assets/homeIcons/sınavlar.svg";
import mesajlasmaLogo from "../assets/homeIcons/mesajlasma.svg";
import odemelerLogo from "../assets/homeIcons/odemeler.svg";
import programlarLogo from "../assets/homeIcons/programlar.svg";
import randevularLogo from "../assets/homeIcons/randevular.svg";
import sınıfLogo from "../assets/homeIcons/sınıf.svg";
import yemekLogo from "../assets/homeIcons/yemek.svg";
import yoklamaLogo from "../assets/homeIcons/yoklama.svg";
import dersliklerLogo from "../assets/homeIcons/derslikler.svg";
import dersatamalariLogo from "../assets/homeIcons/dersatamalari.svg";
import etutLogo from "../assets/homeIcons/etut.svg";
import sosyalkulupLogo from "../assets/homeIcons/sosyalkulup.svg";
import { Link } from "react-router-dom";
import Header from "../components/Header/HomeHeader";
import { useEffect } from "react";
import { useContext } from "react";
import StoreContext from "../context/store";
import NotificationService from "../components/NotificationService";
import { Alert } from "./Alert";
import Chat from "./Chat"


const Home = () => {
  const {
    setTeacherInputValue,
    setLectureInputValue,
    setClassroomInputValue,
    setStudentInputValue,
    setAppointmentInputValue,
    setClassroomCourseAssignmentClassroomInputValue,
    setClassroomCourseAssignmentLectureInputValue,
    setClassroomCourseAssignmentLectureAssignInputValue,
    setTeacherCourseAssignmentClassroomInputValue,
    setTeacherCourseAssignmentTeacherInputValue,
    setTeacherCourseAssignmentLectureAssignInputValue,
    setSchoolroomCourseAssignmentSchoolroomInputValue,
    setSchoolroomCourseAssignmentClassroomInputValue,
    setSchoolroomCourseAssignmentLectureAssignInputValue,
    setSchoolroomInputValue,
    setSocialClubInputValue,
    setWelldoneInputValue,
    setEtudeInputValue,
    setMessageInputValue,
    setAnnouncementInputValue,
    setAttendanceInputValue,
    setMealInputValue,
    setExamInputValue,
  
 
  } = useContext(StoreContext);
  const { userRole } = useContext(StoreContext);
  useEffect(() => {
    setTeacherInputValue("");
    setLectureInputValue("");
    setClassroomInputValue("");
    setStudentInputValue("");
    setAppointmentInputValue("");
    setClassroomCourseAssignmentClassroomInputValue("");
    setClassroomCourseAssignmentLectureInputValue("");
    setClassroomCourseAssignmentLectureAssignInputValue("");
    setTeacherCourseAssignmentClassroomInputValue("");
    setTeacherCourseAssignmentTeacherInputValue("");
    setTeacherCourseAssignmentLectureAssignInputValue("");
    setSchoolroomCourseAssignmentSchoolroomInputValue("");
    setSchoolroomCourseAssignmentClassroomInputValue("");
    setSchoolroomCourseAssignmentLectureAssignInputValue("");
    setSchoolroomInputValue("");
    setSocialClubInputValue("");
    setWelldoneInputValue("");
    setEtudeInputValue("");
    setMessageInputValue("");
    setAnnouncementInputValue("");
    setAttendanceInputValue("");
    setMealInputValue("");
    setExamInputValue("");


  }, []);

  useEffect(() => {
    NotificationService.requestPermission();
    //console.log("rol",userRole)
    //subscribeTopic("6b924595-904a-48df-9fb2-66931751bd75"); /// giriş yapmış kullanıcını guid
  }, []);

  return (
    <div className={style.page}>
      <Header />

      <div className={style.container}>
      <div className={style.newComponent}>

       <div class="customContainer">
      <div className={style.message}>
     {/* <Chat /> */}
    </div>
      <div class="box" style={{ backgroundColor: "#fff",  border:"1px solid #ddd",  borderRadius: "10px",  padding: "10px",boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",width: "700px", maxHeight: "800px"}}>
        <Alert  />
    </div>
  </div> 
    </div>
        {userRole == "Manager" && (

       <>

        <Link className={style.box} to="/ogretmenler">
          <img
            className={style.img}
            src={ogretmenLogo}
            alt="ogretmenlogo"
          ></img>
          <div className={style.title}>Öğretmen</div>
        </Link>
        <Link className={style.box} to="/ogrenciler">
          <img className={style.img} src={ogrenciLogo} alt="ogrencilogo"></img>
          <div className={style.title}>Öğrenci</div>
        </Link>
        <Link className={style.box} to="/siniflar">
          <img className={style.img} src={sınıfLogo} alt="sınıflogo"></img>
          <div className={style.title}>Sınıf</div>
        </Link>
        <Link className={style.box} to="/dersler">
          <img className={style.img} src={dersLogo} alt="derslogo"></img>
          <div className={style.title}>Ders</div>
        </Link>
        <Link className={style.box} to="/aferin">
          <img className={style.img} src={aferinLogo} alt="aferinlogo"></img>
          <div className={style.title}>Aferin</div>
        </Link>
        <Link className={style.box} to="/programlar">
          <img
            className={style.img}
            src={programlarLogo}
            alt="programlarlogo"
          ></img>
          <div className={style.title}>Programlar</div>
        </Link>
        <Link className={style.box} to="/sınavlar">
          <img
            className={style.img}
            src={sınavlarLogo}
            alt="sınavlarlogo"
          ></img>
          <div className={style.title}>Sınavlar</div>
        </Link>
        <Link className={style.box}>
          <img
            className={style.img}
            src={kursetutLogo}
            alt="kursetutlogo"
          ></img>
          <div className={style.title}>Kurs & Etüt</div>
        </Link>
        <Link className={style.box} to="/yoklama">
          <img className={style.img} src={yoklamaLogo} alt="yoklamalogo"></img>
          <div className={style.title}>Yoklama</div>
        </Link>
        <Link className={style.box} to="/duyurular">
          <img
            className={style.img}
            src={duyurularLogo}
            alt="duyurularlogo"
          ></img>
          <div className={style.title}>Duyurular</div>
        </Link>
        <Link className={style.box} to="/yemek">
          <img className={style.img} src={yemekLogo} alt="yemeklogo"></img>
          <div className={style.title}>Yemek</div>
        </Link>
        <Link className={style.box} to="/randevular">
          <img
            className={style.img}
            src={randevularLogo}
            alt="randevularlogo"
          ></img>
          <div className={style.title}>Randevular</div>
        </Link>
        <Link className={style.box} to="/mesajlar">
          <img
            className={style.img}
            src={mesajlasmaLogo}
            alt="mesajlasmalogo"
          ></img>
          <div className={style.title}>Mesajlaşma</div>
        </Link>
        <Link className={style.box} to="/odemeler">
          <img
            className={style.img}
            src={odemelerLogo}
            alt="odemelerlogo"
          ></img>
          <div className={style.title}>Ödemeler</div>
        </Link>
        <Link className={style.box}>
          <img
            className={style.img}
            src={kullanıcıLogo}
            alt="kullanıcılogo"
          ></img>
          <div className={style.title}>Kullanıcı İşlemleri</div>
        </Link>
        <Link className={style.box} to="/derslikler">
          <img
            className={style.img}
            src={dersliklerLogo}
            alt="dersliklerlogo"
          ></img>
          <div className={style.title}>Derslikler</div>
        </Link>
        <Link className={style.box} to="/sosyal-kulupler">
          <img
            className={style.img}
            src={sosyalkulupLogo}
            alt="sosyalkulüplogo"
          ></img>
          <div className={style.title}>Sosyal Kulüp</div>
        </Link>
        <Link className={style.box} to="dersatamalari">
          <img
            className={style.img}
            src={dersatamalariLogo}
            alt="dersatamalarılogo"
          ></img>
          <div className={style.title}>Ders Atamaları</div>
        </Link>
        <Link className={style.box} to="/etut">
          <img className={style.img} src={etutLogo} alt="etütlogo"></img>
          <div className={style.title}>Etüt</div>
        </Link>
        </>
        )}
        {userRole === "Teacher" && (
          <>
         <Link className={style.box} to="/duyurular">
          <img
            className={style.img}
            src={duyurularLogo}
            alt="duyurularlogo"
          ></img>
          <div className={style.title}>Duyurular</div>
          </Link>
          <Link className={style.box} to="/yoklama">
          <img className={style.img} src={yoklamaLogo} alt="yoklamalogo"></img>
          <div className={style.title}>Yoklama</div>
        </Link>
        <Link className={style.box} to="/sınavlar">
          <img
            className={style.img}
            src={sınavlarLogo}
            alt="sınavlarlogo"
          ></img>
          <div className={style.title}>Sınavlar</div>
        </Link>
        <Link className={style.box} to="/aferin">
          <img className={style.img} src={aferinLogo} alt="aferinlogo"></img>
          <div className={style.title}>Aferin</div>
        </Link>
          </>
        )}       
   {userRole === "Student" && (
  <>
    <Link className={style.box} to="/aferin">
      <img className={style.img} src={aferinLogo} alt="aferinlogo" />
      <div className={style.title}>Aferin</div>
    </Link>

    <div>
      <Link className={style.box} to="/yoklama">
        <img className={style.img} src={yoklamaLogo} alt="yoklamalogo"></img>
        <div className={style.title}>Yoklama</div>
      </Link>
    </div>

    <Link className={style.box} to="/sınavlar">
      <img
        className={style.img}
        src={sınavlarLogo}
        alt="sınavlarlogo"
      ></img>
      <div className={style.title}>Sınavlar</div>
    </Link>

    <Link className={style.box} to="/yemek">
      <img className={style.img} src={yemekLogo} alt="yemeklogo"></img>
      <div className={style.title}>Yemek</div>
    </Link>

    <Link className={style.box}>
      <img
        className={style.img}
        src={kullanıcıLogo}
        alt="kullanıcılogo"
      ></img>
      <div className={style.title}>Kullanıcı İşlemleri</div>
    </Link>

  </>
)}
      </div>
      
    </div>
  );
  
};

export default Home;
