import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState, useEffect } from "react";
import StoreContext from "../../../context/store";
import {
  addMessage,
  getStudents,
  getTeachers,
  getParents,
} from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import dateicon from "../../../assets/modalIcons/date.svg";
import clip from "../../../assets/modalIcons/clip.svg";
import send from "../../../assets/modalIcons/send.svg";
import tick from "../../../assets/modalIcons/tick.svg";
import search from "../../../assets/search.svg";
import cn from "classnames";
import { getClassrooms } from "../../../api/general";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";

const AddMessageModal = () => {
  const {
    setAddMessageClick,
    setMessageClassroomInputValue,
    messageClassroomInputValue,
    setMessageTeacherInputValue,
    messageTeacherInputValue,
    messageStudentInputValue,
    setMessageStudentInputValue,
    messageParentInputValue,
    setMessageParentInputValue,
  } = useContext(StoreContext);

  const [image, setImage] = useState(null);
  const [classrooms, setClassrooms] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [parents, setParents] = useState([]);

  const [type] = useState("MESSAGE");
  const [date, setDate] = useState(new Date());
  const [message, setMessage] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedParents, setSelectedParents] = useState([]);

  useEffect(() => {

  }, [selectedOptions]);

  const isOptionSelected = (option) => selectedOptions.includes(option);

  //checkbox click

  const handleCheckboxClick = async (option) => {
    if (isOptionSelected(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }

    if (option === "Sınıflar") {
      setSelectedClassrooms(
        classrooms
          .filter((el) =>
            el.sinifname
              .toLowerCase()
              .includes(messageClassroomInputValue.toLowerCase())
          )
          .map((classroom) => classroom.id)
      );

      if (isOptionSelected("Sınıflar")) {
        setSelectedClassrooms([]);
      }
    }

    if (option === "Öğretmenler") {
      setSelectedTeachers(
        teachers
          .filter(
            (el) =>
              el.nameSurname
                .toLowerCase()
                .includes(messageTeacherInputValue.toLowerCase()) &&
              selectedClassrooms.includes(el.sinifid)
          )
          .map((teacher) => teacher.id)
      );

      if (isOptionSelected("Öğretmenler")) {
        setSelectedTeachers([]);
      }
    }

    if (option === "Öğrenciler") {
      setSelectedStudents(
        students
          .filter(
            (el) =>
              el.nameSurname
                .toLowerCase()
                .includes(messageStudentInputValue.toLowerCase()) &&
              selectedClassrooms.includes(el.sinifid)
          )
          .map((student) => student.id)
      );

      if (isOptionSelected("Öğrenciler")) {
        setSelectedStudents([]);
      }
    }
    if (option === "Veliler") {
      setSelectedParents(
        parents
          .filter(
            (el) =>
              el.nameSurname
                .toLowerCase()
                .includes(messageParentInputValue.toLowerCase()) &&
              selectedClassrooms.includes(el.sinifid)
          )
          .map((parent) => parent.id)
      );

      if (isOptionSelected("Veliler")) {
        setSelectedParents([]);
      }
    }

    console.log(selectedClassrooms, "selectedClassrooms");
    console.log(selectedTeachers, "selectedTeachers");
    console.log(selectedStudents, "selectedStudents");
    console.log(selectedParents, "selectedParents");
    console.log("selectedOptions", selectedOptions);
  };

  //Selected Classrooms CheckBoxes

  const handleSelectedClassrooms = (classroom) => {
    if (selectedClassrooms.includes(classroom)) {
      setSelectedClassrooms(
        selectedClassrooms.filter((item) => item !== classroom)
      );
    } else {
      setSelectedClassrooms([...selectedClassrooms, classroom]);
    }
  };
  const isSelectedClassrooms = (classroom) => {
    // "Sınıflar" seçeneği seçiliyse veya seçili sınıflar arasında bulunuyorsa true döndür
    return selectedClassrooms.includes(classroom); //|| isOptionSelected("Sınıflar")
  };

  //Selected Teachers CheckBoxes

  const handleSelectedTeachers = (teacher) => {
    if (selectedTeachers.includes(teacher)) {
      setSelectedTeachers(selectedTeachers.filter((item) => item !== teacher));
    } else {
      setSelectedTeachers([...selectedTeachers, teacher]);
    }
  };
  const isSelectedTeachers = (teacher) => {
    // "Öğretmenler" seçeneği seçiliyse veya seçili sınıflar arasında bulunuyorsa true döndür
    return selectedTeachers.includes(teacher); //|| isOptionSelected("Öğretmenler")
  };

  //Selected Students CheckBoxes

  const handleSelectedStudents = (student) => {
    if (selectedStudents.includes(student)) {
      setSelectedStudents(selectedStudents.filter((item) => item !== student));
    } else {
      setSelectedStudents([...selectedStudents, student]);
    }
  };
  const isSelectedStudents = (student) => {
    return selectedStudents.includes(student); //|| isOptionSelected("Öğrenciler");
  };

  //Selected Parents CheckBoxes

  const handleSelectedParents = (parent) => {
    if (selectedParents.includes(parent)) {
      setSelectedParents(selectedParents.filter((item) => item !== parent));
    } else {
      setSelectedParents([...selectedParents, parent]);
    }
  };
  const isSelectedParents = (parent) => {
    return selectedParents.includes(parent); //|| isOptionSelected("Veliler");
  };

  //TABLES CLASSROOMS

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
      console.log(response.data, "response.data.classrooms");
    }
  };

  //TABLES TEACHERS

  const getTeachersMethod = async () => {
    const response = await getTeachers();
    if (response.error) {
      console.log("Test öğretmenler gelmedi");
    } else {
      console.log("Test öğretmenler geldi");
      setTeachers(response.data);
      console.log(response.data, "response.data.teachers");
    }
  };

  //TABLES STUDENTS

  const getStudentsMethod = async () => {
    const response = await getStudents();
    if (response.error) {
      console.log("Test öğrenciler gelmedi");
    } else {
      console.log("Test öğrenciler geldi");
      setStudents(response.data);
      console.log(response.data, "response.data.students");
    }
  };

  //TABLES PARENTS

  const getParentsMethod = async () => {
    const response = await getParents();
    if (response.error) {
      console.log("Test veliler gelmedi");
    } else {
      console.log("Test veliler geldi");
      setParents(response.data);
      console.log(response.data, "response.data.parents");
    }
  };

  //ADD MESSAGE
  /*const addMessageMethod = async () => {
    if (message === "") {
      warningMessage("Lütfen mesaj giriniz");

      return;
    }
    if (date === "") {
      warningMessage("Lütfen tarih seçiniz");

      return;
    }
    if (
      selectedTeachers.length === 0 &&
      selectedStudents.length === 0 &&
      selectedParents.length === 0
    ) {
      warningMessage("Lütfen alıcı seçiniz");

      return;
    }

    const response = await addMessage(
      type,
      date,
      message,
      image,
      selectedTeachers,
      selectedStudents,
      selectedParents
    );
    if (response.error) {
      console.log("Mesaj ekleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Mesaj ekleme başarısız");
    } else {
      console.log("Mesaj ekleme başarılı");
      successMessage("Mesaj ekleme başarılı");
      setAddMessageClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  }; */

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddMessageClick(false);
                  setMessageClassroomInputValue("");
                  setMessageTeacherInputValue("");
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.upperinputs}>
            <textarea
              className={style.messageArea}
              placeholder="Mesajınız"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <div className={style.buttonArea}>
              <div className={style.tab2}>
                <input
                  type="file"
                  name="photo"
                  id="upload-photo"
                  className={style.photoinput}
                  onChange={(event) => {
                    setImage(event.target.files[0]);
                  }}
                ></input>
                <div className={style.tabicon2}>
                  <img alt="" src={clip}></img>
                </div>

                <div className={style.tabtext2}>Medya Ekle</div>
              </div>
              <div className={style.tab2}>
                <div className={style.tabicon2}>
                  <img alt="" src={dateicon}></img>
                </div>
                <div className={style.tabtext2}>
                  <DatePicker
                    className="bg-transparent border-0 outline-none pt-1 w-full "
                    timeZone="Europe/Istanbul"
                    selected={date}
                    onChange={(date) => setDate(date)}
                    dateFormat="dd/MM/yyyy"
                    locale={tr}
                    showPopperArrow={false}
                  />
                </div>
              </div>
              <div className={style.tab1}>
                <button
                  className={style.tabicon1}
                  onClick={() => {
                    //addMessageMethod();
                  }}
                >
                  <img alt="" src={send}></img>
                </button>
                <div className={style.tabtext1}>GÖNDER</div>
              </div>
            </div>
          </div>

          <div className={style.lowerinputs}>
            <div className={style.tablearea1}>
              <div className={style.tabletitle}>
                Sınıf: {selectedClassrooms.length}
              </div>
              <div className={style.search}>
                <button className={style.searchicon}>
                  <img alt="" src={search}></img>
                </button>
                <input
                  className={style.searchinput}
                  placeholder="Sınıf Ara"
                  onChange={(e) => {
                    setMessageClassroomInputValue(e.target.value);
                  }}
                  value={messageClassroomInputValue}
                ></input>
              </div>

              <div className={style.table}>
                <div className={style.title1}>
                  <button
                    className={`${style.checkbox} ${
                      isOptionSelected("Sınıflar") ? style.selected : ""
                    }`}
                    onClick={() => {
                      handleCheckboxClick("Sınıflar");
                      console.log("sınıflar", selectedClassrooms);
                    }}
                  >
                    {isOptionSelected("Sınıflar") && <img src={tick} alt="" />}
                  </button>
                  <div className="pl-2 h-full  flex items-center">
                    {" "}
                    Sınıflar
                  </div>
                </div>

                <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
                  {selectedClassrooms &&
                    classrooms &&
                    classrooms
                      ?.filter((el) =>
                        el.sinifname
                          .toLowerCase()
                          .includes(messageClassroomInputValue.toLowerCase())
                      )
                      .map((classroom, index) => (
                        <div
                          className={cn(
                            style.datas,
                            index % 2 === 0 && style.datas2
                          )}
                          key={index}
                        >
                          <button
                            className={`${style.checkbox} ${
                              isSelectedClassrooms(classroom.id)
                                ? style.selected
                                : ""
                            }`}
                            onClick={() =>
                              handleSelectedClassrooms(classroom.id)
                            }
                          >
                            {isSelectedClassrooms(classroom.id) && (
                              <img src={tick} alt="" />
                            )}
                          </button>
                          <div className={style.data1}>
                            {classroom.sinifname}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            <div className={style.tablearea2}>
              <div className={style.tabletitle}>
                Seçilen Öğretmenler: {selectedTeachers.length}
              </div>
              <div className={style.search}>
                <button className={style.searchicon}>
                  <img alt="" src={search}></img>
                </button>
                <input
                  className={style.searchinput}
                  placeholder="Öğretmen Ara"
                  onChange={(e) => {
                    setMessageTeacherInputValue(e.target.value);
                  }}
                  value={messageTeacherInputValue}
                ></input>
              </div>

              <div className={style.table}>
                <div className={style.title1}>
                  <button
                    className={`${style.checkbox} ${
                      isOptionSelected("Öğretmenler") ? style.selected : ""
                    }`}
                    onClick={() => handleCheckboxClick("Öğretmenler")}
                  >
                    {isOptionSelected("Öğretmenler") && (
                      <img src={tick} alt="" />
                    )}
                  </button>
                  <div className="pl-2 h-full  flex items-center">
                    {" "}
                    Öğretmenler
                  </div>
                </div>

                <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
                  {selectedTeachers &&
                    teachers &&
                    teachers
                      ?.filter(
                        (el) =>
                          el.nameSurname
                            .toLowerCase()
                            .includes(messageTeacherInputValue.toLowerCase()) &&
                          selectedClassrooms.includes(el.sinifid) // sınıflara göre filtreleme
                      )
                      .map((teacher, index) => (
                        <div
                          className={cn(
                            style.datas,
                            index % 2 === 0 && style.datas2
                          )}
                          key={index}
                        >
                          <button
                            className={`${style.checkbox} ${
                              isSelectedTeachers(teacher.id)
                                ? style.selected
                                : ""
                            }`}
                            onClick={() => handleSelectedTeachers(teacher.id)}
                          >
                            {isSelectedTeachers(teacher.id) && (
                              <img src={tick} alt="" />
                            )}
                          </button>
                          <div className={style.data1}>
                            {teacher.nameSurname}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>

            <div className={style.tablearea2}>
              <div className={style.tabletitle}>
                Seçilen Öğrenciler: {selectedStudents.length}
              </div>
              <div className={style.search}>
                <button className={style.searchicon}>
                  <img alt="" src={search}></img>
                </button>
                <input
                  className={style.searchinput}
                  placeholder="Öğrenci Ara"
                  onChange={(e) => {
                    setMessageStudentInputValue(e.target.value);
                  }}
                  value={messageStudentInputValue}
                ></input>
              </div>

              <div className={style.table}>
                <div className={style.title1}>
                  <button
                    className={`${style.checkbox} ${
                      isOptionSelected("Öğrenciler") ? style.selected : ""
                    }`}
                    onClick={() => handleCheckboxClick("Öğrenciler")}
                  >
                    {isOptionSelected("Öğrenciler") && (
                      <img src={tick} alt="" />
                    )}
                  </button>
                  <div className="pl-2 h-full  flex items-center">
                    {" "}
                    Öğrenciler
                  </div>
                </div>

                <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
                  {selectedStudents &&
                    students &&
                    students
                      ?.filter(
                        (el) =>
                          el.nameSurname
                            .toLowerCase()
                            .includes(messageStudentInputValue.toLowerCase()) &&
                          selectedClassrooms.includes(el.sinifid) // sınıflara göre filtreleme
                      )
                      .map((student, index) => (
                        <div
                          className={cn(
                            style.datas,
                            index % 2 === 0 && style.datas2
                          )}
                          key={index}
                        >
                          <button
                            className={`${style.checkbox} ${
                              isSelectedStudents(student.id)
                                ? style.selected
                                : ""
                            }`}
                            onClick={() => handleSelectedStudents(student.id)}
                          >
                            {isSelectedStudents(student.id) && (
                              <img src={tick} alt="" />
                            )}
                          </button>
                          <div className={style.data1}>
                            {student.nameSurname}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>

            <div className={style.tablearea2}>
              <div className={style.tabletitle}>
                Seçilen Veliler: {selectedParents.length}
              </div>
              <div className={style.search}>
                <button className={style.searchicon}>
                  <img alt="" src={search}></img>
                </button>
                <input
                  className={style.searchinput}
                  placeholder="Veli Ara"
                  onChange={(e) => {
                    setMessageParentInputValue(e.target.value);
                  }}
                  value={messageParentInputValue}
                ></input>
              </div>

              <div className={style.table}>
                <div className={style.title1}>
                  <button
                    className={`${style.checkbox} ${
                      isOptionSelected("Veliler") ? style.selected : ""
                    }`}
                    onClick={() => handleCheckboxClick("Veliler")}
                  >
                    {isOptionSelected("Veliler") && <img src={tick} alt="" />}
                  </button>
                  <div className="pl-2 h-full  flex items-center"> Veliler</div>
                </div>

                <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
                  {selectedParents &&
                    parents &&
                    parents
                      ?.filter(
                        (el) =>
                          el.nameSurname
                            .toLowerCase()
                            .includes(messageParentInputValue.toLowerCase()) &&
                          selectedClassrooms.includes(el.sinifid) // sınıflara göre filtreleme
                      )
                      .map((parent, index) => (
                        <div
                          className={cn(
                            style.datas,
                            index % 2 === 0 && style.datas2
                          )}
                          key={index}
                        >
                          <button
                            className={`${style.checkbox} ${
                              isSelectedParents(parent.id) ? style.selected : ""
                            }`}
                            onClick={() => handleSelectedParents(parent.id)}
                          >
                            {isSelectedParents(parent.id) && (
                              <img src={tick} alt="" />
                            )}
                          </button>
                          <div className={style.data1}>
                            {parent.nameSurname}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMessageModal;
