import style from "./style.module.css";
import dropdown from "../../../../assets/arrowdown.svg";
import {
  getClassrooms,
  copyClassroomsAssignments,
} from "../../../../api/general";
import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../../helpers/toast";

const AssignmentCopy = () => {
  const [classroom, setClassroom] = useState("");
  const [classroomid, setClassroomId] = useState("");

  const [copyclassroom, setCopyClassroom] = useState("");
  const [copyclassroomid, setCopyClassroomId] = useState("");

  // FOR CLASSROOM DROPDOWN

  const [optionArea2, setOptionArea2] = useState(false);
  const buttonRef2 = useRef(null);

  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassroomsMethod();
    // getUnitsMethod();
  }, []); //

  // FOR CLASSROOM DROPDOWN

  useClickOutside(buttonRef2, () => {
    setOptionArea2(false);
  });

  const handleToggle2 = () => {
    setOptionArea2(!optionArea2);
  };

  const handleClassroomSelect = (event) => {
    setClassroom(event.target.value.split(",")[0]);
    setClassroomId(event.target.value.split(",")[1]);
    setOptionArea2(false);
  };

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
      setCopyClassrooms(response.data);
    }
  }; //

  // FOR  COPY CLASSROOM DROPDOWN

  const [optionArea, setOptionArea] = useState(false);
  const buttonRef = useRef(null);

  const [copyclassrooms, setCopyClassrooms] = useState([]);

  // FOR COPY CLASSROOM DROPDOWN

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const handleCopyClassroomSelect = (event) => {
    setCopyClassroom(event.target.value.split(",")[0]);
    setCopyClassroomId(event.target.value.split(",")[1]);
    setOptionArea(false);
  };

  const copyClassroomsAssignmentsMethod = async () => {
    if (classroomid === "" || copyclassroomid === "") {
      warningMessage("Lütfen boş alan bırakmayınız");
      return;
    }

    const response = await copyClassroomsAssignments(
      classroomid,
      copyclassroomid
    );
    if (response.error) {
      console.log("Kopyalama başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Kopyalama başarısız");
    } else {
      console.log("Kopyalandı");
      successMessage("Kopyalandı");
    }
  };

  return (
    <div className={style.container}>
      <div className={style.col}>
        <div className={style.title}>Seçilen Sınıf</div>{" "}
        <div className={style.dropdown}>
          <div className={style.selectedoption}>{classroom}</div>
          <button onClick={handleToggle2}>
            <img src={dropdown} alt=""></img>
          </button>

          {optionArea2 && (
            <div className={style.optionarea} ref={buttonRef2}>
              <div>
                {classrooms &&
                  classrooms.map((classroom, index) => (
                    <button
                      onClick={handleClassroomSelect}
                      value={[classroom.sinifname, classroom.id]}
                      key={index}
                      className={style.option}
                    >
                      {classroom.sinifname}
                    </button>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={style.col}>
        <div className={style.title}>Kopyalanacak Sınıflar</div>{" "}
        <div className={style.dropdown}>
          <div className={style.selectedoption}>{copyclassroom}</div>
          <button onClick={handleToggle}>
            <img src={dropdown} alt=""></img>
          </button>

          {optionArea && (
            <div className={style.optionarea} ref={buttonRef}>
              <div>
                {copyclassrooms &&
                  copyclassrooms.map((classroom, index) => (
                    <button
                      onClick={handleCopyClassroomSelect}
                      value={[classroom.sinifname, classroom.id]}
                      key={index}
                      className={style.option}
                    >
                      {classroom.sinifname}
                    </button>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={style.col1}>
        <div className={style.title}></div>{" "}
        <button
          className={style.button}
          onClick={() => {
            copyClassroomsAssignmentsMethod(classroomid, copyclassroomid);
          }}
        >
          KOPYALA
        </button>
      </div>
    </div>
  );
};

export default AssignmentCopy;
