import style from "./style.module.css";
import video from "../../../assets/video.svg";
import reload from "../../../assets/reload.svg";
import { Link } from "react-router-dom";


const Bar = () => {
  return (
    <div className={style.container}>
      <div className={style.exams}>
        <div className={style.examstext}>Programlar</div>
        <Link>
          <img alt="" className={style.examsvideo} src={video}></img>
        </Link>
      </div>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        <img alt="" className={style.reload} src={reload}></img>
      </button>
    </div>
  );
};

export default Bar;
