import style from "./style.module.css";
import video from "../../../assets/video.svg";
import search from "../../../assets/search.svg";
import reload from "../../../assets/reload.svg";
import plus from "../../../assets/plus.svg";
import pdf from "../../../assets/pdf.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import StoreContext from "../../../context/store";
import { getMealPdf } from "../../../api/general";

const Bar = () => {
  const { setAddMealClick, setMealInputValue, mealInputValue } =
    useContext(StoreContext);

  const getMealPdfMethod = async () => {
    const response = await getMealPdf();
    if (response.error) {
      console.log("Yemek pdfi gelmedi");
      console.log(response.error, "response.erro");
    } else {
      console.log("Yemek pdfi geldi");
    }
  };

  return (
    <div className={style.container}>
      <div className={style.meals}>
        <div className={style.mealstext}>Yemek Listesi</div>
        <Link>
          <img alt="" className={style.mealsvideo} src={video}></img>
        </Link>
      </div>
      <div className={style.search}>
        <button className={style.searchicon}>
          <img alt="" src={search}></img>
        </button>
        <input
          className={style.searchinput}
          placeholder="Ara"
          onChange={(e) => {
            setMealInputValue(e.target.value);
          }}
          value={mealInputValue}
        ></input>
      </div>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        <img alt="" className={style.reload} src={reload}></img>
      </button>
      <div className={style.tabs}>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              setAddMealClick(true);
            }}
          >
            <img alt="" src={plus}></img>
          </button>
          <div className={style.tabtext}>Yeni Menü</div>
        </div>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
           //   getMealPdfMethod();
            }}
          >
            <img alt="" src={pdf}></img>
          </button>
          <div className={style.tabtext}>PDF Yazdır</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
