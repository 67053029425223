import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../context/store";
import {
  addLecture,
  deleteLecture,
  editLecture,
  getLectureGroup,
} from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import deleteicon from "../../../assets/modalIcons/delete.svg";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
const AddLectureModal = ({ lectureId }) => {
  const { setAddLectureClick, setLecture, lecture, setEditLectureClick } =
    useContext(StoreContext);

  const [lectureGroups, setLectureGroups] = useState([]);
  const [selectedLectureGroup, setSelecetedLectureGroup] = useState(
    lecture.group
  );

  useEffect(() => {
    getLectureGroupMethod();
  }, []);

  const setVariable = (varableName, value) => {
    let temp = Object.assign({}, lecture);
    temp[varableName] = value;
    setLecture(temp);
  };

  const getLectureGroupMethod = async () => {
    const response = await getLectureGroup();
    if (response.error) {
    } else {
      setLectureGroups(response.data);
    }
  };

  const closeModal = () => {
    if (lectureId !== undefined) setEditLectureClick(false);
    else setAddLectureClick(false);
  };

  const addLectureMethod = async () => {
    if (
      lecture.dersName === "" ||
      lecture.kisaAd === "" ||
      lecture.hds === "" ||
      lecture.yerlesmeBicimi === "" ||
      lecture.group === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }
    let response;
    let message;
    if (lectureId !== undefined) {
      response = await editLecture(lectureId, lecture);
      message = "düzenleme";
    } else {
      response = await addLecture(lecture);
      message = "ekleme";
    }

    if (response.error) {
      errorMessage(`Ders ${message} başarısız`);
    } else {
      successMessage(`Ders ${message} başarılı`);
      setAddLectureClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  const deleteLectureMethod = async () => {
    const response = await deleteLecture(lectureId);
    if (response.error) {
      errorMessage("Ders silinemedi");
    } else {
      successMessage("Ders silindi");
      setEditLectureClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon} onClick={closeModal}>
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Ders Adı</div>
            <input
              className={style.inputtext}
              value={lecture.dersName ?? ""}
              onChange={(e) => setVariable("dersName", e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Dersin Kısa Adı</div>
            <input
              className={style.inputtext}
              value={lecture.kisaAd ?? ""}
              onChange={(e) => setVariable("kisaAd", e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Haftalık Ders Saati</div>
            <input
              className={style.inputtext}
              type="number"
              value={lecture.hds ?? ""}
              onChange={(e) => setVariable("hds", e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Yerleşme Düzeni</div>
            <input
              className={style.inputtext}
              value={lecture.yerlesmeBicimi ?? ""}
              onChange={(e) => setVariable("yerlesmeBicimi", e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Seçmeli Ders</div>
            <div className={style.inputcheck}>
              <input
                type="checkbox"
                className={style.inputtextcheck}
                checked={lecture.secmeliDers ?? ""}
                onChange={(event) => {
                  setVariable("secmeliDers", event.target.checked);
                }}
              ></input>
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Bölünebilme</div>
            <div className={style.inputcheck}>
              <input
                type="checkbox"
                className={style.inputtextcheck}
                checked={lecture.bolunebilme}
                onChange={(event) => {
                  setVariable("bolunebilme", event.target.checked);
                }}
              ></input>{" "}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Grup</div>
            <Select
              value={selectedLectureGroup}
              className={style.inputtext}
              isClearable={true}
              onChange={(e) => {
                setSelecetedLectureGroup(e);
                setVariable("group", e);
              }}
              getOptionValue={(option) => option.key}
              getOptionLabel={(option) => option.value}
              options={lectureGroups}
              placeholder=""
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: "1px solid #3733bf",
                  backgroundColor: "#EEF8FF",
                  width: "102%",
                  height: "30px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  margin: "-6px",
                  marginLeft: "-9px",
                }),
              }}
            />
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              {lectureId !== undefined && (
                <div className={style.tab1}>
                  <button
                    className={style.tabicon}
                    onClick={() => {
                      deleteLectureMethod();
                    }}
                  >
                    <img alt="" src={deleteicon}></img>
                  </button>
                  <div className={style.tabtext1}>Sil</div>
                </div>
              )}
            </div>
          </div>

          <div className={style.lowertabs}>
            <div className={style.tableftside}></div>
            <div className={style.tabrightside}>
              <div className={style.tab}>
                <button
                  className={style.tabicon}
                  onClick={() => {
                    addLectureMethod();
                  }}
                >
                  <img alt="" src={save}></img>
                </button>
                <div className={style.tabtext}>Kaydet</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLectureModal;
