import axios from "axios";
import moment from 'moment'; 
import 'moment-timezone';
import 'moment/locale/tr'
import { async } from "q";
const { REACT_APP_API_HOST } = process.env;
const baseURL = `${REACT_APP_API_HOST}/api`;

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const { origin } = new URL(config.url);

    const allowedOrigins = baseURL;
    if (allowedOrigins.includes(origin)) {
      config.headers.Authorization = localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : "";
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      return (window.location.href = "/");
    }
    return Promise.reject(error);
  }
);

//LOGIN - REGISTER

export const generalLogin = async (email, password) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/account/login`,
      data: {
        email: email,
        password: password,
      },
    });
    return await response;
  } catch (error) {
    console.log("Login Error: ", error);
    return { error };
  }
};

export const generalRegister = async (email, firstNameLastName, password) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/account/register`,
      data: {
        email: email,
        firstNameLastName: firstNameLastName,
        password: password,
      },
    });

    return await response;
  } catch (error) {
    console.log("Register Error: ", error);
    return { error };
  }
};

//PARENTS

export const getParents = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/system/parents`,
    });

    return await response;
  } catch (error) {
    return { error: true };
  }
};

//TEACHERS

export const getTeachers = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/teacher`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getBranchTeacher = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/teacher/BranchTeacher`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getTeacher = async (teacherId) => {
  try {
    const response = await axios({
      method: "get",
      url: `${baseURL}/teacher/${teacherId}`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const addTeacher = async (
  nameSurname,
  password,
  unvan,
  brans,
  phone,
  email
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/teacher`,
      data: {
        nameSurname: nameSurname,
        password: password,
        unvan: unvan,
        brans: brans,
        phone: phone,
        email: email,
      },
    });
    return await response;
  } catch (error) {
    return { error };
  }
};

export const deleteTeacher = async (teacherId) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseURL}/teacher/${teacherId}`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const editTeacher = async (
  nameSurname,
  password,
  unvan,
  brans,
  phone,
  email,
  teacherId
) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseURL}/teacher/${teacherId}`,
      data: {
        nameSurname: nameSurname,
        password: password,
        unvan: unvan,
        branches: brans.map((s) => s.key),
        phone: phone,
        email: email,
      },
    });
    return await response;
  } catch (error) {
    return { error };
  }
};

export const getTeacherPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getogretmenfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Öğretmenler.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getTeacherPdf Error: ", error);
    return { error };
  }
};

export const getTeachersDD = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/system/teachers`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

//STUDENTS

export const getStudentsDD = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/system/students`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getStudents = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/student`,
    });

    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getStudent = async (studentId) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/student/${studentId}`,
      data: {
        id: studentId,
      },
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getClassnamesDD = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/system/classrooms`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const addStudent = async (student) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/student`,
      data: student,
    });

    return await response;
  } catch (error) {
    return { error };
  }
};

export const deleteStudent = async (studentId) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseURL}/student/${studentId}`,
    });

    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const editStudent = async (studentId, student) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseURL}/student/${studentId}`,
      data: student,
    });
    return await response;
  } catch (error) {
    return { error };
  }
};

export const getStudentPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getpdfstudent`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Öğrenciler.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getStudentPdf Error: ", error);
    return { error };
  }
};

//CLASSROOMS

export const getClassrooms = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/class`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getClassWithStudents = async (e) => {

  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/class/withStudents/${e}`,
    });
    console.log('url', `${baseURL}/class/withStudents/${e}`)
    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAttendances Error: ", error);
    return { error: true };
  }
};

export const getClassStudents = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/class/classStudents`,
    })
    return await response;
  } catch (error) {
    return { error: true };
  }
}

export const getClassroom = async (classroomId) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/class/${classroomId}`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const addClassroom = async (classroom) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/class`,
      data: classroom,
    });
    return await response;
  } catch (error) {
    return { error };
  }
};

export const deleteClassroom = async (classroomId) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseURL}/class/${classroomId}`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const editClassroom = async (classroomId, classroom) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseURL}/class/${classroomId}`,
      data: classroom,
    });
    return await response;
  } catch (error) {
    return { error };
  }
};

export const getClassroomPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getpdfsinif`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Sınıflar.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getClassroomPdf Error: ", error);
    return { error };
  }
};

export const getClassroomHours = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getdersprogramisaat`,
    });

    return await response;
  } catch (error) {
    return { error: true };
  }
};

//CLASSROOMS COPY API

export const classroomCopy = async (
  classroomId,
  buttonId,
  classroomhours,
  classroominterval
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/sinifcopy`,
      data: {
        id: classroomId,
        buttonid: buttonId,
        classroomhours: classroomhours,
        classroominterval: classroominterval,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("classroomCopy Error: ", error);
    return { error: true };
  }
};

//LECTURES

export const getLectures = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/lesson`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getLectureGroup = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/system/lesson-groups`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getLecture = async (lectureId) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/lesson/${lectureId}`,
      data: {
        id: lectureId,
      },
    });

    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const addLecture = async (lecture) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/lesson`,
      data: lecture,
    });

    return await response;
  } catch (error) {
    return { error };
  }
};

export const deleteLecture = async (lectureId) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseURL}/lesson/${lectureId}`,
      data: {
        id: lectureId,
      },
    });

    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const editLecture = async (lectureId, lecture) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseURL}/lesson/${lectureId}`,
      data: lecture,
    });

    return await response;
  } catch (error) {
    return { error };
  }
};

export const getLecturesDD = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/system/lessons`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getLecturePdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getpdfders`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Dersler.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getLecturePdf Error: ", error);
    return { error };
  }
};
//SCHOOLROOMS

export const getSchoolrooms = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getdersliklers`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getSchoolrooms Error: ", error);
    return { error: true };
  }
};

export const getSchoolroom = async (schoolroomId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getderslik`,
      data: {
        derslikid: schoolroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getSchoolroom Error: ", error);
    return { error: true };
  }
};

export const addSchoolroom = async (
  schoolroomname,
  shortschoolroomname,
  classroom,
  placement,
  xstudentcount,
  ystudentcount
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addderslik`,
      data: {
        derslikname: schoolroomname,
        kisaad: shortschoolroomname,
        sinif: classroom,
        tekcift: placement,
        yatay: xstudentcount,
        dikey: ystudentcount,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add schoolroom Error: ", error);
    return { error };
  }
};

export const deleteSchoolroom = async (schoolroomId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deletederslik`,
      data: {
        derslikid: schoolroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deleteSchoolroom Error: ", error);
    return { error };
  }
};

export const editSchoolroom = async (
  schoolroomId,
  schoolroomname,
  shortschoolroomname,
  classroom,
  placement,
  xstudentcount,
  ystudentcount
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/editderslik`,
      data: {
        derslikid: schoolroomId,
        derslikname: schoolroomname,
        kisaad: shortschoolroomname,
        sinif: classroom,
        tekcift: placement,
        yatay: xstudentcount,
        dikey: ystudentcount,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit schoolroom Error: ", error);
    return { error };
  }
};

export const getSchoolroomPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getpdfderslikler`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Derslikler.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getSchoolroomPdf Error: ", error);
    return { error };
  }
};

//APPOINTMENTS

/*export const getAppointments = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getrandevus`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAppointments Error: ", error);
    return { error: true };
  }
};*/

export const getAppointmentUnit = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/appointment/GetUnit`,
    });

    //console.log(response, "response");
    return await response;
  } catch (error) {
    //console.log("getAppointments Error: ", error);
    return { error: true };
  }
};

export const getTeacherUnit = async (unitId) => {
  try {
   
    const response = await axios({
      method: "GET",
      url: `${baseURL}/appointment/GetTeacherByUnit?unitId=${unitId}`,
    });
    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getAppointment = async (appointmentId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getrandevu`,
      data: {
        id: appointmentId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAppointment Error: ", error);
    return { error: true };
  }
};

export const addAppointment = async (
  appointmentdate,
  appointmenttime,
  appointmentunit,
  appointmentissue,
  appointmentperson,
  appointmentstudent,
  appointmentclassroomId,
  appointmentapproval,
  appointmentstatus,
  appointmentview
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addrandevu`,
      data: {
        tarih: appointmentdate,
        saat: appointmenttime,
        randevubirim: appointmentunit,
        konu: appointmentissue,
        randevualan: appointmentperson,
        studentname: appointmentstudent,
        sinif_id: appointmentclassroomId,
        onay: appointmentapproval,
        durum: appointmentstatus,
        gorus: appointmentview,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add Appointment Error: ", error);
    return { error };
  }
};

export const deleteAppointment = async (appointmentId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deleterandevu`,
      data: {
        id: appointmentId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deleteAppointment Error: ", error);
    return { error: true };
  }
};

export const editAppointment = async (
  appointmentId,
  appointmentdate,
  appointmenttime,
  appointmentunit,
  appointmentissue,
  appointmentperson,
  appointmentstudent,
  appointmentclassroomId,
  appointmentapproval,
  appointmentstatus,
  appointmentview
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/editrandevu`,
      data: {
        id: appointmentId,
        tarih: appointmentdate,
        saat: appointmenttime,
        randevubirim: appointmentunit,
        konu: appointmentissue,
        randevualan: appointmentperson,
        studentname: appointmentstudent,
        sinif_id: appointmentclassroomId,
        onay: appointmentapproval,
        durum: appointmentstatus,
        gorus: appointmentview,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit Appointment Error: ", error);
    return { error };
  }
};

export const getAppointmentPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getrandevufile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Randevular.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAppointmentPdf Error: ", error);
    return { error };
  }
};

// APPOINTMENS SETTINGS

export const getTodayDate = async (teacherId) => {
  try {
    
    const response = await axios({
      method: "GET",
      url: `${baseURL}/appointment/getAppointmentTimes?teacherId=${teacherId}`,
    });
    return await response;
  } catch (error) {
    return { error };
  }
};

export const getUnits = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getbirims`,
    });

    return await response;
  } catch (error) {
    return { error: true };
  }
};

export const getUnitOfficers = async (unitName) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getbirim`,
      data: {
        birimname: unitName,
      },
    });


    return await response;
  } catch (error) {
    console.log("getUnitOfficers Error: ", error);
    return { error: true };
  }
};

export const getUnitOfficerInfo = async (unitId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getbirimwithid`,
      data: {
        id: unitId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getUnitOfficerInfo Error: ", error);
    return { error: true };
  }
};

export const postClosedDate = async (unitId, closedDates) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/appointment/addblockdate`,
      data: {
        birimId: unitId,
        kapalıTarih: closedDates,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("postClosedDate Error: ", error);
    return { error };
  }
};

export const deleteAppointmentDate = async (teacherId) => {
  try {
    debugger;
    const response = await axios({
      method: "DELETE",
      url: `${baseURL}/appointment/deleteAllAppointments/${teacherId}`,
    });
    return await response;
  } catch (error) {
    console.log("deleteClosedDate Error: ", error);
    return { error };
  }
};

export const saveAppointmentSettings = async (
  teacherId,
  availabletimeStart,
  availabletimeEnd
) => {
  try {
    const formattedStartTime = moment(availabletimeStart).tz('Europe/Istanbul').format('YYYY-MM-DDTHH:mm:ss');
    const formattedEndTime = moment(availabletimeEnd).tz('Europe/Istanbul').format('YYYY-MM-DDTHH:mm:ss');
    
    debugger;
    const response = await axios({
      method: "POST",
      url: `${baseURL}/appointment/openAppointment`,
      data: {
        begginingAppointmentTimes: formattedStartTime,
        finalAppointmentTimes: formattedEndTime,
        teacherId: teacherId
      },
    });

    return await response;
  } catch (error) {
    console.log("saveAppointmentSettings Error: ", error);
    return { error };
  }
};

// COURSE ASSIGNMENTS

export const getCourseAssignmentPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getdersatamalarifile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "DersAtamaları.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getCourseAssingmentPdf Error: ", error);
    return { error };
  }
};

//CLASSROOM ASSIGNMENTS LECTURE

export const getClassroomLectureAssigns = async (classroomId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getsinifdersatamas`,
      data: {
        sinif_id: classroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" getClassroomLectureAssigns Error: ", error);
    return { error: true };
  }
};

export const classroomLectureAssignsDelete = async (lectureId, classroomId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deletedersatamaders`,
      data: {
        dersid: lectureId,
        sinifid: classroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" classroomLectureAssignsDelete Error: ", error);
    return { error };
  }
};

export const addClassroomLectureAssigns = async (lectureId, classroomId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addersatamaders`,
      data: {
        dersid: lectureId,
        sinifid: classroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" addClassroomLectureAssignss Error: ", error);
    return { error };
  }
};

//TEACHER ASSIGNMENTS LECTURE

export const getTeacherLectureAssigns = async (teacherId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getogretmendersatamas`,
      data: {
        ogretmenid: teacherId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" getTeacherLectureAssigns Error: ", error);
    return { error: true };
  }
};

export const teacherLectureAssignsDelete = async (
  lectureId,
  teacherId,
  classroomId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deleteogrementatamaders`,
      data: {
        dersid: lectureId,
        ogretmenid: teacherId,
        sinifid: classroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" teacherLectureAssignsDelete Error: ", error);
    return { error };
  }
};

export const addTeacherLectureAssigns = async (
  lectureId,
  teacherId,
  classroomId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addogretmensatamaders`,
      data: {
        dersid: lectureId,
        ogretmenid: teacherId,
        sinifid: classroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" addTeacherLectureAssigns Error: ", error);
    return { error };
  }
};

//SCHOOLROOM ASSIGNMENTS LECTURE

export const getSchoolroomLectureAssigns = async (schoolroomId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getdersliklerswithid`,
      data: {
        derslikid: schoolroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" getSchoolroomLectureAssigns Error: ", error);
    return { error: true };
  }
};

export const schoolroomLectureAssignsDelete = async (
  schoolroomId,
  classroomId,
  lectureId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deletederslikatama`,
      data: {
        derslikid: schoolroomId,
        sinifid: classroomId,
        dersid: lectureId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" schoolroomLectureAssignsDelete Error: ", error);
    return { error };
  }
};

export const addSchoolroomLectureAssigns = async (
  lectureId,
  schoolroomId,
  classroomId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addderslikatama`,
      data: {
        dersid: lectureId,
        derslikid: schoolroomId,
        sinifid: classroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" addSchoolroomLectureAssigns Error: ", error);
    return { error };
  }
};

export const getSchoolroomsUnique = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getderslikler`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getSchoolroomsUnique Error: ", error);
    return { error: true };
  }
};

//common course assignments

export const addClassroomMultipleLectureAssigns = async (
  lectureId,
  classroomId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addersatamadersmultiple`,
      data: {
        dersid: lectureId,
        sinifid: classroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" addClassroomMultipleLectureAssignss Error: ", error);
    return { error };
  }
};

export const getClassroomMultipleLectureAssigns = async (classroomId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getsinifdersatamasmultiple`,
      data: {
        sinif_id: classroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" getClassroomMultipleLectureAssigns Error: ", error);
    return { error: true };
  }
};

//COPY CLASSROOMS ASSIGNMENTS

export const copyClassroomsAssignments = async (
  classroomId,
  copyclassroomId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/copydersler`,
      data: {
        sinifid: classroomId,
        newsinifid: copyclassroomId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log(" copyClassroomsAssignments Error: ", error);
    return { error };
  }
};

//SOCIALCLUBS

export const getSocialClubs = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getklups `,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getSocialClubs Error: ", error);
    return { error: true };
  }
};

export const getSocialClub = async (socialClubId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getsosyalklup`,
      data: {
        id: socialClubId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getSocialClub Error: ", error);
    return { error: true };
  }
};

export const addSocialClub = async (socialClub, teacherId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addsosyalklup`,
      data: {
        klupname: socialClub,
        ogretmenid: teacherId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add SocialClub Error: ", error);
    return { error };
  }
};

export const deleteSocialClub = async (socialClubId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deleteklup`,
      data: {
        id: socialClubId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deleteTeacher Error: ", error);
    return { error: true };
  }
};

export const editSocialClub = async (socialClub, teacherId, socialClubId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/editsosyalkulup`,
      data: {
        klupname: socialClub,
        ogretmenid: teacherId,
        id: socialClubId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit SocialClub Error: ", error);
    return { error };
  }
};

export const getSocialClubPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getsosyalkuluplerfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "SosyalKulüpler.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getSocialClubPdf Error: ", error);
    return { error };
  }
};

///WELLDONES

export const getWelldones = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/welldone`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getWelldones Error: ", error);
    return { error: true };
  }
};

export const getWelldone = async (studentId) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/welldone`,
      data: {
        studentid: studentId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getWelldone Error: ", error);
    return { error: true };
  }
};

export const addWelldone = async (studentId, checks) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/welldone`,
      data: {
        studentid: studentId,
        aferinarray: checks,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add Welldone Error: ", error);
    return { error };
  }
};

export const deleteWelldone = async (studentId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deleteaferin`,
      data: {
        studentid: studentId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deleteWelldone Error: ", error);
    return { error: true };
  }
};

export const editWelldone = async (studentId, checks) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseURL}/welldone/${studentId}`,
      data: checks,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit Welldone Error: ", error);
    return { error };
  }
};

export const getWelldonePdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getaferinfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Aferin.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getWelldonePdf Error: ", error);
    return { error };
  }
};

//ETUDES

export const getEtudes = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getetuts`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getEtudes Error: ", error);
    return { error: true };
  }
};

export const getEtude = async (etudeId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getetut`,
      data: {
        id: etudeId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getEtude Error: ", error);
    return { error: true };
  }
};

export const addEtude = async (
  lectureId,
  teacherId,
  studentId,
  classroomId,
  date,
  time,
  issue
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addetut`,
      data: {
        ders_id: lectureId,
        ogretmen_id: teacherId,
        ogrenci_id: studentId,
        sinif_id: classroomId,
        tarih: date,
        saat: time,
        konu: issue,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add Etude Error: ", error);
    return { error };
  }
};

export const deleteEtude = async (etudeId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deleteetut`,
      data: {
        id: etudeId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deleteEtude Error: ", error);
    return { error: true };
  }
};

export const editEtude = async (
  lectureId,
  teacherId,
  studentId,
  classroomId,
  date,
  time,
  issue,
  etudeId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/editetut`,
      data: {
        ders_id: lectureId,
        ogretmen_id: teacherId,
        ogrenci_id: studentId,
        sinif_id: classroomId,
        tarih: date,
        saat: time,
        konu: issue,
        id: etudeId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit Etude Error: ", error);
    return { error };
  }
};

export const getEtudePdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getetutfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Etütler.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getEtudePdf Error: ", error);
    return { error };
  }
};

//MESSAGES

/*export const getMessages = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getmessages/type=MESSAGE`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getMessages Error: ", error);
    return { error: true };
  }
}; */

/*export const addMessage = async (
  type,
  date,
  message,
  image,
  teacherIds,
  studentIds,
  parentIds
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/postmessages`,
      data: {
        type: type,
        tarih: date,
        ileti: message,
        image: image,
        ogretmenler: teacherIds,
        ogrenciler: studentIds,
        veliler: parentIds,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add Message Error: ", error);
    return { error };
  }
};

export const getMessagePdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getogretmenfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Mesajlar.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getMessagePdf Error: ", error);
    return { error };
  }
};
*/
//ANNOUNCEMENTS

export const getAnnouncements = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/announcement`,
    });

    //console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAnnouncements Error: ", error);
    return { error: true };
  }
};





export const addAnnouncement = async (
  date,
  message,
  resim,
  selectedValues,
  selectedClassrooms,
  userGuid,

) => {
  try {
    var data = new FormData();
    data.append("DuyuruTarihi", "2024-01-01");
    data.append("Mesaj", message);
    data.append("resim", resim);
    data.append("topic", userGuid);


    if (Array.isArray(selectedValues.UserIds)) {
      // userId bir dizi ise, her bir elemanını FormData'ya ekle
      selectedValues.UserIds.forEach((UserIds, index) => {
        data.append(`UserIds[${index}]`, UserIds);
      });
    } else {
      // userId bir dizi değilse, bir hata mesajı yaz
      console.error("Hata: selectedValues.userId bir dizi değil");
      return { error: "selectedValues.userId bir dizi değil" };
    }

    if (Array.isArray(selectedClassrooms.SinifIds)) {
      // SinifId bir dizi ise, her bir elemanını FormData'ya ekle
      selectedClassrooms.SinifIds.forEach((SinifIds, index) => {
        data.append(`SinifIds[${index}]`, SinifIds);
      });
    } else {
      // SinifId bir dizi değilse, bir hata mesajı yaz
      console.error("Hata: selectedClassrooms.SinifId bir dizi değil");
      return { error: "selectedClassrooms.SinifId bir dizi değil" };
    }
    const response = await axios({
      method: "POST",
      url: `${baseURL}/announcement`,

      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response && response.data) {
      console.log(response.data, "response data");
      return response.data;
    } else {
      return { response: "Invalid response structrue" };
    }
  } catch (error) {
    console.log("Add Announcement Error: ", error);
    return { error };
  }
};

export const getAnnouncementPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getogretmenfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Mesajlar.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAnnouncementPdf Error: ", error);
    return { error };
  }
};

//ATTENDANCES

export const getAttendances = async (classroomId, lectureorder, date) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getyoklamastudents`,
      data: {
        sinifid: classroomId,
        kacinciders: lectureorder,
        tarih: date,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAttendances Error: ", error);
    return { error: true };
  }
};







export const getAttendance = async (date, sinifId) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/attendance/?date=${date}&sinifId=${sinifId}`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAttendance Error: ", error);
    return { error: true };
  }
};

export const addAttendance = async (tableData, date, attendance) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/attendance/?yoklamaTarihi=${date}&sinifId=${attendance}`,
      data: tableData,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add Attendance Error: ", error);
    return { error };
  }
};

export const deleteAttendance = async (attendanceId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deleteogretmen`,
      data: {
        id: attendanceId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deleteAttendance Error: ", error);
    return { error: true };
  }
};

export const editAttendance = async (
  nameSurname,
  password,
  unvan,
  brans,
  phone,
  sinif,
  sosyal,
  email,
  attendanceId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/editogretmen`,
      data: {
        nameSurname: nameSurname,
        password: password,
        unvan: unvan,
        brans: brans,
        phone: phone,
        sinif: sinif,
        sosyal: sosyal,
        email: email,
        id: attendanceId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit Attendance Error: ", error);
    return { error };
  }
};

export const getAttendancePdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getogretmenfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Yoklama.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getAttendancePdf Error: ", error);
    return { error };
  }
};

//NOTIFICATIONS

export const getNotification = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getnotification`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("bildirim Error: ", error);
    return { error: true };
  }
};

export const getNotifications = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getnotifications`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("bildirimler Error: ", error);
    return { error: true };
  }
};

//MEALS

export const getMeals = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getyemeklistesis `,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getMeals Error: ", error);
    return { error: true };
  }
};

export const getMeal = async (mealId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getyemeklistesi`,
      data: {
        yemekid: mealId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getMeal Error: ", error);
    return { error: true };
  }
};

export const addMeal = async (date, morning, afternoon, evening) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addyemeklistesi `,
      data: {
        tarih: date,
        sabah: morning,
        oglen: afternoon,
        aksam: evening,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add Meal Error: ", error);
    return { error };
  }
};

export const deleteMeal = async (mealId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deleteyemeklistesi`,
      data: {
        yemekid: mealId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deleteMeal Error: ", error);
    return { error: true };
  }
};

export const editMeal = async (date, morning, afternoon, evening, mealId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/edityemeklistesi `,
      data: {
        tarih: date,
        sabah: morning,
        oglen: afternoon,
        aksam: evening,
        yemekid: mealId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit Meal Error: ", error);
    return { error };
  }
};

export const getMealPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getogretmenfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Öğretmenler.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getMealPdf Error: ", error);
    return { error };
  }
};

//PAYMENTS

export const getPayments = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getetkinliklers`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getPayments Error: ", error);
    return { error: true };
  }
};

export const getPayment = async (paymentId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getetkinlikler`,
      data: {
        etkinlikid: paymentId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getPayment Error: ", error);
    return { error: true };
  }
};
export const getPaymentDetail = async (paymentId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/getetkinliklerodeme`,
      data: {
        etkinlikid: paymentId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getPayment Error: ", error);
    return { error: true };
  }
};

export const addPayment = async (
  paymentname,
  classroomId,
  startDate,
  endDate,
  price,
  place,
  mebfile,
  photo
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/addetkinlik`,
      data: {
        etkinlikname: paymentname,
        sinifid: classroomId,
        baslangictarih: startDate,
        bitistarih: endDate,
        tutar: price,
        yer: place,
        mebfile: mebfile,
        medyafile: photo,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add Payment Error: ", error);
    return { error };
  }
};

export const deletePayment = async (paymentId) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/deleteetkinlik`,
      data: {
        etkinlikid: paymentId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deletePayment Error: ", error);
    return { error: true };
  }
};

export const editPayment = async (
  paymentname,
  classroomId,
  startDate,
  endDate,
  price,
  place,
  mebfile,
  photo,
  paymentId
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/editetkinlik`,
      data: {
        etkinlikname: paymentname,
        sinifid: classroomId,
        baslangictarih: startDate,
        bitistarih: endDate,
        tutar: price,
        yer: place,
        mebfile: mebfile,
        medyafile: photo,
        etkinlikid: paymentId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit Payment Error: ", error);
    return { error };
  }
};

export const getPaymentPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getogretmenfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Öğretmenler.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getPaymentPdf Error: ", error);
    return { error };
  }
};

//EXAMS

export const getExams = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/exam`,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getExams Error: ", error);
    return { error: true };
  }
};

export const getExam = async (examId) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/exam/${examId}`,
      data: {
        id: examId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getExam Error: ", error);
    return { error: true };
  }
};

export const addExam = async (exam) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${baseURL}/exam`,
      data: exam,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Add Exam Error: ", error);
    return { error };
  }
};

export const deleteExam = async (examId) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseURL}/exam/${examId}`,
      data: {
        id: examId,
      },
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("deleteExam Error: ", error);
    return { error: true };
  }
};

export const editExam = async (examId, exam) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${baseURL}/exam/${examId}`,
      data: exam,
    });

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("Edit Exam Error: ", error);
    return { error };
  }
};

export const getExamPdf = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/getogretmenfile`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Sınavlar.pdf"); // set the name of the file
    document.body.appendChild(link);
    link.click();

    console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getExamPdf Error: ", error);
    return { error };
  }
};

// Get Branches

export const getBranches = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseURL}/system/branches`,
    });
    // console.log(response, "response");
    return await response;
  } catch (error) {
    console.log("getBranches Error: ", error);
    return { error: true };
  }
};
