import React, { useState,useEffect } from "react";

import { getAnnouncements } from "../api/general";
import calendaricon from "../assets/calendaricon.svg" 
import moment from "moment";

export const Alert = () => {
 const [announcements, setAnnouncements] = useState([])

 useEffect(() => {
  getAnnouncementMethod();
},[]);



  const getAnnouncementMethod = async () => {
    const response = await getAnnouncements();
    if (response.error) {
      //console.log("Test duyurular gelmedi");
    } else {
      //console.log("Test duyurular geldi");
      setAnnouncements(response.data.result);
     
    }
  };



    return (

      <div style={{ backgroundColor: "white", overflowY: announcements.length > 5 ? "scroll" : "auto", maxHeight: "450px", width: "100%" }}>
      {announcements.slice(0, 5).map((announcement, index) => (
        <div key={index} style={{ marginBottom: "1px" }}>
          <div
            className="alert-container"
            style={{
              background: "#FDEDED",
              border: "0.1rem solid #F16360",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="symbol-container" style={{ marginRight: "10px" }}>
              <img className="symbol" src={calendaricon} alt="Calendar Icon" style={{ width: "30px", marginLeft: "12px", marginBottom: "10px" }} />
              <span className="description-text" style={{ color: "blue", fontWeight: "bold" }}>{moment(announcement.duyuruTarihi).format("DD/MM/YYYY")}</span>

            </div>
            <div className="description-container" style={{ flex: "1",justifyContent: "center"}} >
              <div style={{display: "flex",marginLeft:"10px", alignItems: "center"}}>
              <span className="description-text" style={{  marginLeft: "20px", color: "blue", fontWeight: "bold", fontSize: "1.2rem" }}>{announcement.duyuru}</span>
            </div>
          </div>
        </div>
        </div>
      ))}
    </div>

    );
};