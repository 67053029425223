import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import { useContext } from "react";
import StoreContext from "../../../context/store";
import { getClassroom } from "../../../api/general";
import AddClassroomModal from "../AddClassroomModal";

const ClassroomsTable = ({
  title1 = "-",
  title2 = "Sınıf Adı",
  title3 = "Sınıf Öğretmeni",
  title4 = "Açıklama",
  title5 = "İşlem",
  data,
}) => {
  const {
    setEditClassroomClick,
    editClassroomClick,
    classroomId,
    setClassroomId,
    setClassroom,
  } = useContext(StoreContext);

  const getClassroomMethod = async (classroomId) => {
    const response = await getClassroom(classroomId);
    if (response.error) {
    } else {
      await setClassroom(response.data.result);
    }
  };

  return (
    <div className={style.container}>
      {editClassroomClick && <AddClassroomModal classroomId={classroomId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
      </div>
      {data &&
        data.map((classroom, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{classroom.id}</div>
            <div className={style.data2}>{classroom.sinifName}</div>
            <div className={style.data3}>{classroom.sinifTeacher}</div>
            <div className={style.data4}>{classroom.description}</div>
            <div className={style.data5}>
              <button
                onClick={() => {
                  setClassroomId(classroom.id);
                  Promise.resolve(getClassroomMethod(classroom.id)).then(() => {
                    setEditClassroomClick(true);
                  });
                }}
              >
                <img src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ClassroomsTable;
