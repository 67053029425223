import { Outlet, Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import StoreContext from "../context/store";
import TeacherPage from "../components/Teacher/TeacherPage"
import StudentPage from "../components/Student/StudentPage";

const PrivateRoutes = () => {
  const { isAuth, controlToken, isLoading, userRole } = useContext(StoreContext);


  useEffect(() => {
    controlToken();
  }, []);

  if (isLoading) {
    return <div className="">Loading...</div>;
  }

  return userRole ? (
    <>
      {userRole === "Admin" && "Admin Page"}
      {userRole === "Manager" && <Outlet/>}
      {userRole === "Teacher" && <TeacherPage/>}
      {userRole === "Student" && <StudentPage/>}
    </>
  ) : (
    <Navigate to="/giris" replace={true} />
  );
};

export default PrivateRoutes;
