import React, { useState, useEffect } from 'react';
import {toast,Toaster} from 'react-hot-toast';
import { onMessageListener } from './firebase';
import NotificationService from './NotificationService';

const Notification = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });



  useEffect(() => {
    const unsubscribe = onMessageListener().then(payload => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body
      });

      toast.success(
        `${payload?.notification?.title}: ${payload?.notification?.body}`,
        {
          duration:60000,
          position:"top-center",
          icon:'i',
          style: {
            backgroundColor: "#00FF00", // Başarı rengi
            color: "#ffffff",
            borderRadius: "10px",
            padding: "10px",
            animation: "my-toast-animation 2s ease-in-out infinite",
          },

        }
      )
     
    })
    return () => {
      unsubscribe.catch(err => console.log("failed", err))
    }

  }, []);



  NotificationService.requestPermission(); // Kapanış ayracı eklendi

  return (
    <div>
    <Toaster />
    </div>
  );
};

export default Notification;
