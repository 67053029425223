import { useEffect, useState } from "react";
import { getAttendance } from "../api/general";
import style from "../styles/Attendances.module.css";
import AttendancesTable from "../components/Attendance/AttendancesTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Attendance/AttendanceBar";
import AddAttendanceModal from "../components/Attendance/AddAttendanceModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Attendances = () => {
  const { addAttendanceClick, attendanceInputValue,attendances} = useContext(StoreContext);


  return (
    <div className={style.page}>
      {addAttendanceClick && <AddAttendanceModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <AttendancesTable
            data={attendances}
          />
        </div>
      </div>
    </div>
  );
};

export default Attendances;
