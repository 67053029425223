import { useEffect, useState } from "react";
import { getEtudes } from "../api/general";
import style from "../styles/Etudes.module.css";
import EtudesTable from "../components/Etude/EtudesTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Etude/EtudeBar";
import AddEtudeModal from "../components/Etude/AddEtudeModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Etudes = () => {
  const { addEtudeClick, etudeInputValue } =
    useContext(StoreContext);

  const [etudes, setEtudes] = useState([]);

  useEffect(() => {
    getEtudesMethod();
    console.log(etudes, "etudes");
    console.log(etudeInputValue, "etudeInputValue");
  }, []);

  const getEtudesMethod = async () => {
    const response = await getEtudes();
    if (response.error) {
      console.log("Test Etütler gelmedi");
    } else {
      console.log("Test Etütler geldi");
      setEtudes(response.data);
    }
  };

  return (
    <div className={style.page}>
      {addEtudeClick && <AddEtudeModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <EtudesTable
            data={etudes?.filter(
              (el) =>
                el.ders_name
                  .toLowerCase()
                  .includes(etudeInputValue.toLowerCase()) ||
                el.ogretmen_name
                  .toLowerCase()
                  .includes(etudeInputValue.toLowerCase()) ||
                el.ogrenci_name.toLowerCase().includes(etudeInputValue.toLowerCase())
                ||
                el.sinifname.toLowerCase().includes(etudeInputValue.toLowerCase())
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Etudes;
