export const directives = [
    {
        id:1,
        dirtective:"Derslerin dağıtımı sırasında değişmesini istemediğiniz dersler var ise, bu saateri de dersleri manuel olarak atadıktan sonra kitleyebilirsiniz."
    },
    {
        id:2,
        dirtective:"Temizle butonu kilitli saatleri kaldırmayacaktır. Yerine bir öğretmene Veli Görüşmesi saati atamasını öğretmen ders programı tablosu üzerinde, dilediğiniz öğretmenin programındaki boş bir saatini seçerek klavyenizdeki V tuşu ile yapabilirsiniz."
    },
    {
        id:3,
        dirtective:"Dağıtımda Bir Öğretmen İçin Günlük En Fazla Boşluk ve Çalışma Süresi(sn) seçeneklerini ayarlar düğmesinden değiştirebilirsiniz. Daha fazla boşluk ve Çalışma süresini arttırmak programın yerleşmesini kolaylaştıracaktır."
    },
]

export const initialClassRoom = {
    sinifName: "",
    sinifTeacher: "",
    description: "",
    seviye: "",
    sube: "",
    classLessons: [
      {
        weekOfDay: 0,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
        ]
      },
      {
        weekOfDay: 1,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
        ]
      },
      {
        weekOfDay: 2,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
        ]
      },
      {
        weekOfDay: 3,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
        ]
      },
      {
        weekOfDay: 4,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
        ]
      },
      {
        weekOfDay: 5,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
        ]
      },
      {
        weekOfDay: 6,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          }, {
            start: "",
            end: ""
          },
          {
            start: "",
            end: ""
          },
        ]
      }
    ]
  }