import style from "./style.module.css";
import lectureinfo from "../../../assets/modalIcons/lectureinfo.svg";
import cross from "../../../assets/modalIcons/cross.svg";
import deleteicon from "../../../assets/modalIcons/delete.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { deleteEtude, editEtude } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import { useEffect } from "react";
import { useRef } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import dropdown from "../../../assets/arrowdown.svg";
import { getClassrooms, getTeachers } from "../../../api/general";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import calendar from "../../../assets/calendaricon.svg";
import clock from "../../../assets/clockicon.svg";
import { getLectures } from "../../../api/general";
import { getStudents } from "../../../api/general";

const EditEtudeModal = ({ etudeId }) => {
  const { setEditEtudeClick, etude } = useContext(StoreContext);

  const [classroom, setClassroom] = useState(etude.sinifname);
  const [classroomId, setClassroomId] = useState(etude.sinif_id);

  const [teacher, setTeacher] = useState(etude.ogretmen_name);
  const [teacherId, setTeacherId] = useState(etude.ogretmen_id);

  const [lecture, setLecture] = useState(etude.ders_name);
  const [lectureId, setLectureId] = useState(etude.ders_id);

  const [student, setStudent] = useState(etude.ogrenci_name);
  const [studentId, setStudentId] = useState(etude.ogrenci_id);

  const [issue, setIssue] = useState(etude.konu);

  const [etudedate, setEtudeDate] = useState(new Date(etude.tarih));
  const [etudetime, setEtudeTime] = useState(new Date(etude.saat));

  // FOR CLASSROOM DROPDOWN

  const [optionArea2, setOptionArea2] = useState(false);
  const buttonRef2 = useRef(null);

  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassroomsMethod();
  }, []); //

  // FOR CLASSROOM DROPDOWN

  useClickOutside(buttonRef2, () => {
    setOptionArea2(false);
  });

  const handleToggle2 = () => {
    setOptionArea2(!optionArea2);
  };

  const handleClassroomSelect = (event) => {
    setClassroom(event.target.value.split(",")[0]);
    setClassroomId(event.target.value.split(",")[1]);
    setOptionArea2(false);
  };

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
    }
  }; //

  // FOR TEACHER DROPDOWN

  const [optionArea, setOptionArea] = useState(false);
  const buttonRef = useRef(null);

  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    getTeachersMethod();
  }, []); //

  // FOR TEACHER DROPDOWN

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const handleTeacherSelect = (event) => {
    setTeacher(event.target.value.split(",")[0]);
    setTeacherId(event.target.value.split(",")[1]);
    setOptionArea(false);
  };

  const getTeachersMethod = async () => {
    const response = await getTeachers();
    if (response.error) {
      console.log("Test öğretmenler gelmedi");
    } else {
      console.log("Test öğretmenler geldi");
      setTeachers(response.data);
    }
  }; //

  // FOR LECTURE DROPDOWN

  const [optionArea3, setOptionArea3] = useState(false);
  const buttonRef3 = useRef(null);

  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    getLecturesMethod();
  }, []); //

  // FOR LECTURE DROPDOWN

  useClickOutside(buttonRef3, () => {
    setOptionArea3(false);
  });

  const handleToggle3 = () => {
    setOptionArea3(!optionArea3);
  };

  const handleLectureSelect = (event) => {
    setLecture(event.target.value.split(",")[0]);
    setLectureId(event.target.value.split(",")[1]);
    setOptionArea3(false);
  };

  const getLecturesMethod = async () => {
    const response = await getLectures();
    if (response.error) {
      console.log("Test dersler gelmedi");
    } else {
      console.log("Test dersler geldi");
      setLectures(response.data);
    }
  }; //

  // FOR STUDENT DROPDOWN

  const [optionArea4, setOptionArea4] = useState(false);
  const buttonRef4 = useRef(null);

  const [students, setStudents] = useState([]);

  useEffect(() => {
    getStudentsMethod();
  }, []); //

  // FOR STUDENT DROPDOWN

  useClickOutside(buttonRef4, () => {
    setOptionArea4(false);
  });

  const handleToggle4 = () => {
    setOptionArea4(!optionArea4);
  };

  const handleStudentSelect = (event) => {
    setStudent(event.target.value.split(",")[0]);
    setStudentId(event.target.value.split(",")[1]);
    setOptionArea4(false);
  };

  const getStudentsMethod = async () => {
    const response = await getStudents();
    if (response.error) {
      console.log("Test dersler gelmedi");
    } else {
      console.log("Test dersler geldi");
      setStudents(response.data);
    }
  }; //




  const deleteEtudeMethod = async () => {
    const response = await deleteEtude(etudeId);
    if (response.error) {
      console.log("Etüt silme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Etüt silinemedi");
    } else {
      console.log("Etüt silme başarılı");
      successMessage("Etüt silindi");
      setEditEtudeClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  const editEtudeMethod = async () => {
    if (
      lecture === "" ||
      teacher === "" ||
      student === "" ||
      classroom === "" ||
      etudedate === "" ||
      etudetime === "" ||
      issue === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await editEtude(
      lectureId,
      teacherId,
      studentId,
      classroomId,
      etudedate,
      etudetime,
      issue,
      etudeId
    );
    if (response.error) {
      console.log("Etüt güncelleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Etüt güncellenemedi");
    } else {
      console.log("Etüt güncelleme başarılı");
      successMessage("Etüt güncellendi");
      setEditEtudeClick(false);

    setTimeout(function () {
        window.location.reload();
      }, 2500); 
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button className={style.tabicon1}>
                <img alt="" src={lectureinfo}></img>
              </button>
              <div className={style.tabtext1}>Etüt Bilgileri</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setEditEtudeClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Yer</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{classroom}</div>
              <button onClick={handleToggle2}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea2 && (
                <div className={style.optionarea} ref={buttonRef2}>
                  <div>
                    {classrooms &&
                      classrooms.map((classroom, index) => (
                        <button
                          onClick={handleClassroomSelect}
                          value={[classroom.sinifname, classroom.id]}
                          key={index}
                          className={style.option}
                        >
                          {classroom.sinifname}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Öğretmen</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{teacher}</div>
              <button onClick={handleToggle}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea && (
                <div className={style.optionarea} ref={buttonRef}>
                  <div>
                    {teachers &&
                      teachers.map((teacher, index) => (
                        <button
                          onClick={handleTeacherSelect}
                          value={[teacher.nameSurname, teacher.id]}
                          key={index}
                          className={style.option}
                        >
                          {teacher.nameSurname}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>



          <div className={style.input}>
            <div className={style.inputtitle}>Öğrenci</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{student}</div>
              <button onClick={handleToggle4}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea4 && (
                <div className={style.optionarea} ref={buttonRef4}>
                  <div>
                    {students &&
                      students.map((student, index) => (
                        <button
                          onClick={handleStudentSelect}
                          value={[student.nameSurname, student.id]}
                          key={index}
                          className={style.option}
                        >
                          {student.nameSurname}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>





          <div className={style.input}>
            <div className={style.inputtitle}>Ders</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{lecture}</div>
              <button onClick={handleToggle3}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea3 && (
                <div className={style.optionarea} ref={buttonRef3}>
                  <div>
                    {lectures &&
                      lectures.map((lecture, index) => (
                        <button
                          onClick={handleLectureSelect}
                          value={[lecture.dersname, lecture.id]}
                          key={index}
                          className={style.option}
                        >
                          {lecture.dersname}
                        </button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Konu</div>
            <input
              className={style.inputtext}
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            ></input>
          </div>
          <div className={style.input2}>
            <div className={style.inputtitle}>Tarih</div>
            <div className={style.inputtext}>
              <DatePicker
                className="bg-transparent border-0 outline-none pt-1 w-full "
                selected={etudedate}
                onChange={(date) => setEtudeDate(date)}
                dateFormat="dd/MM/yyyy"
                locale={tr}
                showPopperArrow={false}
                timeZone="UTC+3"
              />

              <img src={calendar} className={style.icon}></img>
            </div>
          </div>
          <div className={style.input2}>
            <div className={style.inputtitle}>Saat</div>
            <div className={style.inputtext}>
              <DatePicker
                 className="bg-transparent border-0 outline-none pt-1 w-full"
                 selected={etudetime}
                 onChange={(time) => setEtudeTime(time)}
                 timeFormat="HH:mm"
                 showTimeSelect
                 showTimeSelectOnly
                 timeIntervals={10}
                 timeCaption="Saat"
                 dateFormat="HH:mm"
                 showPopperArrow={false}
                 locale={tr}
                 timeZone="UTC+3"
              />

              <img src={clock} className={style.icon}></img>
            </div>
          </div>
          
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button
                className={style.tabicon1}
                onClick={() => {
                  deleteEtudeMethod();
                }}
              >
                <img alt="" src={deleteicon}></img>
              </button>
              <div className={style.tabtext1}>Sil</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  editEtudeMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEtudeModal;
