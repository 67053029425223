import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { addExam, editExam, deleteExam } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from "../../../assets/calendaricon.svg";
import clock from "../../../assets/clockicon.svg";
import tr from "date-fns/locale/tr";
import { useEffect } from "react";
import { getLecturesDD, getClassnamesDD } from "../../../api/general";
import { useRef } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import dropdown from "../../../assets/arrowdown.svg";
import Select from "react-select";
import deleteicon from "../../../assets/modalIcons/delete.svg";

const AddExamModal = ({ examId }) => {
  const { setAddExamClick, setEditExamClick, exam, setExam } =
    useContext(StoreContext);

  const [date, setDate] = useState(new Date());

  const [time, setTime] = useState(new Date());

  const [lecture, setLecture] = useState("");
  const [lectureId, setLectureId] = useState("");

  const [classroom, setClassroom] = useState(exam.dersAdi);
  const [classroomId, setClassroomId] = useState("");

  const [examType, setExamType] = useState(exam.sinavSekli);

  const [examTypes, setExamTypes] = useState([
    { label: "Klasik", value: "Klasik" },
    { label: "Çoktan Seçmeli", value: "Çoktan Seçmeli" },
    { label: "Konu Tarama", value: "Konu Tarama" },
    { label: "Quiz", value: "Quiz" },
    { label: "Genel Deneme", value: "Genel Deneme" },
    { label: "Karma", value: "Karma" },
  ]);


  // FOR CLASSROOM DROPDOWN

  const [optionArea2, setOptionArea2] = useState(false);
  const buttonRef2 = useRef(null);

  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassroomsMethod();
  }, []); //

  // FOR CLASSROOM DROPDOWN

  useClickOutside(buttonRef2, () => {
    setOptionArea2(false);
  });

  const getClassroomsMethod = async () => {
    const response = await getClassnamesDD();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
    }
  };

  // FOR LECTURE DROPDOWN

  const [optionArea3, setOptionArea3] = useState(false);
  const buttonRef3 = useRef(null);

  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    getLecturesMethod();
  }, []); //

  // FOR LECTURE DROPDOWN

  useClickOutside(buttonRef3, () => {
    setOptionArea3(false);
  });

  const getLecturesMethod = async () => {
    const response = await getLecturesDD();
    if (response.error) {
      console.log("Test dersler gelmedi");
    } else {
      console.log("Test dersler geldi");
      setLectures(response.data);
    }
  }; //

  // FOR DROPDOWN1

  const [optionArea, setOptionArea] = useState(false);
  const buttonRef = useRef(null);

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const handleExamTypeSelect = (event) => {
    setExamType(event.target.value);
    setOptionArea(false);
  }; //

  const addExamMethod = async () => {
    if (
      exam.sinavTarihi === "" ||
      exam.sinavSaati === "" ||
      exam.sinavSekli === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }
    let response;
    let message;
    if (examId !== undefined) {
      response = await editExam(examId, exam);
      message = "düzenleme";
    } else {
      response = await addExam(exam);
      message = "ekleme";
    }
    if (response.error) {
      errorMessage(`sınıf ${message} başarısız `);
    } else {
      successMessage(`Sınav ${message} başarılı`);
      setAddExamClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  //Exam

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    return (
      selectedDate.getDate() +
      "/" +
      parseInt(selectedDate.getMonth() + 1) +
      "/" +
      selectedDate.getFullYear()
    );
  };

  const formatTimeFn = (time) => {
    const selectedTime = new Date(time);
    return (
      selectedTime.getHours() +
      ":" +
      parseInt(selectedTime.getMinutes() + ":" + selectedTime.getSeconds())
    );
  };

  const setVariable = (varableName, value) => {
    let temp = Object.assign({}, exam);
    temp[varableName] = value;
    setExam(temp);
    console.log("seçilenler", temp);
  };

  const closeModal = () => {
    if (examId !== undefined) setEditExamClick(false);
    else setAddExamClick(false);
  };

  const deleteExamMethod = async () => {
    const response = await deleteExam(examId);
    if (response.error) {
      errorMessage("Ders silinemedi");
    } else {
      successMessage("Ders silindi");
      setEditExamClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon} onClick={closeModal}>
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Ders Adı</div>
            <Select
              value={lecture}
              className={style.inputtext}
              onChange={(e) => {
                setLecture(e);
                setVariable("dersAdi", e.key);
              }}
              getOptionValue={(option) => option.key}
              getOptionLabel={(option) => option.value}
              options={lectures}
              placeholder="Ders Seçimi..."
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: "1px solid #3733bf",
                  backgroundColor: "#EEF8FF",
                  width: "102%",
                  height: "30px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  margin: "-6px",
                  marginLeft: "-9px",
                }),
              }}
            />
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Sınıf</div>
            <Select
              value={classroom}
              className={style.inputtext}
              onChange={(e) => {
                setClassroom(e);
                setVariable("sinifAdi", e.key);
              }}
              getOptionValue={(option) => option.key}
              getOptionLabel={(option) => option.value}
              options={classrooms}
              placeholder="Sınıf Seçimi..."
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: "1px solid #3733bf",
                  backgroundColor: "#EEF8FF",
                  width: "102%",
                  height: "30px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  margin: "-6px",
                  marginLeft: "-9px",
                }),
              }}
            />
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Sınav Tarihi</div>
            <div className={style.inputtext}>
              <DatePicker
                className="bg-transparent border-0 outline-none pt-1 w-full "
                selected={date}
                value={exam.sinavTarihi ?? ""}
                onChange={(date) => {
                  setDate(date);
                  setVariable("sinavTarihi", formatDateFn(date));
                }}
                dateFormat="dd/MM/yyyy"
                locale={tr}
                showPopperArrow={false}
                timeZone="UTC+3"
              />

              <img src={calendar} className={style.icon}></img>
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Sınav Şekli</div>
            <Select
              className={style.inputtext}
              value={examType}
              onChange={(e) => {
                setExamType(e);
                setVariable("sinavSekli", e.value);
              }}
              options={examTypes}
              placeholder="Sınav Tipi..."
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: "1px solid #3733bf",
                  backgroundColor: "#EEF8FF",
                  width: "102%",
                  height: "30px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  margin: "-6px",
                  marginLeft: "-9px",
                }),
              }}
            />
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Sınav Saati</div>
            <div className={style.inputtext}>
              <DatePicker
                className="bg-transparent border-0 outline-none pt-1 w-full"
                selected={time}
                value={exam.sinavSaati ?? ""}
                onChange={(time) => {
                  setTime(time);
                  setVariable("sinavSaati", formatTimeFn(time));
                }}
                timeFormat="HH:mm"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={10}
                timeCaption="Saat"
                dateFormat="HH:mm"
                showPopperArrow={false}
                locale={tr}
                timeZone="UTC+3"
              />

              <img src={clock} className={style.icon}></img>
            </div>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              {examId !== undefined && (
                <div className={style.tab1}>
                  <button
                    className={style.tabicon}
                    onClick={() => {
                      deleteExamMethod();
                    }}
                  >
                    <img alt="" src={deleteicon}></img>
                  </button>
                  <div className={style.tabtext1}>Sil</div>
                </div>
              )}
            </div>
          </div>
          <div className={style.lowertabs}>
            <div className={style.tableftside}></div>
            <div className={style.tabrightside}>
              <div className={style.tab}>
                <button
                  className={style.tabicon}
                  onClick={() => {
                    addExamMethod();
                  }}
                >
                  <img alt="" src={save}></img>
                </button>
                <div className={style.tabtext}>Kaydet</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExamModal;
