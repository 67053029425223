import style from "./style.module.css";

import tick from "../../../../assets/modalIcons/tick.svg";
import { useContext } from "react";
import StoreContext from "../../../../context/store";
import cross from "../../../../assets/modalIcons/cross.svg"

import { directives } from "../../../../constants";
import directive from "../../../../assets/modalIcons/directives.svg"

const DirectivesModal = () => {

    console.log(directives);

    const { setDirectivesModal } =
        useContext(StoreContext);
    return (
        <div className={style.modal}>
            <div className={style.modalbox}>
                <div className={style.uppertabs}>
                    <div className={style.tableftside}>
                        <div className={style.tab}>
                            <button className={style.tabicon_left}>
                                <img alt="" src={directives}></img>
                            </button>
                            <div className={style.tabtext_left}>Yönergeler </div>
                        </div>
                    </div>
                    <div className={style.tabrightside}>
                        <div className={style.tab}>
                            <button className={style.tabicon_right} onClick={() => {
                                setDirectivesModal(false);
                            }}>
                                <img alt="" src={cross}></img>
                            </button>
                            <div className={style.tabtext_right}>Kapat</div>
                        </div>
                    </div>
                </div>
                <div className={style.contents}>
                    {directives.map((item,key)=>{
                        return(
                            <div className={`${style.directive} ${item.id %2 == 0 ?style.even:style.odd}`} key={key}>{item.dirtective}</div>
                        )
                        
                    })}
                    
                </div>
                <div className={style.lowertabs}>
                    <div className={style.tableftside}></div>
                    <div className={style.tabrightside}>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DirectivesModal;