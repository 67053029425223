import style from "../styles/Register.module.css";
import logo from "../assets/logo.svg";
import okulumlogo from "../assets/okulumlogo.svg";
import { generalRegister } from "../api/general";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorMessage, successMessage, warningMessage } from "../helpers/toast";

const Register = () => {
  const [nameSurname, setNamesurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const registerMethod = async () => {
    if (email === "" || nameSurname === "" || password === "") {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await generalRegister(email, nameSurname, password);
    if (response.error) {
      response.error.response.data.errors.forEach(element => {
        errorMessage(element);
      });
    } else {
      successMessage("Kayıt başarılı");
      navigate("/giris");
    }
  };

  return (
    <div className={style.page}>
      <div className={style.container}>
        <img src={logo} alt="logo" className={style.logo}></img>
        <img src={okulumlogo} alt="okulumlogo"></img>
        <div className={style.inputs}>
          <input
            placeholder="Ad Soyad"
            className={style.input}
            value={nameSurname}
            onChange={(e) => setNamesurname(e.target.value)}
          ></input>
          <input
            placeholder="Kullanıcı E-mail"
            className={style.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            placeholder="Şifre"
            className={style.input}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>

        <button
          className={style.button}
          onClick={() => {
            registerMethod();
          }}
        >
          Kayıt
        </button>
      </div>
    </div>
  );
};

export default Register;
