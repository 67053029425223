import { useEffect, useState } from "react";
import { getBranches, getTeachers } from "../api/general";
import style from "../styles/Teachers.module.css";
import TeachersTable from "../components/Teacher/TeachersTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Teacher/TeacherBar";
import AddTeacherModal from "../components/Teacher/AddTeacherModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Teachers = () => {
  const { addTeacherClick, teacherInputValue } =
    useContext(StoreContext);

  const [teachers, setTeachers] = useState([]);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    getTeachersMethod();
    getBranchesMethod();
  }, []);

  const getTeachersMethod = async () => {
    const response = await getTeachers();
    if (response.error) {
    } else {
      setTeachers(response.data.result);
    }
  };
  const getBranchesMethod = async () => {
    const response = await getBranches();
    if (response.error) {
    } else {
      setBranches(response.data);
    }
  };

  return (
    <div className={style.page}>
      {addTeacherClick && <AddTeacherModal branches={branches} />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <TeachersTable
            branches={branches}
            data={teachers?.filter(
              (el) =>
                el.nameSurname
                  .toLowerCase()
                  .includes(teacherInputValue.toLowerCase()) ||
                el.brans
                  .toLowerCase()
                  .includes(teacherInputValue.toLowerCase()) ||
                el.unvan.toLowerCase().includes(teacherInputValue.toLowerCase())
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Teachers;
