import { useEffect, useState } from "react";
import { getLectures } from "../api/general";
import style from "../styles/Lectures.module.css";
import LecturesTable from "../components/Lecture/LecturesTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Lecture/LectureBar";
import AddLectureModal from "../components/Lecture/AddLectureModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Lectures = () => {
  const { addLectureClick } = useContext(StoreContext);

  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    getLecturesMethod();
  }, []);

  const getLecturesMethod = async () => {
    const response = await getLectures();
    if (response.error) {
    } else {
      setLectures(response.data.result);
    }
  };

  return (
    <div className={style.page}>
      {addLectureClick && <AddLectureModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <LecturesTable data={lectures} />
        </div>
      </div>
    </div>
  );
};

export default Lectures;
