import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import dropdown from "../../../assets/arrowdown.svg";
import { useContext, useState, useRef } from "react";
import StoreContext from "../../../context/store";
import { addSchoolroom } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import useClickOutside from "../../../hooks/useClickOutside";

const AddSchoolroomModal = () => {
  const { setAddSchoolroomClick } = useContext(StoreContext);

  const [schoolroomname, setSchoolroomname] = useState("");
  const [shortschoolroomname, setShortschoolroomname] = useState("");
  const [classroom, setClassroom] = useState("");
  const [placement, setPlacement] = useState("");
  const [xstudentcount, setXstudentcount] = useState("");
  const [ystudentcount, setYstudentcount] = useState("");

  // FOR DROPDOWN1

  const [optionArea, setOptionArea] = useState(false);
  const buttonRef = useRef(null);

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  const handlePlacementSelect = (event) => {
    setPlacement(event.target.value);
    setOptionArea(false);
  }; //

  const addSchoolroomMethod = async () => {
    if (
      schoolroomname === "" ||
      shortschoolroomname === "" ||
      placement === "" ||
      xstudentcount === "" ||
      ystudentcount === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await addSchoolroom(
      schoolroomname,
      shortschoolroomname,
      classroom,
      placement,
      xstudentcount,
      ystudentcount
    );
    if (response.error) {
      console.log("Derslik ekleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Derslik ekleme başarısız");
    } else {
      console.log("Derslik ekleme başarılı");
      successMessage("Derslik ekleme başarılı");
      setAddSchoolroomClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setAddSchoolroomClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Derslik Adı</div>
            <input
              className={style.inputtext}
              value={schoolroomname}
              onChange={(e) => setSchoolroomname(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Kısa Adı</div>
            <input
              className={style.inputtext}
              value={shortschoolroomname}
              onChange={(e) => setShortschoolroomname(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Tekli - Çiftli Oturma</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{placement}</div>
              <button onClick={handleToggle}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea && (
                <div className={style.optionarea} ref={buttonRef}>
                  <div>
                    <button
                      onClick={handlePlacementSelect}
                      value="Tekli"
                      className={style.option}
                    >
                      Tekli
                    </button>
                    <button
                      onClick={handlePlacementSelect}
                      value="Çiftli"
                      className={style.option}
                    >
                      Çiftli
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Yatay Öğrenci Sayısı</div>
            <input
              className={style.inputtext}
              value={xstudentcount}
              onChange={(e) => setXstudentcount(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Dikey Öğrenci Sayısı</div>
            <input
              className={style.inputtext}
              value={ystudentcount}
              onChange={(e) => setYstudentcount(e.target.value)}
            ></input>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  addSchoolroomMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSchoolroomModal;
