import style from "./style.module.css";

import { useContext } from "react";
import StoreContext from "../../../context/store";

const SecondBar = () => {
  const { selectedPaymentSection, setSelectedPaymentSection } =
    useContext(StoreContext);

  const styleSection = (section) => {
    if (selectedPaymentSection === section) {
      return style.ssection;
    } else {
      return style.section;
    }
  };

  return (
    <div className={style.container}>
      <button
        onClick={() => {
          setSelectedPaymentSection("1");
        }}
        className={styleSection("1")}
      >
        Tüm Etkinlikler
      </button>
      <button
        onClick={() => {
          setSelectedPaymentSection("2");
        }}
        className={styleSection("2")}
      >
        Aktif Olan Etkinlikler
      </button>
      <button
        onClick={() => {
          setSelectedPaymentSection("3");
        }}
        className={styleSection("3")}
      >
        Biten Etkinlikler
      </button>
    </div>
  );
};

export default SecondBar;
