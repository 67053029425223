import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import EditMealModal from "../EditMealModal";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getMeal } from "../../../api/general";

const MealsTable = ({
  title1 = "Tarih",
  title2 = "Kahvaltı",
  title3 = "Öğle Yemeği",
  title4 = "Akşam Yemeği",
  title5 = "İşlem",

  data,
}) => {
  const { setEditMealClick, editMealClick, mealId, setMealId, setMeal } =
    useContext(StoreContext);

  useEffect(() => {
    console.log("mealId", mealId);
  }, [mealId]);

  const getMealMethod = async (mealId) => {
    const response = await getMeal(mealId);
    if (response.error) {
      console.log("Yemekin bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setMeal(response.data[0]);
      console.log("Yemekin bilgileri geldi");
    }
  };

  return (
    <div className={style.container}>
      {editMealClick && <EditMealModal mealId={mealId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
      </div>
      {data &&
        data.map((meal, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{meal.tarih}</div>
            <div className={style.data2}>{meal.sabah}</div>
            <div className={style.data3}>{meal.oglen}</div>
            <div className={style.data4}>{meal.aksam}</div>

            <div className={style.data5}>
              <button
                onClick={() => {
                  setMealId(meal.id);
                  Promise.resolve(getMealMethod(meal.id)).then(() => {
                    setEditMealClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default MealsTable;
