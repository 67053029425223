import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getExam } from "../../../api/general";
import AddExamModal from "../AddExamModal";

const ExamsTable = ({
  title1 = "-",
  title2 = "Ders Adı",
  title3 = "Sınıf",
  title4 = "Sınav Tarihi",
  title5 = "Sınav Şekli",
  title6 = "Sınav Saati",
  title7 = "İşlem",
  data,
}) => {
  const { setEditExamClick, editExamClick, examId, setExamId, setExam } =
    useContext(StoreContext);

  useEffect(() => {
    console.log("examId", examId);
  }, [examId]);

  const getExamMethod = async (examId) => {
    const response = await getExam(examId);
    if (response.error) {
      console.log("Sınavin bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setExam(response.data.result);
      console.log("Sınavin bilgileri geldi");
    }
  };

  return (
    <div className={style.container}>
      {editExamClick && <AddExamModal examId={examId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
        <div className={style.title7}>{title7}</div>
      </div>
      {data &&
        data.map((exam, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{exam.id}</div>
            <div className={style.data2}>{exam.dersAdi}</div>
            <div className={style.data3}>{exam.sinifAdi}</div>
            <div className={style.data4}>{exam.sinavTarihi}</div>
            <div className={style.data5}>{exam.sinavSekli}</div>
            <div className={style.data6}>{exam.sinavSaati}</div>
            <div className={style.data7}>
              <button
                onClick={() => {
                  setExamId(exam.id);
                  Promise.resolve(getExamMethod(exam.id)).then(() => {
                    setEditExamClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ExamsTable;
