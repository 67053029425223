import style from "./style.module.css";
import lectureinfo from "../../../assets/modalIcons/derslikler.svg";
import cross from "../../../assets/modalIcons/cross.svg";
import deleteicon from "../../../assets/modalIcons/delete.svg";
import save from "../../../assets/modalIcons/save.svg";
import dropdown from "../../../assets/arrowdown.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import { useContext, useState, useRef } from "react";
import StoreContext from "../../../context/store";
import { deleteSchoolroom, editSchoolroom } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import useClickOutside from "../../../hooks/useClickOutside";

const EditSchoolroomModal = ({ schoolroomId }) => {
  const { setEditSchoolroomClick, schoolroom } = useContext(StoreContext);

  

  const [schoolroomname, setSchoolroomname] = useState(schoolroom.derslikname);
  const [shortschoolroomname, setShortschoolroomname] = useState(schoolroom.kisaad);
  const [classroom, setClassroom] = useState(schoolroom.sinif); 
  const [placement, setPlacement] = useState(schoolroom.tekcift);
  const [xstudentcount, setXstudentcount] = useState(schoolroom.yatay);
  const [ystudentcount, setYstudentcount] = useState(schoolroom.dikey);

   // FOR DROPDOWN1

   const [optionArea, setOptionArea] = useState(false);
   const buttonRef = useRef(null);
 
   useClickOutside(buttonRef, () => {
     setOptionArea(false);
   });
 
   const handleToggle = () => {
     setOptionArea(!optionArea);
   };
 
   const handlePlacementSelect = (event) => {
     setPlacement(event.target.value);
     setOptionArea(false);
   }; //

  

  const deleteSchoolroomMethod = async () => {
    const response = await deleteSchoolroom(schoolroomId);
    if (response.error) {
      console.log("Derslik silme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Derslik silinemedi");
    } else {
      console.log("Derslik silme başarılı");
      successMessage("Derslik silindi");
      setEditSchoolroomClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  const editSchoolroomMethod = async () => {
    if (
       schoolroomname === "" ||
      shortschoolroomname === "" ||
      placement === "" ||
      xstudentcount === "" ||
      ystudentcount === "" 
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await editSchoolroom(
      schoolroomId,
      schoolroomname,
      shortschoolroomname,
      classroom,
      placement,
      xstudentcount,
      ystudentcount,
     
    );
    if (response.error) {
      console.log("Derslik güncelleme başarısız");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Derslik güncellenemedi");
    } else {
      console.log("Derslik güncelleme başarılı");
      successMessage("Derslik güncellendi");
      setEditSchoolroomClick(false);

      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button className={style.tabicon1}>
                <img alt="" src={lectureinfo}></img>
              </button>
              <div className={style.tabtext1}>Derslik Bilgileri</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setEditSchoolroomClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
         <div className={style.inputs}>
          <div className={style.input}>
            <div className={style.inputtitle}>Derslik Adı</div>
            <input
              className={style.inputtext}
              value={schoolroomname}
              onChange={(e) => setSchoolroomname(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Kısa Adı</div>
            <input
              className={style.inputtext}
              value={shortschoolroomname}
              onChange={(e) => setShortschoolroomname(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Tekli - Çiftli Oturma</div>
            <div className={style.dropdown}>
              <div className={style.selectedoption}>{placement}</div>
              <button onClick={handleToggle}>
                <img src={dropdown} alt=""></img>
              </button>

              {optionArea && (
                <div className={style.optionarea} ref={buttonRef}>
                  <div>
                    <button
                      onClick={handlePlacementSelect}
                      value="Tekli"
                      className={style.option}
                    >
                      Tekli
                    </button>
                    <button
                      onClick={handlePlacementSelect}
                      value="Çiftli"
                      className={style.option}
                    >
                      Çiftli
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Yatay Öğrenci Sayısı</div>
            <input
              className={style.inputtext}
              value={xstudentcount}
              onChange={(e) => setXstudentcount(e.target.value)}
            ></input>
          </div>
          <div className={style.input}>
            <div className={style.inputtitle}>Dikey Öğrenci Sayısı</div>
            <input
              className={style.inputtext}
              value={ystudentcount}
              onChange={(e) => setYstudentcount(e.target.value)}
            ></input>
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}>
            <div className={style.tab1}>
              <button
                className={style.tabicon1}
                onClick={() => {
                  deleteSchoolroomMethod();
                }}
              >
                <img alt="" src={deleteicon}></img>
              </button>
              <div className={style.tabtext1}>Sil</div>
            </div>
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  editSchoolroomMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSchoolroomModal;
