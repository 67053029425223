import style from "./style.module.css";
import search from "../../../../assets/search.svg";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../../context/store";

import {
  getClassrooms,
  getClassroomLectureAssigns,
  getLectures,
  classroomLectureAssignsDelete,
  addClassroomLectureAssigns,
} from "../../../../api/general";

import cn from "classnames";
import assignment from "../../../../assets/assign.svg";
import whiteassignment from "../../../../assets/whiteassign.svg";
import whitedelete from "../../../../assets/assignwhitedelete.svg";
import assigndelete from "../../../../assets/assigndelete.svg";

import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../../helpers/toast";

const ClassroomCourseAssignment = ({
  title1 = "Sıra",
  title2 = "Sınıf Adı",
  title3 = "Alan",
  title4 = "Toplam",
  title5 = "Ders Adı",
  title6 = "HDS",
  title7 = "Ata",
  title8 = "Sıra",
  title9 = "Ders Adı",
  title10 = "Atanan Sınıf",
  title11 = "HDS",
  title12 = "Sil",
}) => {
  const {
    classroomCourseAssignmentClassroomInputValue,
    setClassroomCourseAssignmentClassroomInputValue,
    classroomCourseAssignmentLectureInputValue,
    setClassroomCourseAssignmentLectureInputValue,
    classroomCourseAssignmentLectureAssignInputValue,
    setClassroomCourseAssignmentLectureAssignInputValue,
  } = useContext(StoreContext);

  const [classrooms, setClassrooms] = useState([]);
  const [selectedClassroomId, setSelectedClassroomId] = useState("");
  const [selectedClassroomName, setSelectedClassroomName] = useState("");

  const [lectures, setLectures] = useState([]);
  const [selectedLectureId, setSelectedLectureId] = useState("");

  const [lectureassigns, setLectureAssigns] = useState([]);
  const [selectedLectureAssignId, setSelectedLectureAssignId] = useState("");
  const [selectedLectureAssignSendId, setSelectedLectureAssignSendId] =
    useState("");

  useEffect(() => {
    getClassroomsMethod();
    getLecturesMethod();
  }, []);

  //classroom

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
      console.log(response.data, "response.data.classrooms");
    }
  };

  const styleSelectedClassroom = (id) => {
    if (selectedClassroomId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  //lecture

  const getLecturesMethod = async () => {
    const response = await getLectures();
    if (response.error) {
      console.log("Test dersler gelmedi");
    } else {
      console.log("Test dersler geldi");
      setLectures(response.data);
    }
  };

  const styleSelectedLecture = (id) => {
    if (selectedLectureId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  const styleSelectedLectureImg = (id) => {
    if (selectedLectureId === id) {
      return <img src={whiteassignment}></img>;
    } else {
      return <img src={assignment}></img>;
    }
  };

  //lectureassign

  const getClassroomLectureAssignsMethod = async (selectedClassroomId) => {
    const response = await getClassroomLectureAssigns(selectedClassroomId);
    if (response.error) {
      console.log("Test ders atamaları gelmedi");
    } else {
      console.log("Test ders atamaları geldi");
      setLectureAssigns(response.data);
    }
  };

  const deleteClassroomLectureAssignsMethod = async (
    selectedLectureAssignSendId,
    selectedLectureAssignId
  ) => {
    const response = await classroomLectureAssignsDelete(
      selectedLectureAssignSendId,
      selectedLectureAssignId
    );
    if (response.error) {
      console.log("Ders sınıftan silinemedi");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Ders sınıftan silinemedi");
    } else {
      console.log("Ders sınıftan silindi");
      successMessage("Ders sınıftan silindi");

      getClassroomLectureAssignsMethod(selectedClassroomId);

      /*  setTimeout(function () {
        window.location.reload();
      }, 2500); */
    }
  };

  const addClassroomLectureAssignsMethod = async (
    selectedLectureId,
    selectedClassroomId
  ) => {
    const response = await addClassroomLectureAssigns(
      selectedLectureId,
      selectedClassroomId
    );
    if (response.error) {
      console.log("Ders sınıfa eklenemedi");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Ders sınıfa eklenemedi");
    } else {
      console.log("Ders sınıfa eklendi");
      successMessage("Ders sınıfa eklendi");

      getClassroomLectureAssignsMethod(selectedClassroomId);

      /*  setTimeout(function () {
        window.location.reload();
      }, 2500); */
    }
  };

  const styleSelectedLectureAssign = (id) => {
    if (selectedLectureAssignId === "") {
      return style.datas;
    }
    if (selectedLectureAssignId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  const styleSelectedLectureDeleteImg = (id) => {
    if (selectedLectureAssignId === "") {
      return <img src={assigndelete}></img>;
    }
    if (selectedLectureAssignId === id) {
      return <img src={whitedelete}></img>;
    } else {
      return <img src={assigndelete}></img>;
    }
  };

  return (
    <div className={style.container}>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>Sınıflar</div>
          <div className={style.search}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Sınıf Ara"
              onChange={(e) => {
                setClassroomCourseAssignmentClassroomInputValue(e.target.value);
              }}
              value={classroomCourseAssignmentClassroomInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table}>
          <div className={style.titles}>
            <div className={style.title1}>{title1}</div>
            <div className={style.title2}>{title2}</div>
            <div className={style.title3}>{title3} </div>
            <div className={style.title4}>{title4} </div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {classrooms &&
              classrooms
                .filter(
                  (el) =>
                    el.sinifname
                      .toLowerCase()
                      .includes(
                        classroomCourseAssignmentClassroomInputValue.toLowerCase()
                      ) /* ||
                    el.alan
                      .toString()
                      .includes(
                        classroomCourseAssignmentClassroomInputValue.toLowerCase()
                      ) */
                )
                .map((classroom, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas2 : null,
                      styleSelectedClassroom(classroom.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedClassroomId(classroom.id);
                      setSelectedClassroomName(classroom.sinifname);
                      getClassroomLectureAssignsMethod(classroom.id);
                    }}
                  >
                    <div className={style.data1}>{classroom.id}</div>
                    <div className={style.data2}>{classroom.sinifname}</div>
                    <div className={style.data3}>Alan</div>
                    <div className={style.data4}>{classroom.id}</div>
                  </button>
                ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>Dersler</div>
          <div className={style.search1}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Ders Ara"
              onChange={(e) => {
                setClassroomCourseAssignmentLectureInputValue(e.target.value);
              }}
              value={classroomCourseAssignmentLectureInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table1}>
          <div className={style.titles}>
            <div className={style.title5}>{title5}</div>
            <div className={style.title6}>{title6}</div>
            <div className={style.title7}>{title7} </div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {lectures &&
              lectures
                .filter((el) =>
                  el.dersname
                    .toLowerCase()
                    .includes(
                      classroomCourseAssignmentLectureInputValue.toLowerCase()
                    )
                )
                .map((lecture, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas2 : null,
                      styleSelectedLecture(lecture.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedLectureId(lecture.id);
                    }}
                  >
                    <div className={style.data5}>{lecture.dersname} </div>
                    <div className={style.data6}>{lecture.hds}</div>
                    <button
                      className={style.data7}
                      onClick={() => {
                        addClassroomLectureAssignsMethod(
                          lecture.id,
                          selectedClassroomId
                        );
                      }}
                    >
                      {styleSelectedLectureImg(lecture.id)}
                    </button>
                  </button>
                ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>
            {selectedClassroomName} Sınıfına Atanan Dersler
          </div>
          <div className={style.search2}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Ders Ara"
              onChange={(e) => {
                setClassroomCourseAssignmentLectureAssignInputValue(
                  e.target.value
                );
              }}
              value={classroomCourseAssignmentLectureAssignInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table2}>
          <div className={style.titles}>
            <div className={style.title8}>{title8}</div>
            <div className={style.title9}>{title9}</div>
            <div className={style.title10}>{title10} </div>
            <div className={style.title11}>{title11}</div>
            <div className={style.title12}>{title12} </div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {lectureassigns &&
              lectureassigns
                .filter((el) =>
                  el.dersname
                    .toLowerCase()
                    .includes(
                      classroomCourseAssignmentLectureAssignInputValue.toLowerCase()
                    )
                )
                .map((lectureassign, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas2 : null,
                      styleSelectedLectureAssign(lectureassign.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedLectureAssignId(lectureassign.id);
                      setSelectedLectureAssignSendId(
                        lectureassign.gerigonderilecekid
                      );
                    }}
                  >
                    <div className={style.data8}>{lectureassign.id} </div>
                    <div className={style.data9}>{lectureassign.dersname}</div>
                    <div className={style.data10}>
                      {lectureassign.sinifname}
                    </div>
                    <div className={style.data11}>{lectureassign.hds}</div>
                    <button
                      className={style.data12}
                      onClick={() => {
                        deleteClassroomLectureAssignsMethod(
                          lectureassign.id,
                          selectedClassroomId
                        );
                      }}
                    >
                      {styleSelectedLectureDeleteImg(lectureassign.id)}
                    </button>
                  </button>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassroomCourseAssignment;
