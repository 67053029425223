import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import EditEtudeModal from "../EditEtudeModal";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getEtude } from "../../../api/general";

const EtudesTable = ({
  title1 = "-",
  title2 = "Etüt",
  title3 = "Öğretmen",
  title4 = "Öğrenci",
  title5 = "Tarih",
  title6 = "Saat",
  title7 = "Yer",
  title8 = "İşlem",
  data,
}) => {
  const {
    setEditEtudeClick,
    editEtudeClick,
    etudeId,
    setEtudeId,
    setEtude,
  } = useContext(StoreContext);

  useEffect(() => {
    console.log("etudeId", etudeId);
  }, [etudeId]);

  const getEtudeMethod = async (etudeId) => {
    const response = await getEtude(etudeId);
    if (response.error) {
      console.log("Etüt bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setEtude(response.data[0]);
      console.log("Etüt bilgileri geldi");
    }
  };

  return (
    <div className={style.container}>
      {editEtudeClick && <EditEtudeModal etudeId={etudeId} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
        <div className={style.title7}>{title7}</div>
        <div className={style.title8}>{title8}</div>
      </div>
      {data &&
        data.map((etude, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{etude.id}</div>
            <div className={style.data2}>{etude.ders_name}</div>
            <div className={style.data3}>{etude.ogretmen_name}</div>
            <div className={style.data4}>{etude.ogrenci_name}</div>
            <div className={style.data5}>{etude.tarih}</div>
            <div className={style.data6}>{etude.saat}</div>
            <div className={style.data7}>{etude.sinifname}</div>
            <div className={style.data8}>
              <button
                onClick={() => {
                  setEtudeId(etude.id);
                  Promise.resolve(getEtudeMethod(etude.id)).then(() => {
                    setEditEtudeClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default EtudesTable;
