import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import EditTeacherModal from "../EditTeacherModal";
import { useContext, useEffect } from "react";
import StoreContext from "../../../context/store";
import { getTeacher } from "../../../api/general";

const TeachersTable = ({
  title1 = "#",
  title2 = "Adı Soyadı",
  title3 = "Ünvan",
  title4 = "Branş",
  title5 = "Telefon",
  title6 = "İşlem",
  data,
  branches,
}) => {
  const {
    setEditTeacherClick,
    editTeacherClick,
    teacherId,
    setTeacherId,
    setTeacher,
  } = useContext(StoreContext);
  const getTeacherMethod = async (teacherId) => {
    const response = await getTeacher(teacherId);
    if (response.error) {
    } else {
      await setTeacher(response.data.result);
    }
  };

  return (
    <div className={style.container}>
      {editTeacherClick && <EditTeacherModal teacherId={teacherId} branches={branches} />}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
      </div>
      {data &&
        data.map((teacher, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>{teacher.id}</div>
            <div className={style.data2}>{teacher.nameSurname}</div>
            <div className={style.data3}>{teacher.unvan}</div>
            <div className={style.data4}>{teacher.brans}</div>
            <div className={style.data5}>{teacher.phone}</div>
            <div className={style.data6}>
              <button
                onClick={() => {
                  setTeacherId(teacher.id);
                  Promise.resolve(getTeacherMethod(teacher.id)).then(() => {
                    setEditTeacherClick(true);
                  });
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TeachersTable;
