import { useEffect, useState } from "react";
import { getSchoolrooms } from "../api/general";
import style from "../styles/Schoolrooms.module.css";
import SchoolroomsTable from "../components/Schoolroom/SchoolroomsTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Schoolroom/SchoolroomBar";
import AddSchoolroomModal from "../components/Schoolroom/AddSchoolroomModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Schoolrooms = () => {
  const { addSchoolroomClick, schoolroomInputValue } =
    useContext(StoreContext);

  const [schoolrooms, setSchoolrooms] = useState([]);

  useEffect(() => {
    getSchoolroomsMethod();
    console.log(schoolrooms, "schoolrooms");
    console.log(schoolroomInputValue, "schoolroomInputValue");
  }, []);

  const getSchoolroomsMethod = async () => {
    const response = await getSchoolrooms();
    if (response.error) {
      console.log("Test derslikler gelmedi");
    } else {
      console.log("Test derslikler geldi");
      setSchoolrooms(response.data);
    }
  };

  return (
    <div className={style.page}>
      {addSchoolroomClick && <AddSchoolroomModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <SchoolroomsTable
            data={schoolrooms?.filter(
              (el) =>
                el.derslikname
                  .toLowerCase()
                  .includes(schoolroomInputValue.toLowerCase()) ||
                el.sinif
                  .toLowerCase()
                  .includes(schoolroomInputValue.toLowerCase()) ||
                el.kisaad.toLowerCase().includes(schoolroomInputValue.toLowerCase())
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Schoolrooms;
