import style from "../styles/Login.module.css";
import logo from "../assets/logo.svg";
import okulumlogo from "../assets/okulumlogo.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import StoreContext from "../context/store";


const Login = () => {
  const { LoginMethod, email, password, setEmail, setPassword } =
    useContext(StoreContext);


  return (
    <div className={style.page}>
      <div className={style.container}>
        <img src={logo} alt="logo" className={style.logo}></img>
        <img src={okulumlogo} alt="okulumlogo"></img>
        <div className={style.inputs}>
          <input
            placeholder="Kullanıcı E-mail"
            className={style.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            placeholder="Şifre"
            className={style.input}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div className={style.links}>
          <Link className={style.link} to="/sifremi-unuttum">
            Şifremi Unuttum
          </Link>
          <Link className={style.link} to="/kayıt-ol">
            Kayıt Ol
          </Link>
        </div>
        <button
          className={style.button}
          onClick={() => {
            LoginMethod();
          }}
        >
          Giriş
        </button>
      </div>
    </div>
  );
};

export default Login;
