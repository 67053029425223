import style from "./style.module.css";

import { useContext } from "react";
import StoreContext from "../../../context/store";

const SecondBar = () => {
  const { selectedProgramSection, setSelectedProgramSection } =
    useContext(StoreContext);

  const styleSection = (section) => {
    if (selectedProgramSection === section) {
      return style.ssection;
    } else {
      return style.section;
    }
  };

  return (
    <div className={style.container}>
      <button
        onClick={() => {
          setSelectedProgramSection("1");
          console.log(selectedProgramSection);
        }}
        className={styleSection("1")}
      >
        Öğretmen Ders Programı
      </button>
      <button
        onClick={() => {
          setSelectedProgramSection("2");
        }}
        className={styleSection("2")}
      >
        Sınıf Ders Programı
      </button>
      <button
        onClick={() => {
          setSelectedProgramSection("3");
        }}
        className={styleSection("3")}
      >
        Derslik Ders Programı
      </button>
      <button
        onClick={() => {
          setSelectedProgramSection("4");
        }}
        className={styleSection("4")}
      >
        Nöbet Programı
      </button>
      <button
        onClick={() => {
          setSelectedProgramSection("5");
        }}
        className={styleSection("5")}
      >
        Çıktılar
      </button>
    </div>
  );
};

export default SecondBar;
