import Table from "../../ProgramsTable";

const TeacherPrograms = () => {
    return(
        <>
            <Table/>
        </>
    )
}

export default TeacherPrograms;