import style from "./style.module.css";
import React from "react";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import appointmenticon from "../../../assets/modalIcons/appointmenticon.svg";
import { useContext, useState, useRef, useEffect } from "react";
import StoreContext from "../../../context/store";
//import { settingsAppointment } from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import arrowleft from "../../../assets/arrowleft.svg";
import arrowright from "../../../assets/arrowright.svg";
import calendar from "../../../assets/calendaricon.svg";
import clock from "../../../assets/clockicon.svg";
import dropdown from "../../../assets/arrowdown.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment-timezone";

import "moment/locale/tr";
import { parseISO } from "date-fns";
//import { format } from 'date-fns';
//import tr from "date-fns/locale/tr";
import useClickOutside from "../../../hooks/useClickOutside";
import {
  getUnits,
  getTeacherUnit,
  getAppointmentUnit,
  getUnitOfficers,
  postClosedDate,
  saveAppointmentSettings,
  getUnitOfficerInfo,
  deleteAppointmentDate,
  getBranchTeacher,
} from "../../../api/general";
import MyCalendar from "../Calender";

const SettingsAppointmentModal = () => {
  const {
    setSettingsAppointmentClick,
    dateInfo,
    setDateInfo,
    teacherId,
    setTeacherId,
  } = useContext(StoreContext);

  const [units, setUnits] = useState("");
  const [unitOfficers, setUnitOfficers] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [officerId, setOfficerId] = useState("");

  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedUnitOfficer, setSelectedUnitOfficer] = useState("");
  const [selectedOfficer, setSelectedOfficer] = useState("");
  const [availabletimeStart, setAvailableTimeStart] = useState(new Date());
  const [availabletimeEnd, setAvailableTimeEnd] = useState(new Date());
  const [opendates, setOpenDates] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [timeRange, setTimeRange] = useState("");

  //FOR DROPDOWN STATES
  const [optionArea, setOptionArea] = useState(false);
  const [optionArea2, setOptionArea2] = useState(false);
  const [optionArea3, setOptionArea3] = useState(false);

  //FOR TABLE DATE CLİCK +7
  const [offsetDays, setOffsetDays] = useState(null);

  useEffect(() => {
    //getUnitsMethod();
    getUnitMethod();
    if (unitId !== "") {
      getUnitOfficerInfoMethod(unitId);
    }
  }, [unitId]);

  const buttonRef = useRef(null);

  useClickOutside(buttonRef, () => {
    setOptionArea(false);
  });

  const handleToggle = () => {
    setOptionArea(!optionArea);
  };

  // FOR DROPDOWN2

  const buttonRef2 = useRef(null);

  useClickOutside(buttonRef2, () => {
    setOptionArea2(false);
  });

  const handleToggle2 = () => {
    setOptionArea2(!optionArea2);
  };

  // FOR DROPDOWN3

  const buttonRef3 = useRef(null);

  useClickOutside(buttonRef3, () => {
    setOptionArea3(false);
  });

  const handleToggle3 = () => {
    if (selectedUnitOfficer.length === 0) {
      errorMessage("Lütfen önce birim seçiniz");
      return;
    }
    setOptionArea3(!optionArea3);
  };

  // API METHODS

  /*const getUnitsMethod = async () => {
    const response = await getUnits();
    if (response.error) {
      console.log("Birimler gelmedi");
    } else {
      console.log("Birimler geldi");
      setUnits(response.data);
    }
  };*/

  const getUnitMethod = async () => {
    const response = await getAppointmentUnit();
    if (response.error) {
    } else {
      setUnits(response.data.result);
    }
  };

  const getUnitOfficerInfoMethod = async (unitId) => {
    const response = await getTeacherUnit(unitId);
    if (response.error) {
      //console.log("Yetkililerinin  bilgileri gelmedi");
    } else {
      //console.log("Yetkililerinin bilgileri geldi");
      setSelectedUnitOfficer(response.data.result);
    }
  };

  const deleteAppointmentDateMethod = async (teacherId) => {
    if (selectedUnitOfficer === "") {
      warningMessage("Lütfen yetkili seçiniz");

      return;
    }
    const response = await deleteAppointmentDate(teacherId);
    if (response.error) {
      errorMessage(" Randevu tarih silme başarısız");
    } else {
      successMessage(" randevu tarihleri silindi");
      setRefreshCalendar(true);
    }
  };

  const saveAppointmentSettingsMethod = async () => {
    if (availabletimeStart === "" || availabletimeEnd === "") {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }

    const response = await saveAppointmentSettings(
      teacherId,
      availabletimeStart,
      availabletimeEnd
    );
    if (response.error) {
      errorMessage(response.error.response.data.error);
      errorMessage("Randevu ayarları eklenemedi");
    } else {
      successMessage("Randevu ayarları başarıyla eklendi");
      setRefreshPage(true);
      // setSettingsAppointmentClick(false);
    }
  };

  const handleChange = (date) => {
    setAvailableTimeStart(date);
  };

  const handleChangeEnd = (date) => {
    setAvailableTimeEnd(date);
  };

  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  setSettingsAppointmentClick(false);
                }}
              >
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>

        <div className={style.inputs}>
          <div className="flex flex-row gap-4 flex-wrap ml-28  ">
            <div className={style.input}>
              <div className={style.inputtitle}>Birim</div>

              <div className={style.dropdown}>
                <div className={style.selectedoption}>{selectedUnit}</div>
                <button onClick={handleToggle2}>
                  <img src={dropdown} alt=""></img>
                </button>

                {optionArea2 && (
                  <div className={style.optionarea} ref={buttonRef2}>
                    <div>
                      {units.map((unit, index) => (
                        <button
                          key={index}
                          className={style.option}
                          value={selectedUnit}
                          onClick={(e) => {
                            setSelectedUnit(unit.name || "");
                            setUnitId(unit.id || "");
                            setOptionArea2(false);
                            if (selectedUnitOfficer !== "") {
                              setSelectedUnitOfficer("");
                            }
                          }}
                        >
                          {unit.name}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={style.input}>
              <div className={style.inputtitle2}>Yetkili</div>

              <div className={style.dropdown}>
                <div className={style.selectedoption}>{selectedOfficer}</div>
                <button onClick={handleToggle3}>
                  <img src={dropdown} alt=""></img>
                </button>

                {optionArea3 && (
                  <div className={style.optionarea} ref={buttonRef3}>
                    <div>
                      {selectedUnitOfficer &&
                        selectedUnitOfficer.map((unitOfficer, index) => {
                          return (
                            <button
                              key={index}
                              className={style.option}
                              value={selectedOfficer}
                              onClick={(e) => {
                                setSelectedOfficer(
                                  unitOfficer.firstNameLastName || ""
                                );
                                setTeacherId(unitOfficer.id);
                                // setUnitId(unitOfficer.id); // Eğer id bilgisini almanız gerekiyorsa, burayı güncelleyin
                                setOptionArea3(false);
                              }}
                            >
                              {unitOfficer.firstNameLastName}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4 flex-wrap justify-center">
            <div className={style.input1}>
              <div className={style.inputtitle}> Tarih Aralıkları</div>
              <div className={style.inputtext1}>
                <div className="w-[46px]  pr-2">
                  <DatePicker
                    className="bg-transparent border-0 outline-none pt-1"
                    selected={availabletimeStart}
                    onChange={handleChange}
                    timeFormat="HH:mm"
                    showTimeSelect
                    timeIntervals={10}
                    timeCaption="Saat"
                    dateFormat="dd/MM/yyyy HH:mm"
                    showPopperArrow={false}
                  />
                </div>
              </div>

              <img src={calendar} className={style.icon}></img>
            </div>
            <div className="flex flex-row flex-wrap max-md:w-full gap-y-4 max-sm:justify-end">
              <div className={style.input1}>
                <div className={style.inputtitle1}>-</div>
                <div className={style.inputtext}>
                  <DatePicker
                    className="bg-transparent border-0 outline-none pt-1"
                    selected={availabletimeEnd}
                    onChange={handleChangeEnd}
                    timeFormat="HH:mm"
                    showTimeSelect
                    timeIntervals={10}
                    timeCaption="Saat"
                    dateFormat="dd/MM/yyyy HH:mm"
                    showPopperArrow={false}
                  />
                </div>
                <img src={calendar} className={style.icon}></img>
              </div>

              <div className="flex flex-row justify-end">
                {" "}
                <button
                  className={style.addButton}
                  onClick={() => {
                    saveAppointmentSettingsMethod();
                  }}
                >
                  EKLE
                </button>
                <button
                  className={style.deleteButton}
                  onClick={() => {
                    deleteAppointmentDateMethod(teacherId);
                  }}
                >
                  SİL
                </button>
              </div>
            </div>
          </div>
          <div className={style.calendararea}>
            <MyCalendar key={refreshCalendar ? "refresh" : "normal"} />
          </div>
        </div>

        {/* <div className={style.lowertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  saveAppointmentSettingsMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default SettingsAppointmentModal;
