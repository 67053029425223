import style from "./style.module.css";
import operation from "../../../assets/operation.svg";
import EditAppointmentModal from "../EditAppointmentModal";
import { useContext, useState } from "react";
import StoreContext from "../../../context/store";
import { getAppointment } from "../../../api/general";
import Modal from "react-modal";
import cross from "../../../assets/modalIcons/cross.svg";

const AppointmentsTable = ({
  title1 = "Tarih",
  title2 = "Saat",
  title3 = "Konu",
  title4 = "Randevu Alınan Birim",
  title5 = "Randevuyu Alan",
  title6 = "Öğrenci Adı Soyadı",
  title7 = "Sınıf",
  title8 = "Onay",
  title9 = "Durum",
  title10 = "Görüş",
  title11 = "İşlem",
  data,
}) => {
  const {
    setEditAppointmentClick,
    editAppointmentClick,
    appointmentId,
    setAppointmentId,
    setAppointment,
  } = useContext(StoreContext);

  //useEffect(() => {}, [appointmentId]);

  const getAppointmentMethod = async (appointmentId) => {
    const response = await getAppointment(appointmentId);
    if (response.error) {
      console.log("Randevu bilgileri gelmedi");
      console.log(response.error, "response.error");
    } else {
      await setAppointment(response.data);
      console.log("Randevu bilgileri geldi");
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [view, setView] = useState("");

  function handleOpenModal(x) {
    setView(x);
    setShowModal(true);
  }
  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <div className={style.container}>
      {editAppointmentClick && (
        <EditAppointmentModal appointmentId={appointmentId} />
      )}
      <div className={style.titles}>
        <div className={style.title1}>{title1}</div>
        <div className={style.title2}>{title2}</div>
        <div className={style.title3}>{title3} </div>
        <div className={style.title4}>{title4} </div>
        <div className={style.title5}>{title5}</div>
        <div className={style.title6}>{title6}</div>
        <div className={style.title7}>{title7}</div>
        <div className={style.title8}>{title8}</div>
        <div className={style.title9}>{title9}</div>
        <div className={style.title10}>{title10}</div>
        <div className={style.title11}>{title11}</div>
      </div>
      {data &&
        data.map((appointment, index) => (
          <div className={style.datas} key={index}>
            <div className={style.data1}>
              {appointment.tarih}
              {/* {appointment.tarih.toString().split("T", 1)}*/}
            </div>
            <div className={style.data2}>
              {appointment.saat}
              {/*  {appointment.saat.toString().split("T")[1].split(":")[0]}:
              {appointment.saat.toString().split("T")[1].split(":")[1]}{" "}*/}
            </div>
            <div className={style.data3}>{appointment.konu}</div>
            <div className={style.data4}>{appointment.randevubirim}</div>
            <div className={style.data5}>{appointment.randevualan}</div>
            <div className={style.data6}>{appointment.studentname}</div>
            <div className={style.data7}>{appointment.sinifname} </div>
            <div className={style.data8}>{appointment.onay} </div>
            <div className={style.data9}>{appointment.durum} </div>

            <div
              onClick={() => handleOpenModal(appointment.gorus)}
              className={style.data10}
            >
              {appointment.gorus}
            </div>
            <Modal
              isOpen={showModal}
              onRequestClose={handleCloseModal}
              style={{
                content: {
                  maxWidth: "500px",
                  height: "fit-content",
                  minHeight: "300px",
                  backgroundColor: "#F1F2F7",
                  color: "#3733bf",
                  borderRadius: "20px",
                  margin: "auto",
                  marginTop: "10%",
                  padding: "15px",
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "medium",
                  fontFamily: "Poppins",
                  border: "2px",
                  borderStyle: "solid",
                  borderColor: "#F99E1D",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  placeContent: "start",
                  gap: "10px",
                },
                overlay: { backgroundColor: "rgba(0, 0, 0, 0.08)" },
              }}
            >
              <div className={style.tab}>
                <button className={style.tabicon} onClick={handleCloseModal}>
                  <img alt="" src={cross}></img>
                </button>
                <div className={style.tabtext}>Kapat</div>
              </div>
              <div>{view}</div>
            </Modal>

            <div className={style.data11}>
              <button
                onClick={() => {
                  setAppointmentId(appointment.id);
                  Promise.resolve(getAppointmentMethod(appointment.id)).then(
                    () => {
                      setEditAppointmentClick(true);
                    }
                  );
                }}
              >
                <img alt="" src={operation}></img>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AppointmentsTable;
