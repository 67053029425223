import { createContext, useState } from "react";
import { useEffect } from "react";
import { generalLogin } from "../api/general";
import { errorMessage, successMessage, warningMessage } from "../helpers/toast";
import { dateInitialValue } from "../constants";
import NotificationService from "../components/NotificationService";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {
  //For Auth
  const [isAuth, setIsAuth] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //For Login

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [userGuid, setUserGuid] = useState("");
  const [userName,setUserName] = useState("");
  const [id, setId] = useState("");

  //addTeacherModal Click
  const [addTeacherClick, setAddTeacherClick] = useState(false);
  //editTeacherModal
  const [editTeacherClick, setEditTeacherClick] = useState(false);
  const [teacher, setTeacher] = useState({}); //teacher detail for edit modal
  const [teacherId, setTeacherId] = useState("");
  //teacherSearchBar
  const [teacherInputValue, setTeacherInputValue] = useState("");

  //addStudentModal Click
  const [addStudentClick, setAddStudentClick] = useState(false);
  //editStudentModal
  const [editStudentClick, setEditStudentClick] = useState(false);
  const [student, setStudent] = useState({
    email: "",
    nameSurname: "",
    password: "",
    phone: "",
    studentnumber: "",
    sClass: "",
    parents: [],
  });
  const [studentId, setStudentId] = useState(""); //Student detail for edit modal
  //studentSearchBar
  const [studentInputValue, setStudentInputValue] = useState("");

  //addClassroomModal Click
  const [addClassroomClick, setAddClassroomClick] = useState(false);
  //editClassroomModal
  const [editClassroomClick, setEditClassroomClick] = useState(false);
  const [classroom, setClassroom] = useState({
    sinifName: "",
    sinifTeacher: "",
    description: "",
    seviye: "",
    sube: "",
    classLessons: [
      {
        weekOfDay: 0,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
        ],
      },
      {
        weekOfDay: 1,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
        ],
      },
      {
        weekOfDay: 2,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
        ],
      },
      {
        weekOfDay: 3,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
        ],
      },
      {
        weekOfDay: 4,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
        ],
      },
      {
        weekOfDay: 5,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
        ],
      },
      {
        weekOfDay: 6,
        baslangic: "",
        bitis: "",
        hours: [
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
          {
            start: "",
            end: "",
          },
        ],
      },
    ],
  }); //Classroom detail for edit modal
  const [classroomId, setClassroomId] = useState(null);
  //classroomSearchBar
  const [classroomInputValue, setClassroomInputValue] = useState("");

  //addLectureModal Click
  const [addLectureClick, setAddLectureClick] = useState(false);
  //editLectureModal
  const [editLectureClick, setEditLectureClick] = useState(false);
  const [lecture, setLecture] = useState({}); //Lecture detail for edit modal
  const [lectureId, setLectureId] = useState("");
  //lectureSearchBar
  const [lectureInputValue, setLectureInputValue] = useState("");

  //addAppointmentModal Click
  const [addAppointmentClick, setAddAppointmentClick] = useState(false);
  //editAppointmentModal{}
  const [editAppointmentClick, setEditAppointmentClick] = useState(false);
  const [appointment, setAppointment] = useState({}); //appointment detail for edit modal
  const [appointmentId, setAppointmentId] = useState("");
  //appointmentSearchBar
  const [appointmentInputValue, setAppointmentInputValue] = useState("");
  //settingsAppointmentModal Click
  const [settingsAppointmentClick, setSettingsAppointmentClick] =
    useState(false);
  // for appointment settings table info
  const [dateInfo, setDateInfo] = useState();
  

  //assignmentSectionClick
  const [selectedAssignmentSection, setSelectedAssignmentSection] =
    useState("1");
  //assignments input states
  const [
    classroomCourseAssignmentClassroomInputValue,
    setClassroomCourseAssignmentClassroomInputValue,
  ] = useState("");
  const [
    classroomCourseAssignmentLectureInputValue,
    setClassroomCourseAssignmentLectureInputValue,
  ] = useState("");
  const [
    classroomCourseAssignmentLectureAssignInputValue,
    setClassroomCourseAssignmentLectureAssignInputValue,
  ] = useState("");
  //teacher assignments input states
  const [
    teacherCourseAssignmentClassroomInputValue,
    setTeacherCourseAssignmentClassroomInputValue,
  ] = useState("");
  const [
    teacherCourseAssignmentTeacherInputValue,
    setTeacherCourseAssignmentTeacherInputValue,
  ] = useState("");
  const [
    teacherCourseAssignmentLectureAssignInputValue,
    setTeacherCourseAssignmentLectureAssignInputValue,
  ] = useState("");

  //schoolroom assignments input states
  const [
    schoolroomCourseAssignmentSchoolroomInputValue,
    setSchoolroomCourseAssignmentSchoolroomInputValue,
  ] = useState("");
  const [
    schoolroomCourseAssignmentClassroomInputValue,
    setSchoolroomCourseAssignmentClassroomInputValue,
  ] = useState("");
  const [
    schoolroomCourseAssignmentLectureAssignInputValue,
    setSchoolroomCourseAssignmentLectureAssignInputValue,
  ] = useState("");

  //addSchoolroomModal Click
  const [addSchoolroomClick, setAddSchoolroomClick] = useState(false);
  //editSchoolroomModal
  const [editSchoolroomClick, setEditSchoolroomClick] = useState(false);
  const [schoolroom, setSchoolroom] = useState({}); //Schoolroom detail for edit modal
  const [schoolroomId, setSchoolroomId] = useState("");
  //SchoolroomSearchBar
  const [schoolroomInputValue, setSchoolroomInputValue] = useState("");

  //addSocialClubModal Click
  const [addSocialClubClick, setAddSocialClubClick] = useState(false);
  //editSocialClubModal
  const [editSocialClubClick, setEditSocialClubClick] = useState(false);
  const [socialClub, setSocialClub] = useState({}); //SocialClub detail for edit modal
  const [socialClubId, setSocialClubId] = useState("");
  //SocialClubSearchBar
  const [socialClubInputValue, setSocialClubInputValue] = useState("");

  //addWelldoneModal Click
  const [addWelldoneClick, setAddWelldoneClick] = useState(false);
  //editWelldoneModal
  const [editWelldoneClick, setEditWelldoneClick] = useState(false);
  const [welldone, setWelldone] = useState([]); //Welldone detail for edit modal
  const [welldoneId, setWelldoneId] = useState("");
  //WelldoneSearchBar
  const [welldoneInputValue, setWelldoneInputValue] = useState("");

  //addEtudeModal Click
  const [addEtudeClick, setAddEtudeClick] = useState(false);
  //editEtudeModal
  const [editEtudeClick, setEditEtudeClick] = useState(false);
  const [etude, setEtude] = useState([]); //Etude detail for edit modal
  const [etudeId, setEtudeId] = useState("");
  //EtudeSearchBar
  const [etudeInputValue, setEtudeInputValue] = useState("");

  //addMessageModal Click
  const [addMessageClick, setAddMessageClick] = useState(false);
  //MessageModal
  const [message, setMessage] = useState({}); //message detail for edit modal
  const [messageId, setMessageId] = useState("");
  //messageSearchBar
  const [messageInputValue, setMessageInputValue] = useState("");
  const [messageClassroomInputValue, setMessageClassroomInputValue] =
    useState("");
  const [messageTeacherInputValue, setMessageTeacherInputValue] = useState("");
  const [messageStudentInputValue, setMessageStudentInputValue] = useState("");
  const [messageParentInputValue, setMessageParentInputValue] = useState("");

  //addAnnouncementModal Click
  const [addAnnouncementClick, setAddAnnouncementClick] = useState(false);
  //AnnouncementModal
  const [announcement, setAnnouncement] = useState({}); //announcement detail for edit modal
  const [announcementId, setAnnouncementId] = useState("");
  //announcementSearchBar
  const [announcementInputValue, setAnnouncementInputValue] = useState("");
  const [announcementClassroomInputValue, setAnnouncementClassroomInputValue] =
    useState("");
  const [announcementTeacherInputValue, setAnnouncementTeacherInputValue] =
    useState("");
  const [announcementStudentInputValue, setAnnouncementStudentInputValue] =
    useState("");
  const [announcementParentInputValue, setAnnouncementParentInputValue] =
    useState("");

  //addAttendanceModal Click
  const [addAttendanceClick, setAddAttendanceClick] = useState(false);
  //editAttendanceModal
  const [editAttendanceClick, setEditAttendanceClick] = useState(false);
  const [date, setDate] = useState(new Date());
  const [selectsinif, setSelectsinif] = useState("");
  const [attendance, setAttendance] = useState({});
  const [attendances, setAttendances] = useState([]); //attendance detail for edit modal
  const [attendanceId, setAttendanceId] = useState("");
  const [data, setData] = useState("");
  const [selectedSinif, setSelectedSinif] = useState({});
  const [selectedSiniff, setSelectedSiniff] = useState({});
  //attendanceSearchBar
  const [attendanceInputValue, setAttendanceInputValue] = useState("");

  const [classroomattendance, setClassroomattendance] = useState("");
  const [classroomattendanceId, setClassroomattendanceId] = useState("");

  const [lectureorders, setLectureOrders] = useState([
    "1. Ders",
    "2. Ders",
    "3. Ders",
    "4. Ders",
    "5. Ders",
    "6. Ders",
    "7. Ders",
    "8. Ders",
  ]);

  const [lectureorder, setLectureOrder] = useState("1. Ders");
  const [selectedLectureOrder, setSelectedLectureOrder] = useState("1. Ders");

  const [attendanceDate, setAttendanceDate] = useState(
    new Date().toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })
  );

  //addMealModal Click
  const [addMealClick, setAddMealClick] = useState(false);
  //editMealModal
  const [editMealClick, setEditMealClick] = useState(false);
  const [meal, setMeal] = useState({}); //meal detail for edit modal
  const [mealId, setMealId] = useState("");
  //mealSearchBar
  const [mealInputValue, setMealInputValue] = useState("");

  //addPaymentModal Click
  const [addPaymentClick, setAddPaymentClick] = useState(false);
  //editPaymentModal
  const [editPaymentClick, setEditPaymentClick] = useState(false);
  const [payment, setPayment] = useState({}); //payment detail for edit modal
  const [paymentId, setPaymentId] = useState("");
  //paymentSecondBar
  const [selectedPaymentSection, setSelectedPaymentSection] = useState("1");

  //programsSecondBar
  const [selectedProgramSection, setSelectedProgramSection] = useState("1");

  //addExamModal Click
  const [addExamClick, setAddExamClick] = useState(false);
  //editExamModal
  const [editExamClick, setEditExamClick] = useState(false);
  const [exam, setExam] = useState({}); //exam detail for edit modal
  const [examId, setExamId] = useState("");
  //examSearchBar
  const [examInputValue, setExamInputValue] = useState("");

  // Programs Page Modal

  const [showPlaceModal, setShowPlaceModal] = useState(false);
  const [showTeacherPlaceModal, setShowTeacherPlaceModal] = useState(false);
  const [nonPlacedLecturesModal, setNonPlacedLecturesModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [cleanModal, setCleanModal] = useState(false);
  const [changesModal, setChangesModal] = useState(false);
  const [directivesModal, setDirectivesModal] = useState(false);

  // User Role
  const [userRole, setUserRole] = useState("");
  //Control Token

  useEffect(() => {
    controlToken();
  }, []);

  const controlToken = async () => {
    let localToken = localStorage.getItem("token");
    if (localToken) {
      let jwtData = localToken.split(".")[1];
      let decodedJwtJsonData = window.atob(jwtData);
      let decodedJwtData = JSON.parse(decodedJwtJsonData);
      let theRole = decodedJwtData.role;
      setUserRole(theRole);
    }

    if (localToken) {
      setIsAuth(true);
      setToken(localToken);
    } else {
      setIsAuth(false);
      setToken(null);
    }
    setIsLoading(false);
  };

  const sendDataServer = async (userData) => {
    try {

      await axios.post('http://localhost:3001/user',userData);
      console.log('Kullanıcı bilgileri sunucuya başarıyla gönderildi');
    } catch (error) {
      console.error('Kullanıcı bilgileri sunucuya gönderilirken bir hata oluştu:', error);
    }
  }

  //Login Method

  const LoginMethod = async () => {
    if (email === "" || password === "") {
      warningMessage("Lütfen boş alan bırakmayınız");

      return;
    }
    const response = await generalLogin(email, password);
    if (response.error) {
      errorMessage("Giriş başarısız");
      errorMessage(response.error.response.data.message);
    } else {
      successMessage("Giriş başarılı");
      sendDataServer(response.data.userData);
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("userGuid", response.data.userData.userGuid);
      localStorage.setItem("nameSurname", response.data.userData.nameSurname);
      setUserType(response.data.userData.usertype);
      setUserGuid(response.data.userData.userGuid);
      setUserName(response.data.userData.nameSurname);
      setId(response.data.userData.id);
      const userGuid = response.data.userData.userGuid;
      const username = response.data.userData.nameSurname;
      controlToken();
      const topic = userGuid;
      const usernamee = username;
      const usertypee = response.data.userData.userType;
      //console.log("user:", usernamee);
      NotificationService.subscribeTopic(topic) 
    }
  };
  
  return (
    <StoreContext.Provider
      value={{
        isAuth,
        controlToken,
        LoginMethod,
        email,
        setEmail,
        password,
        userName,
        setUserName,
        setPassword,
        userType,
        setUserType,
        userGuid,
        setUserGuid,
        id,
        setId,
        isLoading,
        addTeacherClick,
        setAddTeacherClick,
        editTeacherClick,
        setEditTeacherClick,
        selectedSinif,
        setSelectedSinif,
        teacher,
        setTeacher,
        teacherId,
        date,
        setDate,
        selectsinif,
        setSelectsinif,
        setTeacherId,
        selectedSiniff,
        setSelectedSiniff,
        addStudentClick,
        setAddStudentClick,
        editStudentClick,
        setEditStudentClick,
        student,
        setStudent,
        studentId,
        setStudentId,
        teacherInputValue,
        setTeacherInputValue,
        studentInputValue,
        setStudentInputValue,
        addClassroomClick,
        setAddClassroomClick,
        editClassroomClick,
        setEditClassroomClick,
        classroom,
        setClassroom,
        classroomId,
        setClassroomId,
        classroomInputValue,
        setClassroomInputValue,
        addLectureClick,
        setAddLectureClick,
        editLectureClick,
        setEditLectureClick,
        lecture,
        setLecture,
        lectureId,
        setLectureId,
        lectureInputValue,
        setLectureInputValue,
        addAppointmentClick,
        setAddAppointmentClick,
        editAppointmentClick,
        setEditAppointmentClick,
        appointment,
        setAppointment,
        appointmentId,
        setAppointmentId,
        appointmentInputValue,
        setAppointmentInputValue,
        settingsAppointmentClick,
        setSettingsAppointmentClick,
        selectedAssignmentSection,
        setSelectedAssignmentSection,
        dateInfo,
        setDateInfo,
        classroomCourseAssignmentClassroomInputValue,
        setClassroomCourseAssignmentClassroomInputValue,
        classroomCourseAssignmentLectureInputValue,
        setClassroomCourseAssignmentLectureInputValue,
        classroomCourseAssignmentLectureAssignInputValue,
        setClassroomCourseAssignmentLectureAssignInputValue,
        teacherCourseAssignmentClassroomInputValue,
        setTeacherCourseAssignmentClassroomInputValue,
        teacherCourseAssignmentTeacherInputValue,
        setTeacherCourseAssignmentTeacherInputValue,
        teacherCourseAssignmentLectureAssignInputValue,
        setTeacherCourseAssignmentLectureAssignInputValue,
        schoolroomCourseAssignmentSchoolroomInputValue,
        setSchoolroomCourseAssignmentSchoolroomInputValue,
        schoolroomCourseAssignmentClassroomInputValue,
        setSchoolroomCourseAssignmentClassroomInputValue,
        schoolroomCourseAssignmentLectureAssignInputValue,
        setSchoolroomCourseAssignmentLectureAssignInputValue,
        addSchoolroomClick,
        setAddSchoolroomClick,
        editSchoolroomClick,
        setEditSchoolroomClick,
        schoolroom,
        setSchoolroom,
        schoolroomId,
        setSchoolroomId,
        schoolroomInputValue,
        setSchoolroomInputValue,
        addSocialClubClick,
        setAddSocialClubClick,
        editSocialClubClick,
        setEditSocialClubClick,
        socialClub,
        setSocialClub,
        socialClubId,
        setSocialClubId,
        socialClubInputValue,
        setSocialClubInputValue,
        addWelldoneClick,
        setAddWelldoneClick,
        editWelldoneClick,
        setEditWelldoneClick,
        welldone,
        setWelldone,
        welldoneId,
        setWelldoneId,
        welldoneInputValue,
        setWelldoneInputValue,
        addEtudeClick,
        setAddEtudeClick,
        editEtudeClick,
        setEditEtudeClick,
        etude,
        setEtude,
        etudeId,
        setEtudeId,
        etudeInputValue,
        setEtudeInputValue,

        selectedProgramSection,
        setSelectedProgramSection,

        addMessageClick,
        setAddMessageClick,
        message,
        setMessage,
        messageId,
        setMessageId,
        messageInputValue,
        setMessageInputValue,
        messageClassroomInputValue,
        setMessageClassroomInputValue,
        messageTeacherInputValue,
        setMessageTeacherInputValue,
        messageStudentInputValue,
        setMessageStudentInputValue,
        messageParentInputValue,
        setMessageParentInputValue,

        addAnnouncementClick,
        setAddAnnouncementClick,
        announcement,
        setAnnouncement,
        announcementId,
        setAnnouncementId,
        announcementInputValue,
        setAnnouncementInputValue,
        announcementClassroomInputValue,
        setAnnouncementClassroomInputValue,
        announcementTeacherInputValue,
        setAnnouncementTeacherInputValue,
        announcementStudentInputValue,
        setAnnouncementStudentInputValue,
        announcementParentInputValue,
        setAnnouncementParentInputValue,

        addAttendanceClick,
        setAddAttendanceClick,
        editAttendanceClick,
        setEditAttendanceClick,
        attendance,
        setAttendance,
        attendances,
        setAttendances,
        attendanceId,
        setAttendanceId,
        attendanceInputValue,
        setAttendanceInputValue,

        classroomattendance,
        setClassroomattendance,
        classroomattendanceId,
        setClassroomattendanceId,

        lectureorders,
        setLectureOrders,
        lectureorder,
        setLectureOrder,
        selectedLectureOrder,
        setSelectedLectureOrder,
        attendanceDate,
        setAttendanceDate,

        addMealClick,
        setAddMealClick,
        editMealClick,
        setEditMealClick,
        meal,
        setMeal,
        mealId,
        setMealId,
        mealInputValue,
        setMealInputValue,

        addPaymentClick,
        setAddPaymentClick,
        editPaymentClick,
        setEditPaymentClick,
        payment,
        setPayment,
        paymentId,
        setPaymentId,
        selectedPaymentSection,
        setSelectedPaymentSection,

        addExamClick,
        setAddExamClick,
        editExamClick,
        setEditExamClick,
        exam,
        setExam,
        examId,
        setExamId,
        examInputValue,
        setExamInputValue,

        showPlaceModal,
        setShowPlaceModal,

        showTeacherPlaceModal,
        setShowTeacherPlaceModal,

        nonPlacedLecturesModal,
        setNonPlacedLecturesModal,

        settingsModal,
        setSettingsModal,

        cleanModal,
        setCleanModal,

        changesModal,
        setChangesModal,

        directivesModal,
        setDirectivesModal,

        userRole,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
