import style from "./style.module.css";

import tick from "../../../../assets/modalIcons/tick.svg";
import { useContext, useState } from "react";
import StoreContext from "../../../../context/store";

import lectures from "../../../../assets/modalIcons/settings.svg"
import save from "../../../../assets/modalIcons/save.svg"
import cross from "../../../../assets/modalIcons/cross.svg"
import bin from "../../../../assets/modalIcons/delete.svg"

const CleanModal = () => {

    const { setCleanModal } =
        useContext(StoreContext);
    

    const [isToggleActive, setIsToggleActive] = useState(false)

    const handleToggle = () => {
        if (isToggleActive) {
            setIsToggleActive(false)
        } else {
            setIsToggleActive(true)
        }

    }

    return (
        <div className={style.modal}>
            <div className={style.modalbox}>
                <div className={style.uppertabs}>
                    <div className={style.tableftside}>
                        <div className={style.tab}>
                            <button className={style.tabicon_left}>
                                <img alt="" src={lectures}></img>
                            </button>
                            <div className={style.tabtext_left}>Temizleme Seçenekleri </div>
                        </div>
                    </div>
                    <div className={style.tabrightside}>
                        <div className={style.tab}>
                            <button className={style.tabicon_right} onClick={() => {
                                setCleanModal(false);
                            }}>
                                <img alt="" src={cross}></img>
                            </button>
                            <div className={style.tabtext_right}>Kapat</div>
                        </div>
                    </div>
                </div>
                <div className={style.contents}>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Tüm Program</div>
                        <div className={style.buttoncontainer}>
                            <input
                                type="button"
                                className={`${isToggleActive ? style.activeinputbutton : style.inputbutton}`}
                                onClick={handleToggle}
                            ></input>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Seçili Öğretmen Programı</div>
                        <div className={style.buttoncontainer}>
                            <input
                                type="button"
                                className={`${isToggleActive ? style.activeinputbutton : style.inputbutton}`}
                                onClick={handleToggle}
                            ></input>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Kırmızı Alanlar</div>
                        <div className={style.buttoncontainer}>
                            <input
                                type="button"
                                className={`${isToggleActive ? style.activeinputbutton : style.inputbutton}`}
                                onClick={handleToggle}
                            ></input>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.inputtitle}>Seçmeli / Kurs</div>
                        <div className={style.buttoncontainer}>
                            <input
                                type="button"
                                className={`${isToggleActive ? style.activeinputbutton : style.inputbutton}`}
                                onClick={handleToggle}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className={style.lowertabs}>
                    <div className={style.tableftside}></div>
                    <div className={style.tabrightside}>
                        <div className={style.tab}>
                            <button
                                className={style.tabicon_right}
                                onClick={() => {
                                    setCleanModal(false);
                                }}
                            >
                                <img alt="" src={bin}></img>
                            </button>
                            <div className={style.tabtext_right}>Temizle</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CleanModal;