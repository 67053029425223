import style from "./style.module.css";
import cross from "../../../assets/modalIcons/cross.svg";
import save from "../../../assets/modalIcons/save.svg";
import excel from "../../../assets/modalIcons/excel.svg";
import deleteicon from "../../../assets/modalIcons/delete.svg";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../context/store";
import {
  addClassroom,
  getTeachersDD,
  deleteClassroom,
  editClassroom,
} from "../../../api/general";
import {
  successMessage,
  errorMessage,
  warningMessage,
} from "../../../helpers/toast";
import React from "react";
import AddClassRoomDay from "../AddClassroomDay";
import Select from "react-select";

const AddClassroomModal = ({ classroomId }) => {
  const {
    setAddClassroomClick,
    setEditClassroomClick,
    setClassroom,
    classroom,
  } = useContext(StoreContext);

  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedTeacher, setSelectedTeacher] = useState(
    classroom.sinifTeacher
  );

  const [teachers, setTeachers] = useState([]);
   console.log("ogretmenler",teachers)
  useEffect(() => {
    getTeachersMethod();
  }, []);

  const getTeachersMethod = async () => {
    const response = await getTeachersDD();
    if (response.error) {
    } else {
      await setTeachers(response.data);
    }
  }; //

  const addClassroomMethod = async () => {
    if (
      classroom.sinifName === "" ||
      classroom.description === "" ||
      classroom.seviye === "" ||
      classroom.sube === ""
    ) {
      warningMessage("Lütfen boş alan bırakmayınız");
      return;
    }
    let response;
    let message;
    if (classroomId !== undefined) {
      response = await editClassroom(classroomId, classroom);
      message = "düzenleme";
    } else {
      response = await addClassroom(classroom);
      message = "ekleme";
    }
    if (response.error) {
      errorMessage(`Sınıf ${message} başarısız`);
    } else {
      successMessage(`Sınıf ${message} başarılı`);
      setAddClassroomClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };

  const styleDay = (day) => {
    if (selectedDay === day) {
      return style.sday;
    } else {
      return style.day;
    }
  };

  const setVariable = (variableName, value) => {
    let temp = Object.assign({}, classroom);
    temp[variableName] = value;
    setClassroom(temp);
  };

  const initializeDays = () => {
    var week = new Array(
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
      "Pazar"
    );
    let result = [];
    for (let i = 0; i < 7; i++) {
      result.push(
        <button
          onClick={() => {
            setSelectedDay(i);
          }}
          className={styleDay(i)}
          key={week[i]}
        >
          {week[i]}
        </button>
      );
    }
    return <>{result}</>;
  };

  const closeModal = () => {
    if (classroomId !== undefined) setEditClassroomClick(false);
    else setAddClassroomClick(false);
  };

  const deleteClassroomMethod = async () => {
    const response = await deleteClassroom(classroomId);
    if (response.error) {
      errorMessage("Sınıf silinemedi");
    } else {
      successMessage("Sınıf silindi");
      setEditClassroomClick(false);
      setTimeout(function () {
        window.location.reload();
      }, 2500);
    }
  };
  return (
    <div className={style.modal}>
      <div className={style.modalbox}>
        <div className={style.uppertabs}>
          <div className={style.tableftside}></div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Şablon İndir </div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon}>
                <img alt="" src={excel}></img>
              </button>
              <div className={style.tabtext}>Excel İle Aktar</div>
            </div>
            <div className={style.tab}>
              <button className={style.tabicon} onClick={closeModal}>
                <img alt="" src={cross}></img>
              </button>
              <div className={style.tabtext}>Kapat</div>
            </div>
          </div>
        </div>
        <div className={style.inputbox}>
          <div className={style.inputarea}>
            <div className={style.inputs}>
              <div className={style.input}>
                <div className={style.inputtitle}>Sınıf Adı</div>
                <input
                  className={style.inputtext}
                  value={classroom.sinifName ?? ""}
                  onChange={(e) => setVariable("sinifName", e.target.value)}
                ></input>
              </div>
              <div className={style.input}>
                <div className={style.inputtitle}>Seviye</div>
                <input
                  className={style.inputtext}
                  value={classroom.seviye ?? ""}
                  onChange={(e) => setVariable("seviye", e.target.value)}
                ></input>
              </div>
              <div className={style.input}>
                <div className={style.inputtitle}>Şube</div>
                <input
                  className={style.inputtext}
                  value={classroom.sube ?? ""}
                  onChange={(e) => setVariable("sube", e.target.value)}
                ></input>
              </div>
              <div className={style.input1}>
                <div className={style.inputtitle}>Sınıf Öğretmeni</div>
                <Select
                  value={selectedTeacher}
                  className={style.inputtext}
                  onChange={(e) => {
                    setSelectedTeacher(e);
                    setVariable("sinifTeacher", e !== null ? e.key : null);
                  }}
                  getOptionValue={(option) => option.key}
                  getOptionLabel={(option) => option.value}
                  isClearable={true}
                  options={teachers}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "none",
                      backgroundColor: "#EEF8FF",
                      width: "103%",
                      height: "0px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      margin: "-1px",
                      marginLeft: "-10px",
                    }),
                  }}
                />
              </div>
            </div>
            <div>
              <div className={style.input1}>
                <div className={style.inputtitle}>Açıklama</div>
                <input
                  className={style.inputtext}
                  value={classroom.description ?? ""}
                  onChange={(e) => setVariable("description", e.target.value)}
                ></input>
              </div>
            </div>
            <div className={style.title}>Giriş-Çıkış Saatleri</div>
            <div className={style.days}>{initializeDays()}</div>
            <AddClassRoomDay day={selectedDay} />
          </div>
        </div>
        <div className={style.lowertabs}>
          <div className={style.tableftside}>
            {classroomId !== undefined && (
              <div className={style.tab1}>
                <button
                  className={style.tabicon1}
                  onClick={() => {
                    deleteClassroomMethod();
                  }}
                >
                  <img alt="" src={deleteicon}></img>
                </button>
                <div className={style.tabtext1}>Sil</div>
              </div>
            )}
          </div>
          <div className={style.tabrightside}>
            <div className={style.tab}>
              <button
                className={style.tabicon}
                onClick={() => {
                  addClassroomMethod();
                }}
              >
                <img alt="" src={save}></img>
              </button>
              <div className={style.tabtext}>Kaydet</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClassroomModal;
