import style from "../styles/Payments.module.css";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Payment/PaymentBar";
import AddPaymentModal from "../components/Payment/AddPaymentModal";
import { useContext, useState,useEffect } from "react";
import StoreContext from "../context/store";
import { checkPaymentSections } from "../helpers/checkpaymentsections";
import SecondBar from "../components/Payment/PaymentSecondBar";
import { getPayments } from "../api/general";

const Payments = () => {
  const { addPaymentClick, selectedPaymentSection } = useContext(StoreContext);

  const [payments, setPayments] = useState([]);


  useEffect(() => {
    getPaymentsMethod();

  }, []);

  const getPaymentsMethod = async () => {
    const response = await getPayments();
    if (response.error) {
      console.log("Test tüm odemeler gelmedi");
    } else {
      console.log("Test tüm odemeler geldi");
      setPayments(response.data);
    }
  };



  return (
    <div className={style.page}>
      {addPaymentClick && <AddPaymentModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <SecondBar />
        <div className={style.table}>
          {checkPaymentSections(
            selectedPaymentSection,
            payments
          )}
        </div>
      </div>
    </div>
  );
};

export default Payments;
