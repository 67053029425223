import style from "./style.module.css";
import search from "../../../../assets/search.svg";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../../context/store";
import {
  addSchoolroomLectureAssigns,
  getClassroomLectureAssigns,
  getClassrooms,
  getSchoolroomLectureAssigns,
  getSchoolroomsUnique,
  schoolroomLectureAssignsDelete,
} from "../../../../api/general";
import cn from "classnames";
import assignment from "../../../../assets/assign.svg";
import whiteassignment from "../../../../assets/whiteassign.svg";
import whitedelete from "../../../../assets/assignwhitedelete.svg";
import assigndelete from "../../../../assets/assigndelete.svg";
import {
  successMessage,
  errorMessage,
} from "../../../../helpers/toast";

const SchoolroomCourseAssignment = ({
  title5 = "Derslik Adı ",

  title1 = "Sıra",
  title2 = "Sınıf Adı",
  title3 = "Toplam",

  title8 = "Ders Adı",
  title9 = "Atanan Öğretmen",
  title10 = "HDS",
  title11 = "Ata",

  title12 = "Atanan Derslik Adı",
  title13 = "Sınıf Adı",
  title14 = "Ders Adı",
  title15 = "Sil",
}) => {
  const {
    schoolroomCourseAssignmentSchoolroomInputValue,
    setSchoolroomCourseAssignmentSchoolroomInputValue,
    schoolroomCourseAssignmentClassroomInputValue,
    setSchoolroomCourseAssignmentClassroomInputValue,
    schoolroomCourseAssignmentLectureAssignInputValue,
    setSchoolroomCourseAssignmentLectureAssignInputValue,
  } = useContext(StoreContext);

  const [schoolrooms, setSchoolrooms] = useState([]);
  const [selectedSchoolroomId, setSelectedSchoolroomId] = useState("");
  const [selectedSchoolroomName, setSelectedSchoolroomName] = useState("");

  const [classrooms, setClassrooms] = useState([]);
  const [selectedClassroomId, setSelectedClassroomId] = useState("");
  const [selectedClassName, setSelectedClassName] = useState("");

  const [lectureassigns, setLectureAssigns] = useState([]);
  const [selectedLectureAssignId, setSelectedLectureAssignId] = useState("");


  const [schoolroomlectureassigns, setSchoolroomLectureAssigns] = useState([]);
  const [selectedSchoolroomLectureAssignId, setSelectedSchoolroomLectureAssignId] =
    useState("");
    const [selectedSchoolroomLectureAssignIndex, setSelectedSchoolroomLectureAssignIndex] =
    useState("");


  useEffect(() => {
    getClassroomsMethod();
    getSchoolroomsMethod();
  }, []);

  //schoolroom

  const getSchoolroomsMethod = async () => {
    const response = await getSchoolroomsUnique();
    if (response.error) {
      console.log("Test derslikler gelmedi");
    } else {
      console.log("Test derslikler geldi");
      setSchoolrooms(response.data);
    }
  };

  const styleSelectedSchoolroom = (id) => {
    if (selectedSchoolroomId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  //classroom

  const getClassroomsMethod = async () => {
    const response = await getClassrooms();
    if (response.error) {
      console.log("Test sınıflar gelmedi");
    } else {
      console.log("Test sınıflar geldi");
      setClassrooms(response.data);
      console.log(response.data, "response.data.classrooms");
    }
  };

  const styleSelectedClassroom = (id) => {
    if (selectedClassroomId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  //lectureassign

  const getClassroomLectureAssignsMethod = async (selectedClassroomId) => {
    const response = await getClassroomLectureAssigns(selectedClassroomId);
    if (response.error) {
      console.log("Test ders atamaları gelmedi");
    } else {
      console.log("Test ders atamaları geldi");
      setLectureAssigns(response.data);
    }
  };

  const styleSelectedLectureAssign = (id) => {
    if (selectedLectureAssignId === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  const styleSelectedLectureAssignImg = (id) => {
    if (selectedLectureAssignId === id) {
      return <img src={whiteassignment}></img>;
    } else {
      return <img src={assignment}></img>;
    }
  };

  const addSchoolroomLectureAssignsMethod = async (
    selectedLectureId,
    selectedSchoolroomId,
    selectedClassroomId
  ) => {
    const response = await addSchoolroomLectureAssigns(
      selectedLectureId,
      selectedSchoolroomId,
      selectedClassroomId
    );
    if (response.error) {
      console.log("Ders dersliğe eklenemedi");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Ders dersliğe eklenemedi");
    } else {
      console.log("Ders dersliğe eklendi");
      successMessage("Ders dersliğe eklendi");

      getSchoolroomLectureAssignsMethod(selectedSchoolroomId);
      getClassroomLectureAssignsMethod(selectedClassroomId);

      /*  setTimeout(function () {
        window.location.reload();
      }, 2500); */
    }
  };

  //schoolroomlectureassign

  const getSchoolroomLectureAssignsMethod = async (selectedSchoolroomId) => {
    /*  if (selectedSchoolroomId === "" ) {
      return;
    } */
    const response = await getSchoolroomLectureAssigns(selectedSchoolroomId);
    if (response.error) {
      console.log("Test derslik atamaları gelmedi");
    } else {
      console.log("Test derslik atamaları geldi");
      setSchoolroomLectureAssigns(response.data);
    }
  };


  const deleteSchoolroomLectureAssignsMethod = async (
    selectedSchoolroomId,
    selectedClassroomId,
    selectedLectureId,
  ) => {
    const response = await schoolroomLectureAssignsDelete(
      
      selectedSchoolroomId,
      selectedClassroomId,
      selectedLectureId,
    );
    if (response.error) {
      console.log("Ders derslikten silinemedi");
      console.log(response.error, "response.erro");
      errorMessage(response.error.response.data.error);
      errorMessage("Ders derslikten silinemedi");
    } else {
      console.log("Ders sınıftan silindi");
      successMessage("Ders derslikten silindi");

      getSchoolroomLectureAssignsMethod(selectedSchoolroomId);

      /*  setTimeout(function () {
        window.location.reload();
      }, 2500); */
    }
  };

  const styleSelectedSchoolroomLectureAssign = (id) => {
    if (selectedSchoolroomLectureAssignIndex === id) {
      return style.sdatas;
    } else {
      return style.datas;
    }
  };

  const styleSelectedSchoolroomLectureDeleteImg = (id) => {
    if (selectedSchoolroomLectureAssignIndex === id) {
      return <img src={whitedelete}></img>;
    } else {
      return <img src={assigndelete}></img>;
    }
  };

  return (
    <div className={style.container}>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>Derslikler</div>
          <div className={style.search1}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Derslik Ara"
              onChange={(e) => {
                setSchoolroomCourseAssignmentSchoolroomInputValue(
                  e.target.value
                );
              }}
              value={schoolroomCourseAssignmentSchoolroomInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table1}>
          <div className={style.titles}>
            <div className={style.title5}>{title5}</div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {schoolrooms &&
              schoolrooms
                .filter((el) =>
                  el.derslikname
                    .toLowerCase()
                    .includes(
                      schoolroomCourseAssignmentSchoolroomInputValue.toLowerCase()
                    )
                )
                .map((schoolroom, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas2 : null,
                      styleSelectedSchoolroom(schoolroom.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedSchoolroomId(schoolroom.id);
                      setSelectedSchoolroomName(schoolroom.derslikname);
                      getSchoolroomLectureAssignsMethod(schoolroom.id);
                    }}
                  >
                    <div className={style.data5}>{schoolroom.derslikname} </div>
                  </button>
                ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>Sınıflar</div>
          <div className={style.search}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Sınıf Ara"
              onChange={(e) => {
                setSchoolroomCourseAssignmentClassroomInputValue(
                  e.target.value
                );
              }}
              value={schoolroomCourseAssignmentClassroomInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table}>
          <div className={style.titles}>
            <div className={style.title1}>{title1}</div>
            <div className={style.title2}>{title2}</div>
            <div className={style.title3}>{title3} </div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {classrooms &&
              classrooms
                .filter((el) =>
                  el.sinifname
                    .toLowerCase()
                    .includes(
                      schoolroomCourseAssignmentClassroomInputValue.toLowerCase()
                    )
                )
                .map((classroom, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas2 : null,
                      styleSelectedClassroom(classroom.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedClassroomId(classroom.id);
                      getClassroomLectureAssignsMethod(classroom.id);
                      setSelectedClassName(classroom.sinifname);
                    }}
                  >
                    <div className={style.data1}>{classroom.id}</div>
                    <div className={style.data2}>{classroom.sinifname}</div>

                    <div className={style.data3}>{classroom.id}</div>
                  </button>
                ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>{selectedClassName} Sınıfına Atanan Dersler</div>
          <div className={style.search2}>
            <button className={style.searchicon}>
              <img alt="" src={search}></img>
            </button>
            <input
              className={style.searchinput}
              placeholder="Ders Ara"
              onChange={(e) => {
                setSchoolroomCourseAssignmentLectureAssignInputValue(
                  e.target.value
                );
              }}
              value={schoolroomCourseAssignmentLectureAssignInputValue}
            ></input>
          </div>
        </div>

        <div className={style.table2}>
          <div className={style.titles}>
            <div className={style.title8}>{title8}</div>
            <div className={style.title9}>{title9}</div>
            <div className={style.title10}>{title10} </div>
            <div className={style.title11}>{title11}</div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {lectureassigns &&
              lectureassigns
                .filter((el) =>
                  el.dersname
                    .toLowerCase()
                    .includes(
                      schoolroomCourseAssignmentLectureAssignInputValue.toLowerCase()
                    ) || el.atananogretmen
                    .toLowerCase()
                    .includes(
                      schoolroomCourseAssignmentLectureAssignInputValue.toLowerCase()
                ))
                .map((lecture, index) => (
                  <button
                    className={cn(
                      style.datas,
                      index % 2 === 0 ? style.datas2 : null,
                      styleSelectedLectureAssign(lecture.id)
                    )}
                    key={index}
                    onClick={() => {
                      setSelectedLectureAssignId(lecture.id);
                    }}
                  >
                    <div className={style.data8}>{lecture.dersname}</div>
                    <div className={style.data9}>{lecture.atananogretmen}</div>
                    <div className={style.data10}>{lecture.hds}</div>
                    <button
                      className={style.data11}
                      onClick={() => {
                        addSchoolroomLectureAssignsMethod(
                          lecture.id,
                          selectedSchoolroomId,
                          selectedClassroomId
                        );
                      }}
                    >
                      {styleSelectedLectureAssignImg(lecture.id)}
                    </button>
                  </button>
                ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className={style.title}>
            {selectedSchoolroomName} Atanan Dersler
          </div>
          <div className={style.search3}></div>
        </div>

        <div className={style.table3}>
          <div className={style.titles}>
            <div className={style.title12}>{title12}</div>
            <div className={style.title13}>{title13}</div>
            <div className={style.title14}>{title14} </div>
            <div className={style.title15}>{title15}</div>
          </div>

          <div className="flex flex-col max-h-[522px] overflow-y-auto gap-2">
            {schoolroomlectureassigns.map((lecture, index) => (
              <button
                className={cn(
                  style.datas,
                  index % 2 === 0 ? style.datas2 : null,
                  styleSelectedSchoolroomLectureAssign(index)
                )}
                key={index}
                onClick={() => {
                  setSelectedSchoolroomLectureAssignId(lecture.id);
                  setSelectedSchoolroomLectureAssignIndex(index+1);
                }}
              >
                <div className={style.data12}>{lecture.derslikname} </div>
                <div className={style.data13}>{lecture.sinif}</div>
                <div className={style.data14}>{lecture.dersname} </div>

                <button
                  className={style.data15}
                  onClick={() => {
                    deleteSchoolroomLectureAssignsMethod(
                      
                      lecture.id,
                      lecture.sinifid,
                      lecture.dersid,
                    


                     
                    );
                  }}
                >
                  {styleSelectedSchoolroomLectureDeleteImg(index)}
                </button>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolroomCourseAssignment;
