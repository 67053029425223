import React, { useState } from 'react'
import style from "./style.module.css"

import dropdown from "../../../assets/arrowdown.svg"
import search from "../../../assets/searchblue.svg"
const FilterBar = () => {
    const [selectedOption, setSelectedOption] = useState("Öğretmen Filtre")
    const [dropdownarea, setDropdownArea] = useState(false)
    const filteroptions = ["Tüm Öğretmenler", "Seçili saatte kapalı olanlar", "Seçili saatte açık olanlar", "Seçili saatte dersi olanlar", "Seçili saatte dersi olmayanlar"]
    const handleDropdownArea = () => {
        setDropdownArea(!dropdownarea)
    }

    const handleSelectedOption = (e) => {
        setSelectedOption(e.target.value);
        setDropdownArea(false)
    }
    return (
        <div className={style.container}>
            <div className={style.dropdown}>
                <div className={style.selectedone}>{selectedOption}</div>
                <button className={style.dropdown_btn} onClick={handleDropdownArea}>
                    <img src={dropdown} alt=""></img>
                </button>
                {dropdownarea && <div className={style.optionsarea}>
                    <div>
                        {filteroptions.map((opt, key) => 
                            <button key={key} value={opt} onClick={handleSelectedOption} className={style.option}>
                                {opt}
                            </button>
                        )}
                    </div>
                </div>}
            </div>
            <div className={style.filter_input}>
                <input className={style.input} placeholder='Öğretmen Ara'/>
                <img className={style.search_icon} src={search} alt=""></img>
            </div>
        </div>  
    )
}

export default FilterBar;