import style from "./style.module.css";
import video from "../../../assets/video.svg";
import search from "../../../assets/search.svg";
import reload from "../../../assets/reload.svg";
import plus from "../../../assets/plus.svg";
import pdf from "../../../assets/pdf.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import StoreContext from "../../../context/store";
import { getEtudePdf } from "../../../api/general";

const Bar = () => {
  const { setAddEtudeClick, setEtudeInputValue, etudeInputValue } =
    useContext(StoreContext);

  const getEtudePdfMethod = async () => {
    const response = await getEtudePdf();
    if (response.error) {
      console.log("Etüt pdfi gelmedi");
      console.log(response.error, "response.erro");
    } else {
      console.log("Etüt pdfi geldi");
    }
  };

  return (
    <div className={style.container}>
      <div className={style.etudes}>
        <div className={style.etudestext}>Etüt</div>
        <Link>
          <img alt="" className={style.etudesvideo} src={video}></img>
        </Link>
      </div>
      <div className={style.search}>
        <button className={style.searchicon}>
          <img alt="" src={search}></img>
        </button>
        <input
          className={style.searchinput}
          placeholder="Ara"
          onChange={(e) => {
            setEtudeInputValue(e.target.value);
          }}
          value={etudeInputValue}
        ></input>
      </div>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        <img alt="" className={style.reload} src={reload}></img>
      </button>
      <div className={style.tabs}>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
              setAddEtudeClick(true);
            }}
          >
            <img alt="" src={plus}></img>
          </button>
          <div className={style.tabtext}>Yeni Etüt</div>
        </div>
        <div className={style.tab}>
          <button
            className={style.tabicon}
            onClick={() => {
           //   getEtudePdfMethod();
            }}
          >
            <img alt="" src={pdf}></img>
          </button>
          <div className={style.tabtext}>PDF Yazdır</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
