import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import StoreContext from "../context/store";
import { getClassStudents } from "../api/general";

const useStyles = makeStyles({
  chatSection: {
    height: "100vh",
    flexDirection: "column-reverse"
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
    flexGrow: 1
  }
});

const Chat = () => {
  const classes = useStyles();
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const { userName, userRole, id } = useContext(StoreContext);

  useEffect(() => {
    getClassWithStudentsMethod();
  }, []);

  const getClassWithStudentsMethod = async () => {
    // Öğrencilerin sınıfları ile ilgili verileri al
    // API çağrısını buraya ekleyin
    // Örnek: const response = await getClassStudents();
    // Dönen verileri setMessages fonksiyonuyla ayarlayın
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Yeni mesajı göndermek için API çağrısını buraya ekleyin
    // Örnek: const response = await sendMessage(selectedStudent, newMessage);
    // Gönderilen mesajın cevabını alın ve state'i güncelleyin
  };

  return (
    <div>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={12}>
          <Typography variant="h5" className="header-message">
            Chat
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.messageArea}>
          <List>
            {messages.map((message, index) => (
              <ListItem key={index}>
                <ListItemText
                  align={message.sender === id ? "right" : "left"}
                  primary={message.text}
                />
                <ListItemText
                  align={message.sender === id ? "right" : "left"}
                  secondary={message.sender === id ? "You" : message.sender}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container style={{ padding: "20px" }} alignItems="center">
              <Grid item xs={11}>
                <TextField
                  id="outlined-basic-email"
                  label="Type Something"
                  fullWidth
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} align="right">
                <Fab color="primary" aria-label="add" type="submit">
                  <SendIcon />
                </Fab>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;
