import { useEffect, useState } from "react";
import { getStudents } from "../api/general";
import style from "../styles/Students.module.css";
import StudentsTable from "../components/Student/StudentsTable";
import Header from "../components/Header/SecondHeader";
import Bar from "../components/Student/StudentBar";
import AddStudentModal from "../components/Student/AddStudentModal";
import { useContext } from "react";
import StoreContext from "../context/store";

const Students = () => {
  const { addStudentClick, studentInputValue } = useContext(StoreContext);

  const [students, setStudents] = useState([]);

  useEffect(() => {
    getStudentsMethod();
  }, []);

  const getStudentsMethod = async () => {
    const response = await getStudents();
    if (response.error) {
    } else {
      setStudents(response.data.result);
    }
  };

  return (
    <div className={style.page}>
      {addStudentClick && <AddStudentModal />}
      <div className={style.container}>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.bar}>
          <Bar />
        </div>
        <div className={style.table}>
          <StudentsTable data={students} />
        </div>
      </div>
    </div>
  );
};

export default Students;
