import React, { useEffect } from "react";
import AllPayments from "../components/Payment/PaymentSections/AllPayments";
import ActivePayments from "../components/Payment/PaymentSections/ActivePayments";
import OldPayments from "../components/Payment/PaymentSections/OldPayments";

export const checkPaymentSections = (selectedSection, payments) => {
  const allPayments = payments;
  const currentDate = new Date();

  let activePayments = [];
  let oldPayments = [];

  if (payments) {
    activePayments = payments.filter((el) => {
      const parsedDate = new Date(el.gercektarih);
      return parsedDate.getTime() >= currentDate.getTime();
    });

    oldPayments = payments.filter((el) => {
      const parsedDate = new Date(el.gercektarih);
      return parsedDate.getTime() < currentDate.getTime();
    });
  }

  switch (selectedSection) {
    case "1":
      return <AllPayments data={allPayments} />;

    case "2":
      return <ActivePayments data={activePayments} />;

    case "3":
      return <OldPayments data={oldPayments} />;

    default:
      return <AllPayments data={allPayments} />;
  }
};
